import React from "react";
import "./MachineCurrentToggle.css";
import Toggle from "react-toggle";

export default function MachineCurrentToggle(props) {
  const { title, className = "", isChecked = false, handler } = props;
  return (
    <div className={`machine-current-toggle ${className}`}>
      <div className="machine-current-toggle-title">{title}</div>
      <label className="mt-8 mb-2 flex content-center gap-5">
        <div
          className={`machine-current-toggle-toggle-state ${
            !isChecked && "active"
          }`}
        >
          OFF
        </div>
        <Toggle checked={isChecked} icons={false} onChange={handler} />
        <div
          className={`machine-current-toggle-toggle-state ${
            isChecked && "active"
          }`}
        >
          ON
        </div>
      </label>
    </div>
  );
}
