import React from "react";

export default function RightArrow(props) {
  return (
    <svg
      width="7"
      height="8"
      viewBox="0 0 7 8"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.874688 7.54999L0.154688 6.42499L5.53969 3.33498V4.33999L0.154688 1.24998L0.874688 0.124985L6.28969 3.22998V4.44499L0.874688 7.54999Z" />
    </svg>
  );
}
