import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "react-query";

import { ReactQueryDevtools } from "react-query/devtools";
import api from "./components/util/api"; // Import the Axios instance

// Create a new instance of QueryClient with Axios as the default client
// const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      // refetchOnReconnect: false,
      // retry: false,
      // staleTime: twentyFourHoursInMs,
      // Use Axios for queries
      queryFn: ({ queryKey }) => api.get(queryKey[0]),
    },
    mutations: {
      // Use Axios for mutations
      mutationFn: ({ mutationKey, data }) => api.post(mutationKey[0], data),
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <Router>
      <SnackbarProvider>
        <App />
      </SnackbarProvider>
    </Router>
    <ReactQueryDevtools />
  </QueryClientProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
