import React from "react";
import NavBar from "./NavBar";
import SideSheet from "./SideSheet";
import MobileNav from "./MobileNav";
function Layout({ children }) {
  return (
    <>
      <div className="main-layout-wrapper">
        <div className="hidden md:block">
          <NavBar />
          <SideSheet />
        </div>
        <div className="block md:hidden">
          <MobileNav />
        </div>
        <div className="main-content-wrapper">{children}</div>
      </div>
    </>
  );
}

export default Layout;
