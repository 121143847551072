import React from "react";

const Input = React.forwardRef(
  (
    {
      label,
      onChange,
      className,
      placeholder = "",
      value,
      type = "text",
      required = false,
      pattern,
      xClass = "",
      title = "",
      name,
      unit = false,
    },
    ref
  ) => {
    const baseClasses = "";
    let inputClasses = `${baseClasses}`;
    switch (className) {
      case "primary":
        inputClasses += " primary-input";
        break;
      case "secondary":
        inputClasses += " secondary-input";
        break;
      case "disable-input":
        inputClasses += " primary-input disable-input";
        break;
      default:
        inputClasses += " primary-input";
    }

    return (
      <div className={`${xClass}  mb-4 `}>
        {label && (
          <p
            className={
              className === "primary" || className === "disable-input"
                ? "primary-input-label"
                : "secondary-input-label"
            }
            htmlFor="input"
          >
            {label}
          </p>
        )}
        <div className="relative">
          <input
            ref={ref}
            name={name}
            type={type}
            className={inputClasses}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            required={required}
            pattern={
              pattern || "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"
            }
            title={title}
          />
          {unit && (
            <span className="primary-input-label absolute  top-[17px] md:top-[13px] right-[12px] ">
              cm
            </span>
          )}
        </div>
      </div>
    );
  }
);

export default Input;
