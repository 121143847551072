import React, { useState } from "react";
import CardWrapper from "../../shared/CardWrapper/CardWrapper";
import BarChartColored from "../../shared/BarChartColored/BarChart";
// import Dropdown from "../../shared/Dropdown/Dropdown";
import { getTankLevelHistoryGraphQuery } from "../../util/query";
import { generateClient } from "aws-amplify/api";
import GraphSkeleton from "../../util/graphSkeleton";
import { getTimeFormattedArray, divideArray } from "../../util/helper";
// const TABLE_SELECT_FIELD = ["Day", "Week", "Month", "Year"];
const getCurrentDateFormatted = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
function Bar({ plantId }) {
  const [selected, setSelected] = useState(getCurrentDateFormatted());
  const [tankLevel, setTankLevel] = useState();
  const [refinedLabel, setRefinedLabel] = useState();
  const client = generateClient();
  const fetchTankLevel = async (name) => {
    try {
      const query = getTankLevelHistoryGraphQuery(
        selected.toLowerCase(),
        plantId
      );
      // Make the asynchronous GraphQL request
      const response = await client.graphql({ query });
      if (response) {
        const filteredData = await response.data.getTankLevelHistory.map(
          (item) => {
            if (Number(item.currentTankLevel) > 0) {
              return Number(item.currentTankLevel).toFixed(2);
            } else {
              return Number(item.currentTankLevel).toFixed(2);
            }
          }
        );

        const refinedData = divideArray(filteredData.reverse(), 1);

        setTankLevel(refinedData);
        setRefinedLabel(
          getTimeFormattedArray(response.data.getTankLevelHistory.reverse(), 1)
        );
      }
    } catch (error) {
      console.error(`Error fetching pressure data for ${name}:`, error);
    }
  };

  React.useEffect(() => {
    const fetchData = () => {
      fetchTankLevel();
    };
    fetchData(); // Initial fetch
    const interval = setInterval(fetchData, 600000); // Set interval to 10 minutes
    return () => clearInterval(interval); // Clear interval on component unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);
  const isFutureDate = (dateString) => {
    const inputDate = new Date(dateString);
    const currentDate = new Date(getCurrentDateFormatted());
    return inputDate > currentDate;
  };
  const handleDateChange = (event) => {
    if (isFutureDate(event.target.value)) {
      return;
    }

    setSelected(event.target.value);
    setTankLevel();
  };

  return (
    <>
      <CardWrapper className="mt-4 md:mt-0 h-full">
        <header className="p-2  pb-2 py-3 md:p-6 md:pb-0 md:pt-2 ">
          <div className="flex justify-between">
            <div className="dashboard-page-title mb-2">Tank level</div>
            <div className="table-container-select-field dropdown-bar-mob-view mb-1">
              <div class="relative max-w-sm">
                <input
                  datepicker
                  id="default-datepicker"
                  type="date"
                  class="bg-gray-50 border text-gray-900 text-sm rounded-lg    block w-full ps-3 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  placeholder="Select date"
                  style={{ background: "transparent", outline: "none" }}
                  onChange={handleDateChange}
                  value={selected}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <div className="table-container-select-field dropdown-bar-mob ">
              <div class="relative max-w-sm">
                <input
                  datepicker
                  id="default-datepicker"
                  type="date"
                  class="bg-gray-50 border text-gray-900 text-sm rounded-lg    block w-full ps-3 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  placeholder="Select date"
                  style={{ background: "transparent", outline: "none" }}
                  onChange={handleDateChange}
                  value={selected}
                />
              </div>
            </div>
          </div>
        </header>

        <div className="w-full h-full p-2 pl-1 md:p-6 pb-0 md:pb-0 ">
          {tankLevel ? (
            tankLevel.length > 0 ? (
              <BarChartColored
                barData={tankLevel}
                selected={selected}
                refinedLabel={refinedLabel}
              />
            ) : (
              <p className="d-flex items-center justify-center m-auto h-full text-center my-6">
                No Date Available for Selected Date.
              </p>
            )
          ) : (
            <GraphSkeleton />
          )}
        </div>
      </CardWrapper>
    </>
  );
}

export default Bar;
