import React, { useState } from "react";
import Pagination from "../../shared/Pagination/Pagination";
import Dropdown from "../../shared/Dropdown/Dropdown";
import { getOperationHours } from "../../util/query";
import { generateClient } from "aws-amplify/api";
import ListSkeleton from "../../util/listSkeleton";
import { getFormattedDate } from "../../util/helper";
import { CSVLink } from "react-csv";

const TABLE_HEAD = ["Interval", "Operating hours"];
const TABLE_CELL_WIDTH = 100 / TABLE_HEAD.length;
const PAGE_SIZE = 6;

export default function MachineTable({ plantId }) {
  const [selected, setSelected] = useState("Day");
  const [tableRows, setTableRows] = useState();
  const [totalPage, setTotalPage] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const TABLE_SELECT_FIELD = ["Day", "Week", "Month", "Year"];
  const client = generateClient();

  const fetchQualityhData = async (name) => {
    try {
      const query = getOperationHours(selected.toLowerCase(), plantId);
      // Make the asynchronous GraphQL request
      const response = await client.graphql({ query });
      setTableRows(response.data.getMachineHistoryOperationData);
    } catch (error) {
      console.error(`Error fetching pressure data for ${name}:`, error);
    }
  };
  React.useEffect(() => {
    const fetchData = () => {
      fetchQualityhData();
    };
    fetchData(); // Initial fetch
    const interval = setInterval(fetchData, 600000); // Set interval to 10 minutes
    return () => clearInterval(interval);
  }, [selected]);

  React.useEffect(() => {
    tableRows && setTotalPage(Math.ceil(tableRows.length / PAGE_SIZE));
    tableRows && setCurrentPage(1);
  }, [tableRows]);
  // startIndex and endIndex for table items
  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex =
    tableRows && Math.min(startIndex + PAGE_SIZE, tableRows.length);
  const displayedItems = tableRows && tableRows.slice(startIndex, endIndex);
  return (
    <div className="table-container">
      <div className="table-container-row">
        <div className="table-container-select-field">
          <Dropdown
            data={TABLE_SELECT_FIELD}
            setSelected={(singleField) => {
              setSelected(singleField);
              if (singleField !== selected) {
                setTableRows();
              }
            }}
            selected={selected}
          />{" "}
        </div>
        <Pagination
          currentPage={currentPage}
          totalPage={totalPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
      {/* Table Start */}
      <div className="w-full overflow-scroll">
        {tableRows ? (
          <table className="w-full table-auto">
            <thead>
              <tr>
                {TABLE_HEAD.map((head, index) => (
                  <th key={index} className="border-b p-4">
                    <div className="table-heading">{head}</div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {displayedItems.map(
                ({ time_interval, OperatingTime_hours }, index) => {
                  return (
                    <tr
                      key={index}
                      style={{
                        width: `${TABLE_CELL_WIDTH}%`,
                        borderBottom:
                          index !== displayedItems.length - 1 &&
                          "0.25px solid #545D6E",
                      }}
                    >
                      <td className="dark-bg">
                        <span>
                          {" "}
                          {getFormattedDate(time_interval, selected)}
                        </span>
                      </td>
                      <td
                        className="light-bg"
                        style={{ width: `${TABLE_CELL_WIDTH}%` }}
                      >
                        <span> {OperatingTime_hours}</span>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        ) : (
          <ListSkeleton breakpoint="lg" />
        )}
      </div>
      {/* Table End */}
      <div className="download-button">
        {tableRows && (
          <CSVLink
            data={tableRows}
            headers={[
              { label: "Interval", key: "time_interval" },
              { label: "Operating Time hours", key: "OperatingTime_hours" },
            ]}
            filename={`${"Operating-hours"}.csv`}
          >
            <span className="underline">Download</span>{" "}
          </CSVLink>
        )}
      </div>
    </div>
  );
}
