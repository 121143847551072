import React from "react";

function GraphSkeleton() {
  return (
    <div
      role="status"
      class="w-full p-4 border border-gray-200 rounded-lg shadow animate-pulse md:p-6 dark:border-gray-700"
    >
      {/* <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2.5"></div>
      <div class="w-48 h-2 mb-10 bg-gray-200 rounded-full dark:bg-gray-700"></div> */}
      <div class="flex items-baseline mt-4 graph-skeleton">
        <div class="w-full bg-gray-200 rounded-t-lg  dark:bg-gray-700"></div>
        <div class="w-full  ms-6 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
        <div class="w-full bg-gray-200 rounded-t-lg  ms-6 dark:bg-gray-700"></div>
        <div class="w-full  ms-6 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
        <div class="w-full bg-gray-200 rounded-t-lg  ms-6 dark:bg-gray-700"></div>
        <div class="w-full bg-gray-200 rounded-t-lg  ms-6 dark:bg-gray-700"></div>
        <div class="w-full bg-gray-200 rounded-t-lg  ms-6 dark:bg-gray-700"></div>
        <div class="w-full  ms-6 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
        <div class="w-full bg-gray-200 rounded-t-lg  ms-6 dark:bg-gray-700"></div>
        <div class="w-full bg-gray-200 rounded-t-lg  ms-6 dark:bg-gray-700"></div>
        <div class="w-full bg-gray-200 rounded-t-lg  ms-6 dark:bg-gray-700"></div>{" "}
        <div class="w-full  ms-6 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
        <div class="w-full bg-gray-200 rounded-t-lg  ms-6 dark:bg-gray-700"></div>
        <div class="w-full bg-gray-200 rounded-t-lg  ms-6 dark:bg-gray-700"></div>
        <div class="w-full bg-gray-200 rounded-t-lg  ms-6 dark:bg-gray-700"></div>
      </div>
      <span class="sr-only">Loading...</span>
    </div>
  );
}

export default GraphSkeleton;
