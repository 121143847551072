import React from "react";
import GaugeChart from "react-gauge-chart";
import "./SpeedoMeterFilter.css";

export default function SpeedoMeterFilter(props) {
  const {
    title = "Coarse filter",
    value = "0,2",
    className = "",
    labelTextStart,
    labelText,
    labelValue,
    labelText2,
    labelValue2,
    loading = "",
    maxVal,
  } = props;
  const fistLabel = labelTextStart
    ? `${labelText} ${labelValue}`
    : `${labelValue} - ${labelText}`;
  const secondLabel = labelTextStart
    ? `${labelText2} ${labelValue2}`
    : `>${labelValue2} - ${labelText2}`;

  const maxValue = maxVal ? maxVal : 6;
  function normalizeValue(value) {
    // Ensure the value is within the expected range
    if (value < 0) value = 0;
    if (value > maxValue) value = maxValue;
    // Normalize the value to fit within the 0 to 1 range
    return value / maxValue;
  }
  return (
    <div className={`speedo-meter-container ${className}`}>
      <div className="speedo-merter-heading">{title}</div>
      <GaugeChart
        id="gauge-chart1"
        nrOfLevels={40}
        arcWidth={0.05}
        percent={normalizeValue(value)}
        arcPadding={0.02}
        needleColor="#e8e8ea"
        needleBaseColor="#e8e8ea"
        hideText
      />
      <div
        className={"speedo-merter-points"}
        style={{
          fontSize: loading ? "12px" : "24px",
          padding: loading ? "7.2px" : "0px",
        }}
      >
        {loading ? "Loading..." : value}
      </div>
      <div className="speedo-merter-desc-container">
        <div className="speedo-merter-desc">{fistLabel}</div>
        <div className="speedo-merter-desc">{secondLabel}</div>
      </div>
    </div>
  );
}
