import React, { useState } from "react";
import "./Flow.css";
import CardWrapper from "../../shared/CardWrapper/CardWrapper";
import FlowTable from "./FlowTable";
import ReportFlowTag from "../../shared/ReportFlowTag/ReportFlowTag";
import BarChart from "../../dashboard/BarChart";
import {
  getWaterFlowProductionX08Query,
  getWaterFlowDistributionX04Query,
} from "../../util/query";
import { generateClient } from "aws-amplify/api";
import { getPlantId } from "../../util/helper";

export default function Flow() {
  const plantId = getPlantId();
  const client = generateClient();
  const [selectedFilter, setSelectedFilter] = useState("Day");
  const [tab, setTab] = useState("tab1");
  const [flowWaterProduction, setFlowWaterProduction] = useState();
  const [flowWaterDistribution, setFlowWaterDistribution] = useState();
  async function fetchFlowWaterProduction() {
    try {
      const query = getWaterFlowProductionX08Query(
        selectedFilter.toLowerCase(),
        plantId
      );
      // Make the asynchronous GraphQL request
      const flowIn = await client.graphql({ query });
      if (flowIn) {
        setFlowWaterProduction(flowIn.data.getWaterFlowProductionX08);
      }
      console.log({ flowIn });
    } catch (error) {
      console.error("Error fetching flow in production data:", error);
    }
  }

  async function fetchFlowWaterDistribution() {
    try {
      const query = getWaterFlowDistributionX04Query(
        selectedFilter.toLowerCase(),
        plantId
      );
      // Make the asynchronous GraphQL request
      const flowOut = await client.graphql({ query });
      if (flowOut) {
        setFlowWaterDistribution(flowOut.data.getWaterFlowDistributionX04);
      }
      console.log({ flowOut });
    } catch (error) {
      console.error("Error fetching flow in production data:", error);
    }
  }
  React.useEffect(() => {
    const fetchData = () => {
      if (tab === "tab1") {
        fetchFlowWaterProduction();
      }
      if (tab === "tab2") {
        fetchFlowWaterDistribution();
      }
    };
    fetchData(); // Initial fetch
    const interval = setInterval(fetchData, 600000); // Set interval to 10 minutes
    return () => clearInterval(interval);
  }, [tab, selectedFilter]);
  React.useEffect(() => {
    setFlowWaterProduction();
    setFlowWaterDistribution();
  }, [tab, selectedFilter]);

  const getCurrentValue = (tab) => {
    if (flowWaterProduction && tab === "tab1") {
      return Number(flowWaterProduction.current_flowLpmin_x08).toFixed(2);
    }

    if (flowWaterDistribution && tab === "tab2") {
      return Number(flowWaterDistribution.current_flowLpmin_x04).toFixed(2);
    }
  };

  const getRecentValue = (tab) => {
    if (flowWaterProduction && tab === "tab1") {
      return Number(flowWaterProduction.flow_totilizer_x08).toFixed(2);
    }
    if (flowWaterDistribution && tab === "tab2") {
      return Number(flowWaterDistribution.flow_totilizer_x04).toFixed(2);
    }
  };
  const getLabel = () => {
    return selectedFilter === "Day"
      ? "Recent 24 hours"
      : selectedFilter === "Month"
      ? "Current month"
      : selectedFilter === "Year"
      ? "Current year"
      : "Recent 7 days";
  };
  return (
    <div className="flow-page grid grid-cols-1 lg:grid-cols-3 gap-y-5 lg:gap-x-5">
      <div className="col-span-2">
        <BarChart
          setSelectedFilter={(val) => setSelectedFilter(val)}
          setTab={setTab}
          plantId={plantId}
        />
      </div>
      <CardWrapper className="flow-page-stats flow-page-stats-flow">
        <ReportFlowTag
          className="border-bottom"
          title={
            tab === "tab1"
              ? "Current produced water"
              : "Current distributed water"
          }
          number={getCurrentValue(tab) || 0}
          unit={"l/min"}
        />
        <ReportFlowTag
          title={getLabel(selectedFilter)}
          number={getRecentValue(tab, selectedFilter) || 0}
          unit={"&nbsp;m<sup>3</sup>"}
        />
      </CardWrapper>
      <div className="col-span-2">
        <FlowTable activeTab={tab} plantId={plantId} />
      </div>
    </div>
  );
}
