import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { Bar } from "react-chartjs-2";

Chart.register(...registerables);
function convertHoursToTimeString(totalHours) {
  const hours = Math.floor(totalHours);
  const minutes = Math.round((totalHours - hours) * 60);

  return `${hours} hours ${minutes} minutes`;
}
const WaterLevelChart = ({ barData, selected, refinedLabel }) => {
  const [label] = useState(refinedLabel);
  const [data] = useState(barData);
  const visibleData = data;

  // Calculate the lowest and highest values in the dataset
  const minValue = Math.min(...visibleData);
  const maxValue = Math.max(...visibleData);

  // Calculate opacity based on the data value
  const calculateOpacity = (value) => {
    const range = maxValue - minValue;
    const opacityRange = 0.3; // Minimum opacity
    return opacityRange + (1 - opacityRange) * ((value - minValue) / range);
  };

  // Create a gradient color array with varying opacities
  const gradientColors = visibleData.map((value) => {
    const opacity = calculateOpacity(value);
    return `rgba(35, 171, 185,1`; // Adjust color as needed
  });
  const chartData = {
    labels: label,
    datasets: [
      {
        // label: "Water Level",
        data: visibleData,
        fill: true,
        backgroundColor: (context) => {
          const gradient = context.chart.ctx.createLinearGradient(
            0,
            0,
            0,
            context.chart.height
          );
          gradient.addColorStop(0, gradientColors[0]);
          gradient.addColorStop(1, gradientColors[gradientColors.length - 1]);
          return gradient;
        },
        borderColor: "2px solid transparent",
        borderWidth: 1,
        borderRadius: 3,
        barPercentage: 1,
        categoryPercentage: 1,
        barThickness: "flex", // Set bar thickness as flexible
        categorySpacing: 4, // Set the spacing between bars
      },
    ],
  };

  const chartOptions = {
    indexAxis: "x",
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          font: {
            weight: "bold", // Set font weight to bold
            size: 8,
          },

          color: "#B5B9C0", // Set label color to white
          align: "inner", // Align labels to the start (left)
          labelOffset: 10,
        },
        grid: {
          color: "transparent",
        },
        border: {
          width: 2,
          color: "#0B1830",
        },
      },

      y: {
        beginAtZero: true,
        ticks: {
          font: {
            // weight: "bold", // Set font weight to bold
          },
          color: "#B5B9C0",
          align: "inner",
          weight: "bold",
          stepSize: 5,
        },

        grid: {
          color: "#545D6E",
          lineWidth: 0.25,
        },

        border: {
          width: 2,
          color: "#0B1830",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
  };
  const alternatingBackgroundPlugin = {
    id: "alternatingBackgroundPlugin",
    beforeDraw: (chart) => {
      const ctx = chart.ctx;
      const xAxis = chart.scales.x; // Assuming x is your horizontal axis
      const chartArea = chart.chartArea;

      // Colors to alternate
      const colors = ["#18243b", "#232f43"];

      // Calculate the width for each section
      const sectionWidth =
        (chartArea.right - chartArea.left) / xAxis.ticks.length;

      ctx.save();
      for (let i = 0; i < xAxis.ticks.length; i++) {
        ctx.fillStyle = colors[i % colors.length];
        // Calculate x position of each section. Add a check to ensure the last section is drawn to the end.
        let x = chartArea.left + i * sectionWidth;
        // Prevent overflow by not drawing beyond the chart area.
        let width =
          i === xAxis.ticks.length - 1 ? chartArea.right - x : sectionWidth;
        ctx.fillRect(x, chartArea.top, width, chartArea.bottom - chartArea.top);
      }
      ctx.restore();
    },
  };

  // Register the plugin
  Chart.register(alternatingBackgroundPlugin);
  return (
    <div className="m-auto text-center">
      <Bar data={chartData} options={chartOptions} />
      <p className="months-bar mt-3">
        <span className="cursor-pointer"> {"< "} &nbsp;</span>
        {selected} <span className="cursor-pointer">&nbsp;{">"}</span>
      </p>
    </div>
  );
};

export default WaterLevelChart;
