import React, { useState } from "react";
import TextField from "../shared/TextField";
import Button from "../shared/Button";
import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import api from "../util/api";
import { useSnackbar } from "notistack";
function AddBrand({ setIsAdd }) {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setIsAdd(!isModalOpen);
  };

  return (
    <div>
      {/* Main modal */}
      {isModalOpen && <Modal onClose={toggleModal} setIsAdd={setIsAdd} />}
    </div>
  );
}

function Modal({ onClose, setIsAdd }) {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [brand, setBrand] = useState("");

  const { mutate: addBrand } = useMutation(
    ["addBrand"],
    (data) => api.post("/admin/add_brand", data),
    {
      onSuccess: () => {
        enqueueSnackbar("Brand added successfully.", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        queryClient.invalidateQueries("allBrands");
      },
      onError: (error) => {
        enqueueSnackbar(error.response.data, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
    }
  );

  const onAdd = (e) => {
    e.preventDefault();
    if (brand) {
      const brand_id = "id" + Math.random().toString(16).slice(2);
      const data = {
        brand_id,
        brand_name: brand,
      };
      addBrand(data);
    }
    onClose();
  };
  return (
    <div className="fixed top-0 right-0 left-0 z-50 flex items-center justify-center w-full h-[calc(100%-1rem)] overflow-y-auto overflow-x-hidden md:inset-0 max-h-full backdrop-blur-sdm ">
      <div className="relative m-4 w-full  rounded-lg max-h-full max-w-[530px] border-[.1px]  border-gray-500 ">
        <div className="relative bg-white rounded-lg shadow bg-1">
          <div className="flex items-center justify-between p-4 md:p-4 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              Add Brand{" "}
            </h3>
            <button
              onClick={onClose}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-2 dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <form onSubmit={onAdd}>
            <div className="p-4 md:p-5 space-y-4">
              <TextField
                name="Brand"
                label="Brand Name"
                className="primary"
                type="text"
                required
                pattern=".{3,}"
                title="Minimum 3 characters name"
                value={brand}
                onChange={(e) => {
                  setBrand(e.target.value);
                }}
              />
            </div>
            <div
              className="flex items-center p-4 pt-0 md:p-5  pb-4 md:pt-0 border-gray-200 rounded-b dark:border-gray-600"
              style={{ paddingBottom: "2rem" }}
            >
              <Button label={"Add"} className="secondary" type="submit" />
              <button
                onClick={onClose}
                className="ml-3 py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddBrand;
