import React, { useState } from "react";
import MainBg from "../../assests/MAIN BG.png";
import MainBgMob from "../../assests/MAIN BG mob.png";
import Aquacontrol from "../../assests/aquacontrol.svg";
import TextField from "../shared/TextField";
import Button from "../shared/Button";
import { signIn } from "aws-amplify/auth";
import PasswordReset from "./ResetPassword";
import { useSnackbar } from "notistack";
import { fetchAuthSession } from "aws-amplify/auth";
import VerifyEmail from "./VerifyEmail";
import VerifyUser from "./VerifyUser";
import "./Styles.css";
import { signOut } from "aws-amplify/auth";
import { fetchUserAttributes } from "aws-amplify/auth";
import { sendUserAttributeVerificationCode } from "aws-amplify/auth";

const LoginPage = ({ onLogin }) => {
  const { enqueueSnackbar } = useSnackbar();
  // const [isChecked, setIsChecked] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [nextStep, setNextStep] = useState(false);
  const [showVerify, setShowVerify] = useState(false);

  const [formData, setFormData] = useState({
    username: "",
    password: -1,
  });
  const [error, setError] = useState("");

  // const handleCheckboxChange = () => {
  //   setIsChecked(!isChecked);
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value.trim(),
    }));
    setError("");
  };
  async function handleFetchUserAttributes() {
    try {
      const userAttributes = await fetchUserAttributes();
      return userAttributes;
    } catch (error) {
      console.log(error);
    }
  }
  async function handleSendUserAttributeVerificationCode(key) {
    try {
      const data = await sendUserAttributeVerificationCode({
        userAttributeKey: key,
      });
      if (data) {
        setShowVerify(true);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const handleSubmit = async (event) => {
    if (!formData.username || !formData.username) return;
    event.preventDefault();

    try {
      const username = formData.username;
      const password = formData.password;

      // Call Amplify's signIn function
      const { isSignedIn, nextStep } = await signIn({ username, password });

      if (
        nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
      ) {
        setNextStep(true);
      }

      if (isSignedIn) {
        const userAttributes = await handleFetchUserAttributes();

        async function currentSession() {
          try {
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
            localStorage.setItem(
              "auth",
              JSON.stringify({
                isSignedIn: isSignedIn,
                token: idToken.toString(),
                user_type: idToken.payload["custom:user_type"],
                organization_type: idToken.payload["custom:organization_type"],
                organization_id: idToken.payload["custom:organization_id"],
              })
            );
          } catch (err) {
            console.log(err);
          }
        }
        if (userAttributes.email_verified) {
          await currentSession();
          onLogin();
          enqueueSnackbar("Logged in successfully.", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
        } else {
          handleSendUserAttributeVerificationCode("email");
        }
      }
    } catch (error) {
      console.error("Sign-in error:", error);
      if (error.message === "There is already a signed in user.") {
        localStorage.clear();
        localStorage.removeItem("hasNavigated");
        localStorage.removeItem("reportActiveTab");
        await signOut();
        window.location.reload();
      }

      setError(error.message || "An error occurred.");
    }
  };

  return (
    <div className="flex   w-full h-screen flex-col md:flex-row ">
      <div className="left-login-wrapper w-full h-[60%] md:w-[37%]  md:h-full  flex items-center justify-center relative">
        <img
          src={MainBg}
          alt=""
          className="w-full h-full hidden md:h-screen md:block"
        />
        <img src={MainBgMob} alt="" className="w-full h-full md:hidden" />
        <img
          src={Aquacontrol}
          alt=""
          className="absolute w-[269px] h-[94px] md:w-[360px] md:h-[124px]  mb-[1rem] md:mb-[6rem] mob-banner"
        />
      </div>

      <div className="right-login-wrapper bg-1 w-full md:w-[67%] flex items-center justify-center md:justify-start h-full">
        {!resetPassword && !nextStep && !showVerify && (
          <form className="w-full" onSubmit={handleSubmit}>
            <div className="login-form">
              <p className="login-heading">Login</p>
              <TextField
                name="username"
                label="Username"
                className="secondary"
                required
                onChange={handleInputChange}
              />
              <TextField
                name="password"
                label="Password"
                className="secondary"
                xClass="mb-3.3"
                pattern=".{7,}"
                type="password"
                required
                title="Password must be exactly 7 characters long"
                onChange={handleInputChange}
              />
              <p className="mt-px font-light text-gray-700 cursor-pointer select-none checkbox-lable mb-4">
                Forgot Password?{" "}
                <span
                  className="underline"
                  onClick={() => {
                    setResetPassword(true);
                  }}
                >
                  Reset Password
                </span>
              </p>
              {error && <p className="error-msg">{error}</p>}
              <Button label="LOGIN" className="primary" />
              <br></br>
              {/* <div class="inline-flex items-center  mt-6">
                <label
                  class="relative flex items-center   cursor-pointer"
                  htmlFor="check"
                >
                  <input
                    type="checkbox"
                    class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none  border bg-white border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4  before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <span class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      stroke="currentColor"
                      stroke-width="1"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </span>
                </label>
                <label
                  className="mt-px font-light text-gray-700 cursor-pointer select-none ml-3 checkbox-lable"
                  htmlFor="check"
                >
                  By proceeding I agree to the{" "}
                  <a href="/terms" className="underline">
                    Terms of use{" "}
                  </a>
                </label>
              </div> */}
            </div>
          </form>
        )}
        {resetPassword && !nextStep && !showVerify && (
          <div className="login-form">
            <PasswordReset />
          </div>
        )}
        {nextStep && !showVerify && (
          <div className="login-form">
            <VerifyUser onLogin={onLogin} />
          </div>
        )}
        {showVerify && (
          <div className="login-form">
            {" "}
            <VerifyEmail onLogin={onLogin} />{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
