import React, { useState, useEffect } from "react";
import CardWrapper from "../../shared/CardWrapper/CardWrapper";
import AreaChart from "../../shared/AreaChart/AreaChart";
import Dropdown from "../../shared/Dropdown/Dropdown";
import { generateClient } from "aws-amplify/api";
import { getOperationHours } from "../../util/query";
import ListSkeleton from "../../util/listSkeleton";
import { getTimeFormattedArray } from "../../util/helper";
import GraphSkeleton from "../../util/graphSkeleton";

function convertTimeStringToHours(timeString) {
  const hoursMatch = timeString.match(/(\d+)\s*hours/);
  const minutesMatch = timeString.match(/(\d+)\s*minutes/);

  const hours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
  const minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;

  const totalHours = hours + minutes / 60;
  return parseFloat(totalHours.toFixed(2));
}
function Bar({ setOperatingHours, setSelectedFilter, plantId }) {
  const [selected, setSelected] = useState("Day");
  const [operationHours, setOperationHours] = useState();

  const [refinedLabel, setRefinedLabel] = useState();

  const TABLE_SELECT_FIELD = ["Day", "Week", "Month", "Year"];

  const client = generateClient();

  async function fetchOperationHours() {
    try {
      const query = getOperationHours(selected.toLowerCase(), plantId);
      // Make the asynchronous GraphQL request
      const operationHours = await client.graphql({
        query,
      });
      setOperatingHours(operationHours.data.getMachineHistoryOperationData[0]);
      setSelectedFilter(selected);
      const filteredData =
        await operationHours.data.getMachineHistoryOperationData.map((item) => {
          if (item?.OperatingTime_hours) {
            return convertTimeStringToHours(
              item.OperatingTime_hours || "0 hours 0 minutes"
            );
          } else {
            return "0 hours 0 minutes";
          }
        });
      setOperationHours(filteredData.reverse());
      setRefinedLabel(
        getTimeFormattedArray(
          operationHours.data.getMachineHistoryOperationData.reverse(),
          1,
          selected === "Year" ? "year" : undefined
        )
      );
    } catch (error) {
      console.error("Error fetching flow in production data:", error);
    }
  }

  useEffect(() => {
    const fetchData = () => {
      fetchOperationHours();
    };
    fetchData(); // Initial fetch
    const interval = setInterval(fetchData, 600000); // Set interval to 10 minutes
    return () => clearInterval(interval); // Clear interval on component unmount or when activeTab changes
  }, [selected]);

  return (
    <>
      <CardWrapper>
        <header className="p-2  pb-2 py-3 md:p-6 md:pb-0 md:pt-2 ">
          <div className="flex justify-between">
            <div className="dashboard-page-title mb-2">Operating hours</div>
            <div className="table-container-select-field dropdown-bar-mob-view mb-1">
              <Dropdown
                data={TABLE_SELECT_FIELD}
                setSelected={(singleField) => {
                  setSelected(singleField);
                  if (singleField !== selected) {
                    setOperationHours();
                  }
                }}
                selected={selected}
              />{" "}
            </div>
          </div>
          <div className="flex justify-end">
            <div className="table-container-select-field dropdown-bar-mob ">
              <Dropdown
                data={TABLE_SELECT_FIELD}
                setSelected={(singleField) => {
                  setSelected(singleField);
                  if (singleField !== selected) {
                    setOperationHours();
                  }
                }}
                selected={selected}
              />{" "}
            </div>
          </div>
        </header>

        <div className="w-full p-2 pl-1 md:p-6 pb-0 md:pb-0">
          {operationHours ? (
            <AreaChart
              barData={operationHours}
              selected={selected}
              refinedLabel={refinedLabel}
            />
          ) : (
            <GraphSkeleton breakpoint="lg" />
          )}
        </div>
      </CardWrapper>
    </>
  );
}

export default Bar;
