import React, { useState } from "react";
import Pagination from "../shared/Pagination/Pagination";
import AddBrand from "./AddBrand";
import Button from "../shared/Button";
import api from "../util/api";
import { useQuery } from "react-query";

const TABLE_HEAD = ["Brand Name", "Created Date"];
const TABLE_CELL_WIDTH = 100 / TABLE_HEAD.length;
const PAGE_SIZE = 6;

export default function OrganizationsListing() {
  const [currentPage, setCurrentPage] = useState(1);
  const [isAdd, setIsAdd] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  // startIndex and endIndex for table items
  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = Math.min(startIndex + PAGE_SIZE, tableRows.length);
  const displayedItems = tableRows.slice(startIndex, endIndex);

  const { data: allBrands } = useQuery(
    "allBrands",
    () => api.get("/admin/get_brands"),
    {
      enabled: true,
      select: (response) => response.data.brands,
    }
  );
  React.useEffect(() => {
    if (allBrands?.length > 0) {
      setTotalPage(Math.ceil(allBrands.length / PAGE_SIZE));
      setTableRows(allBrands);
    }
  }, [allBrands]);

  function formatTimestampToDate(timestamp) {
    const date = new Date(timestamp * 1000);
    const formatter = new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });

    return formatter.format(date);
  }
  return (
    <>
      <div className="table-container">
        <div className="table-container-row">
          <div className="table-container-select-field"></div>

          <Button
            label={"+ Add Brand"}
            className="secondary"
            onClick={() => setIsAdd(true)}
          />
        </div>
        {/* Table Start */}
        <div className="w-full overflow-scroll">
          <table className="w-full table-auto">
            <thead>
              <tr>
                {TABLE_HEAD.map((head, index) => (
                  <th key={index} className="border-b p-4">
                    <div
                      className="secondary-font "
                      style={{ fontWeight: 700 }}
                    >
                      {head}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {displayedItems.map(
                ({ brand_name, creation_timestamp }, index) => {
                  return (
                    <tr
                      key={index}
                      style={{
                        width: `${TABLE_CELL_WIDTH}%`,
                        borderBottom:
                          index !== displayedItems.length - 1 &&
                          "0.25px solid #545D6E",
                      }}
                    >
                      <td className="dark-bg">
                        <span> {brand_name}</span>
                      </td>
                      <td
                        className="light-bg"
                        style={{ width: `${TABLE_CELL_WIDTH}%` }}
                      >
                        <span>
                          {" "}
                          {formatTimestampToDate(creation_timestamp)}
                        </span>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </div>
        {/* Table End */}
        <div className="download-button">
          <Pagination
            currentPage={currentPage}
            totalPage={totalPage}
            setCurrentPage={setCurrentPage}
          />{" "}
        </div>
      </div>
      {isAdd && <AddBrand setIsAdd={setIsAdd} />}
    </>
  );
}
