import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import SpeedoMeterFilter from "../shared/SpeedoMeterFilter/SpeedoMeterFilter";
import CardWrapper from "../shared/CardWrapper/CardWrapper";
import StatusTag from "../shared/StatusTag/StatusTag";
import TankLevel from "../shared/TankLevel/TankLevel";
import SenorTag from "../shared/SensorTag/SensorTag";
import BarChart from "./BarChart";
import { generateClient } from "aws-amplify/api";
import {
  getFlowProductionQuery,
  getIotDataQuery,
  getAlertQuery,
  getStatusBarX05AndX07Query,
  getPLantPortStatusAlertQuery,
} from "../util/query";
import Waves from "../../assests/icons/Waves";
import Pressure from "../../assests/icons/Pressure";
import { getPlantId } from "../util/helper";
import {
  getWaterFlowProductionX08Query,
  getWaterFlowDistributionX04Query,
} from "../util/query";
import ReportFlowTag from "../shared/ReportFlowTag/ReportFlowTag";
const getLabel = (port) => {
  if (port === "x03") {
    return "Water flow in";
  } else if (port === "x01") {
    return "Pressure in";
  } else if (port === "x08") {
    return "Water production";
  } else if (port === "x05") {
    return "Filter 1 pressure";
  } else if (port === "x07") {
    return "Filter 2 pressure";
  } else if (port === "x04") {
    return "Water distribution";
  }
};
// Custom order for ports
const portOrder = ["x03", "x01", "x05", "x07", "x08", "x04"];

// Sort function
const customSort = (a, b) => {
  const portIndexA = portOrder.indexOf(a.port);
  const portIndexB = portOrder.indexOf(b.port);
  return portIndexA - portIndexB;
};
function Dashboard({ plant_id }) {
  const plantId = getPlantId() || plant_id;

  const client = generateClient();
  const [monitorData, setMonitorData] = useState(null);
  const [deviceStatus, setDeviceStatus] = useState(null);
  const [alerts, setAlerts] = useState(null);
  const [alert07, setAlert07] = useState(null);
  //start for water flow in out card
  const [selectedFilter, setSelectedFilter] = useState("Day");
  const [tab, setTab] = useState("tab1");
  const [flowWaterProduction, setFlowWaterProduction] = useState();
  const [flowWaterDistribution, setFlowWaterDistribution] = useState();
  //end for water flow in out card

  async function fetchFlowInProduction() {
    try {
      const query = getFlowProductionQuery(plantId);
      // Make the asynchronous GraphQL request
      const flowIn = await client.graphql({ query });
      if (flowIn && flowIn.data.getTankAndPressureData) {
        setMonitorData(flowIn.data.getTankAndPressureData);
      }
    } catch (error) {
      console.error("Error fetching flow in production data:", error);
    }
  }

  async function fetchSensorStatus() {
    try {
      const query = getPLantPortStatusAlertQuery(plantId);
      // Make the asynchronous GraphQL request
      const status = await client.graphql({ query });
      const sortedDevices = status.data.getPLantPortStatusAlert
        .filter((item) => item.port !== "mo1")
        .sort(customSort);
      setDeviceStatus(sortedDevices);
    } catch (error) {
      console.error("Error fetching flow in production data:", error);
    }
  }

  async function fetchSensorStatusAlert() {
    try {
      const query = getAlertQuery(plantId);
      // Make the asynchronous GraphQL request
      const status = await client.graphql({ query });
      setAlerts(status.data);
    } catch (error) {
      console.error("Error fetching flow in production data:", error);
    }
  }
  async function getStatusBarX05AndX07Alert() {
    try {
      const query = getStatusBarX05AndX07Query(plantId);
      // Make the asynchronous GraphQL request
      const status = await client.graphql({ query });
      setAlert07(status.data);
    } catch (error) {
      console.error("Error fetching flow in production data:", error);
    }
  }
  useEffect(() => {
    const fetchData = () => {
      fetchFlowInProduction();
      fetchSensorStatus();
      fetchSensorStatusAlert();
      getStatusBarX05AndX07Alert();
    };
    fetchData(); // Initial fetch
    const interval = setInterval(fetchData, 600000); // Set interval to 10 minutes
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const tagColor = (alert) => {
    if (alert === "GREEN") {
      return "primary";
    } else if (alert === "RED") {
      return "error";
    } else {
      return "error";
    }
  };

  const uniqueLabels = new Set();

  // Filter deviceStatus to include only items with unique labels
  const uniqueDeviceStatus = (deviceStatus) => {
    const uniqueLabel = deviceStatus.filter((item) => {
      const label = getLabel(item.port);
      if (uniqueLabels.has(label)) {
        return false; // Skip this item if the label is already in the set
      }
      uniqueLabels.add(label);
      return true; // Include this item if the label is unique
    });
    return uniqueLabel;
  };
  //start for water flow in out card

  const getLabelFlow = () => {
    return selectedFilter === "Day"
      ? "Recent 24 hours"
      : selectedFilter === "Month"
      ? "Current month"
      : selectedFilter === "Year"
      ? "Current year"
      : "Recent 7 days";
  };
  const getRecentValue = (tab) => {
    if (flowWaterProduction && tab === "tab1") {
      return Number(flowWaterProduction.flow_totilizer_x08).toFixed(2);
    }
    if (flowWaterDistribution && tab === "tab2") {
      return Number(flowWaterDistribution.flow_totilizer_x04).toFixed(2);
    }
  };

  const getCurrentValue = (tab) => {
    if (flowWaterProduction && tab === "tab1") {
      return Number(flowWaterProduction.current_flowLpmin_x08).toFixed(2);
    }

    if (flowWaterDistribution && tab === "tab2") {
      return Number(flowWaterDistribution.current_flowLpmin_x04).toFixed(2);
    }
  };
  async function fetchFlowWaterProduction() {
    try {
      const query = getWaterFlowProductionX08Query(
        selectedFilter.toLowerCase(),
        plantId
      );
      // Make the asynchronous GraphQL request
      const flowIn = await client.graphql({ query });
      if (flowIn) {
        setFlowWaterProduction(flowIn.data.getWaterFlowProductionX08);
      }
      console.log({ flowIn });
    } catch (error) {
      console.error("Error fetching flow in production data:", error);
    }
  }

  async function fetchFlowWaterDistribution() {
    try {
      const query = getWaterFlowDistributionX04Query(
        selectedFilter.toLowerCase(),
        plantId
      );
      // Make the asynchronous GraphQL request
      const flowOut = await client.graphql({ query });
      if (flowOut) {
        setFlowWaterDistribution(flowOut.data.getWaterFlowDistributionX04);
      }
      console.log({ flowOut });
    } catch (error) {
      console.error("Error fetching flow in production data:", error);
    }
  }
  React.useEffect(() => {
    const fetchData = () => {
      if (tab === "tab1") {
        fetchFlowWaterProduction();
      }
      if (tab === "tab2") {
        fetchFlowWaterDistribution();
      }
    };
    fetchData(); // Initial fetch
    const interval = setInterval(fetchData, 600000); // Set interval to 10 minutes
    return () => clearInterval(interval);
  }, [tab, selectedFilter]);
  React.useEffect(() => {
    setFlowWaterProduction();
    setFlowWaterDistribution();
  }, [tab, selectedFilter]);

  //end for water flow in out card

  return (
    <div className="dashboard-page grid grid-cols-1 xl:grid-cols-3 md:gap-5 mb-10">
      <div className="dashboard-page-first-column col-span-2">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div className="dashboard-page-title col-span-1 md:col-span-2">
            Filter overview
          </div>
          {monitorData ? (
            <>
              <SpeedoMeterFilter
                title="Filter 1"
                labelText="FILTER INSPECTION"
                labelValue="5,0"
                labelText2="PROBABLE FILTER CHANGE"
                labelValue2="> 1.0"
                labelTextStart={true}
                value={Number(monitorData?.filter1Result).toFixed(2) || 0}
              />
              <SpeedoMeterFilter
                title="Filter 2"
                labelText="FILTER INSPECTION"
                labelValue="5,0"
                labelText2="PROBABLE FILTER CHANGE"
                labelValue2="> 1.0"
                labelTextStart={true}
                value={Number(monitorData?.filter2Result).toFixed(2) || 0}
              />
            </>
          ) : (
            <>
              <SpeedoMeterFilter
                title="Filter 1"
                labelText="FILTER INSPECTION"
                labelValue="5,0"
                labelText2="PROBABLE FILTER CHANGE"
                labelValue2="> 1.0"
                labelTextStart={true}
                value={0}
                loading="loading"
              />
              <SpeedoMeterFilter
                title="Filter 2"
                labelText="FILTER INSPECTION"
                labelValue="5,0"
                labelText2="PROBABLE FILTER CHANGE"
                labelValue2="> 1.0"
                labelTextStart={true}
                value={0}
                loading="loading"
              />
            </>
          )}
        </div>
        <div className="mt-4">
          <BarChart
            plantId={plantId}
            setSelectedFilter={(val) => setSelectedFilter(val)}
            setTab={setTab}
          />
        </div>
      </div>

      <div className="dashboard-page-second-column">
        {/* //start for water flow in out card */}

        <CardWrapper className="flow-page-stats flow-page-stats-flow mt-5  md:mt-10">
          <ReportFlowTag
            className="border-bottom"
            title={
              tab === "tab1"
                ? "Current produced water"
                : "Current distributed water"
            }
            number={getCurrentValue(tab) || 0}
            unit={"l/min"}
          />
          <ReportFlowTag
            title={getLabelFlow(selectedFilter)}
            number={getRecentValue(tab, selectedFilter) || 0}
            unit={"&nbsp;m<sup>3</sup>"}
          />
        </CardWrapper>
        {/*   //end for water flow in out card
         */}

        <CardWrapper className={"p-3 mt-5"}>
          <div className="dashboard-page-title col-span-2 mb-5 mt-5 md:mt-0">
            Status
          </div>
          <div className="grid grid-cols-2 gap-x-5 px-2">
            <StatusTag
              text="Intake"
              type={
                alerts
                  ? tagColor(alerts?.getStatusPressureIntakex01.status_alert)
                  : "primary"
              }
            />
            <StatusTag
              text="Operation"
              type={
                alerts
                  ? tagColor(alerts?.getStatusOperation.status_alert)
                  : "primary"
              }
            />
            <StatusTag
              text="Filter 1"
              type={
                alerts
                  ? tagColor(alerts?.getStatusBarX07AndX05.status_alert)
                  : "primary"
              }
            />
            <StatusTag
              text="Filter 2"
              type={
                alerts
                  ? tagColor(alert07?.getStatusBarX07AndX05.status_alert)
                  : "primary"
              }
            />

            <StatusTag
              text="Tank level"
              type={
                alerts
                  ? tagColor(
                      alerts?.getStatusTankLevelBelow60Percentage.status_alert
                    )
                  : "primary"
              }
            />
            <StatusTag
              text="Water prod."
              type={
                alerts
                  ? tagColor(alerts?.getStatusBarWaterProduction.status_alert)
                  : "primary"
              }
            />
          </div>
        </CardWrapper>
        <CardWrapper className={"my-5"}>
          <div className="p-2">
            <div className="dashboard-page-title">Sensors</div>
            <div className="grid grid-cols-2 my-4 gap-7">
              <StatusTag text={"Connected"} type="tertiary" />
              <StatusTag text={"Disconnected"} type={"disabled"} />
            </div>
            <div className="grid grid-cols-2 gap-7 pb-3">
              {deviceStatus &&
                uniqueDeviceStatus(deviceStatus).map((item) => (
                  <SenorTag
                    key={item.port} // Assuming item.port is a unique key
                    type={
                      item.connection_status.toLowerCase() === "connected"
                        ? "primary"
                        : "secondary"
                    }
                    text={getLabel(item.port)}
                    icon={
                      getLabel(item.port).includes("Water") ? (
                        <Waves />
                      ) : (
                        <Pressure />
                      )
                    }
                  />
                ))}
            </div>
          </div>
        </CardWrapper>
        <CardWrapper className="flex justify-center">
          <TankLevel
            recenter
            className={"pt-5"}
            progressValue={
              Number(monitorData?.currentTankLevel).toFixed(2) || 0
            }
            maxTankLevel={Number(monitorData?.maxTankLevel)?.toFixed(2)}
          />
        </CardWrapper>
      </div>
    </div>
  );
}

export default Dashboard;
