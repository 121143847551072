import React, { useEffect, useState } from "react";
import "./Monitor.css";
import MonitorIndicator from "../shared/MonitorIndicator/MonitorIndicator";
import TankLevel from "../shared/TankLevel/TankLevel";
import ProgressBar from "../shared/ProgressBar/ProgressBar";
import { Link } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import * as subscriptions from "../util/subscriptions";
import ListSkeleton from "../util/listSkeleton";
import { getFlowProductionQuery } from "../util/query";
import { Tooltip as ReactTooltip } from "react-tooltip";
import api from "../util/api";
import { useQuery } from "react-query";
export default function Monitor() {
  const client = generateClient();
  const user_type = JSON.parse(localStorage.getItem("auth"))?.user_type;
  const [monitorData, setMonitorData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { data: getPlants } = useQuery(
    "getPlant",
    () =>
      api.get(
        user_type === "viewer"
          ? "/viewer/get_brand_plants"
          : "/admin/get_brand_plants"
      ),
    {
      enabled: true,
      select: (response) => response?.data,
      onSuccess: (data) => {
        console.log(data);
      },
    }
  );

  const types = ["primary", "secondary", "error"];

  const getTypeBasedOnValue = (currentTankLevel, maxTankLevel) => {
    // Calculate the percentage of the current tank level
    const percentage = (currentTankLevel / maxTankLevel) * 100;
    // Determine the type based on the percentage
    if (percentage >= 85) {
      return types[0]; // "primary"
    } else if (percentage >= 65) {
      return types[1]; // "secondary"
    } else {
      return types[2]; // "error"
    }
  };
  async function fetchFlowInProduction(plant_id) {
    try {
      const query = getFlowProductionQuery(plant_id);
      // Make the asynchronous GraphQL request
      const flowIn = await client.graphql({ query });
      // const flowInData = flowIn.data.onFlowInProduction;
      if (flowIn && flowIn.data.getTankAndPressureData) {
        setMonitorData((pre) => {
          return [...pre, flowIn.data.getTankAndPressureData];
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching flow in production data:", error);
    }
  }

  React.useEffect(() => {
    const fetchData = () => {
      setMonitorData([]);
      getPlants.forEach((item) => {
        fetchFlowInProduction(item.plant_id);
      });
    };
    getPlants && fetchData(); // Initial fetch
    const interval = setInterval(fetchData, 600000); // Set interval to 10 minutes
    return () => clearInterval(interval); // Clear interval on component unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPlants]);
  return (
    <div className="monitor-page">
      <div className="monitor-row">
        <div className="monitor-title">Warnings</div>
        {isLoading ? (
          <div>
            <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-full max-w-[200px] mb-2.5"></div>
            <div class="w-full max-w-[300px] h-2 mb-10 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
        ) : (
          <div className="filter-rows">
            {monitorData?.map((item, idx) => (
              <MonitorIndicator
                key={idx}
                // type=
                type={getTypeBasedOnValue(
                  item.currentTankLevel,
                  item.maxTankLevel
                )}
                text={item?.plantName || "Plant"}
                notFullWidth
              />
            ))}
          </div>
        )}
      </div>
      <div className="monitor-title">Facilities</div>
      {isLoading ? (
        <ListSkeleton />
      ) : (
        <>
          <div className="monitor-row">
            <div className="grid gap-10 grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3">
              {monitorData?.map((singleItem, idx) => {
                return (
                  <div key={idx} className="facilities-single-container">
                    <MonitorIndicator
                      text={singleItem.plantName}
                      type={getTypeBasedOnValue(
                        singleItem.currentTankLevel,
                        singleItem.maxTankLevel
                      )}
                    />
                    <div className="facilities-filter">
                      <div className="filter-container">
                        <div
                          data-tooltip-id={"fitlerOne"}
                          data-tooltip-content={
                            Number(singleItem.filter1Result).toFixed(2) + " bar"
                          }
                        >
                          <ProgressBar
                            progressValue={singleItem.filter1Result}
                            title="Filter 1"
                          />
                        </div>
                        <ReactTooltip
                          id={"fitlerOne"}
                          place="bottom"
                          content={
                            Number(singleItem.filter1Result).toFixed(2) + " bar"
                          }
                        />
                        <div
                          data-tooltip-id={"filter1"}
                          data-tooltip-content={
                            Number(singleItem.filter2Result).toFixed(2) + " bar"
                          }
                        >
                          <ProgressBar
                            progressValue={singleItem.filter2Result}
                            title="Filter 2"
                          />
                        </div>

                        <ReactTooltip
                          id={"filter1"}
                          place="bottom"
                          content={
                            Number(singleItem.filter2Result).toFixed(2) + " bar"
                          }
                        />
                        <div>
                          <p className="pro-per-day">Production in 24hrs</p>
                          <p className="pro-per-day mt-4">
                            {Number(singleItem.producedWaterx08).toFixed(2)}
                            &nbsp;m<sup>3</sup>
                          </p>
                        </div>
                      </div>
                      <TankLevel
                        maxTankLevel={singleItem.maxTankLevel}
                        isSmall
                        progressValue={singleItem.currentTankLevel}
                      />
                    </div>
                    <Link
                      to="/more-info-dashboard"
                      preventScrollReset={true}
                      onClick={() =>
                        localStorage.setItem("plantId", singleItem.plantID)
                      }
                      state={{ orgName: singleItem?.organization_name }}
                    >
                      {" "}
                      <div className="more-info-text">More info</div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
