import React, { useState, useRef, useEffect } from "react";
import TextField from "../shared/TextField";
import Button from "../shared/Button";
import AddIcon from "../../assests/Add.svg";
import { useQueryClient } from "react-query";
import { useQuery, useMutation } from "react-query";
import api from "../util/api";
import { useSnackbar } from "notistack";
import DropdownPrimary from "../shared/Dropdown/DropdownPrimary";
import Pagination from "./Pagination";
const avatarPlaceHolder =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAclBMVEX///8AAADx8fEJCQn39/fw8PD8/PzZ2dn09PTi4uKWlpZwcHDQ0NB9fX1jY2Ps7OxJSUm3t7cqKiqlpaVoaGiFhYW/v79DQ0MXFxd5eXktLS1dXV0lJSXIyMigoKA4ODgdHR1VVVWPj4+tra1PT0+Tk5Na8LRmAAAEkElEQVR4nO3d63aqMBAFYCPIpaiI1vut2vb9X/GUti57LJjLkExi9/e/a2UvQJLJkPZ6AAAAAAAAAAAAAAAAAAAAAAAAAH9VEmf9LE64h2FHklaj8XK6ilbT5XhUpY8Wc1HsxP92xYJ7UN0pz6LZueQeWifKdUu+2voBMj7dyVd74h4gUbqRBBRik3IPkmImzVc7cA/TWFIoBRSiCPTVkbwpBhTiLciI8VE5oBDHmHu4+uKJRkAhluFF3GoFFGLLPWBdI82AQsy5h6yn0g4oRMU9aB25QUAhQprB6T6EXwJ6FE3u0Vow92liGFCIUF78suVEu0AWGrFxQCHCeO8fCAmDWGYkU0LCaQhP4oIQUIgQylPPpIQF9/DlMlJAITLuAFK0mzSE21R/UfG/EXcAKWJAEXEHkBlQE4oBdwQJ6mPo/4OoViG9Z8YdQYL2Nqz5/kZckhMuuSNIUCalX6bcESReyAlfuCPcF0fkhCu/14h9esLI75lpFwn73CHuyjpI6Pdd+vi/NL3bphJ9O+4IEjqbhs2O3BEkxuSEY+4IEubV4Avfq8KmWxZXvm9emO2r/ZRzR5AhJ+QOIEVdID5zB5B6JSZ85Q4gVRITBrDVfSIFPHEPXwHtNvX/Ju31BpTlReR7tfQTpa4fRtsQ5aUfwO9MTbWt9Dffa6UX5i+MQC6h+QLD92XFVSLvX29yDKFN4dveKOGQe9g62r6SuefMPWg96l3sF2/cQ9aU6Rbddn6Xuhvovve9X9r/phcxwIB6EYMM+DG3Ud0unQYzl7mVqNWHx57vxdyl0prhe/OFRC5r298G+gj+sL/3Zlz53h+kZtH2kdfyMfLV0vnvXbfjPOjPY39J8tn62k20XM/ygJZK6pI4T4dp/qjHRgCAU3m1MKjOl2Uo89P8syKsWxncf0wLNk8hLPSHl4noRqd0Vn6vQla+Z0wWPyulJ9VJy2D+46/WHk91ssPtkvdZZbT5/OavTnvrQzUyOK8aJteTSjKB2Tetko8ebpT2by/EVdF+SYbztu3UlWfr4oGkxr2dpf2ba9lPZ5LGlJk/L49Yaa9pWYwO1X6YDvfVYVSonAuye/Vkiv5K75pts/GhyW1oL1/tyP3uKM13tFWtWdszqC1eSiK+W7U8uQj4Ycv0gQK9WVYdR1EuoX7wq8d9K9FA76ArujfHd2pK/zZGV+S0/D90nq/msFfDrJmEztmqiucK1hxdRfoHB+acPIvafSRd2rlYUdG/R6dw8LGCy5lME+uTVOrxM3S2y43t5RhXLM/f+C+h7YtI/7yQzu6JZ2bNv93a2AyYcqf7ZLN0Qz99pgs2i8X2604qbH6Vod/bbIPFfumYfvpMF6b2JqdZ0+6Seyt7b8QOTi7pgsXTT5DQESREQiTkZzFhZnc3VJXNT6Rcb1Y0m9gL+Adm3tyFti82y22k8xK6YvfcBf5Sm+1iG+W/AnTFcmGffk4wlfUt/XfmgO+2A3JHdBCQ90Z11HUSt3aF2hXN3fVjDqpisotc2k2KynWDW9Z3yfMWfgAAAAAAAAAAAAAAAAAAAAAAAABF/wCB50Scz71fkQAAAABJRU5ErkJggg==";
function AddUser({ editData }) {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [imageSrc, setImageSrc] = useState("");
  const [selected, setSelected] = useState("Select Role");
  const [file, setFile] = useState("");
  const dropdownRef = useRef(null);
  const dropdownRefUser = useRef(null);
  const [allusersData, setAlluserData] = useState([]);
  const [allUserWithImage, setAllUserWithImage] = useState([]);
  const [error, setError] = useState("");
  const inputRef = useRef(null);
  const addUserRef = useRef(null);

  const [isOpenUser, setIsOpenUser] = useState("");
  const [editUser, setEditUser] = useState("");
  const [formData, setFormData] = useState({
    user_email: "",
    user_type: "",
    name: "",
    profile_picture: "",
  });
  const handleAddBtnClick = () => {
    inputRef.current.focus();
  };

  const newUserClick = () => {
    addUserRef.current.focus();
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefUser.current &&
        !dropdownRefUser.current.contains(event.target)
      ) {
        setIsOpenUser("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdownUser = (userId) => {
    setIsOpenUser(userId);
  };

  const uploadImageToPresignedURL = async (presignedURL) => {
    try {
      const response = await fetch(presignedURL, {
        method: "PUT",
        headers: {
          "Content-Type": file.type,
        },
        body: file,
      });

      if (response.ok) {
        console.log("Image uploaded successfully!");
      } else {
        console.error("Failed to upload image:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
    setFile("");
  };

  const { mutate } = useMutation(
    (file) =>
      api.get(`/admin/get_profile_pic_upload_link?file_name=${file.name}`),
    {
      onSuccess: (res) => {
        setFormData({ ...formData, profile_picture: res.data.image_s3_path });
        uploadImageToPresignedURL(res.data.presigned_url);
      },
    }
  );
  const { mutate: enableUser } = useMutation(
    (email) =>
      api.get(
        `/admin/superadmin_enable_user?organization_id=${editData.organization_id}&user_email=${email}`
      ),
    {
      onSuccess: (res) => {
        setIsOpenUser("");
        enqueueSnackbar(res.data.message, {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        queryClient.invalidateQueries("allUsers");
      },
      onError: (error) => {
        setIsOpenUser("");
        enqueueSnackbar(error.response.data, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
    }
  );

  const { mutate: disableUser } = useMutation(
    (email) =>
      api.get(
        `/admin/superadmin_disable_user?user_email=${email}&organization_id=${editData.organization_id}`
      ),
    {
      onSuccess: (res) => {
        setIsOpenUser("");
        enqueueSnackbar(res.data.message, {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        queryClient.invalidateQueries("allUsers");
      },
      onError: (error) => {
        setIsOpenUser("");
        enqueueSnackbar(error.response.data.error || "Something went wrong!", {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
    }
  );

  const { mutate: deleteUser } = useMutation(
    (user_email) =>
      api.post(`/admin/superadmin_remove_user`, {
        user_email,
        organization_id: editData.organization_id,
      }),
    {
      onSuccess: (res) => {
        setIsOpenUser("");
        enqueueSnackbar("User deleted!", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        queryClient.invalidateQueries("allUsers");
      },
      onError: (error) => {
        setIsOpenUser("");
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
    }
  );

  const { mutate: updateUser } = useMutation(
    (formData) => api.post(`/admin/superadmin_update_user`, formData),
    {
      onSuccess: (res) => {
        setFormData({
          user_email: "",
          user_type: "",
          name: "",
        });
        setEditUser("");
        setImageSrc("");
        // setSelected("Select Role");
        enqueueSnackbar("User Updated!", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        queryClient.invalidateQueries("allUsers");
      },
      onError: (error) => {
        setIsOpenUser("");
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
    }
  );
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (loadEvent) => {
        setImageSrc(loadEvent.target.result);
      };
      reader.readAsDataURL(file);
    }
    setFile(file);
  };
  useEffect(() => {
    if (file !== "") {
      mutate(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);
  const { mutate: addUser } = useMutation(
    ["addUser"],
    (data) => api.post(`/admin/superadmin_add_user`, data),
    {
      onSuccess: () => {
        setFormData({
          user_email: "",
          user_type: "",
          name: "",
        });
        setImageSrc("");
        // setSelected("Select Role");
        enqueueSnackbar("User added successfully.", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        queryClient.invalidateQueries("allUsers");
      },
      onError: (error) => {
        enqueueSnackbar(error.response.data, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
    }
  );

  const onSubmit = (e) => {
    e.preventDefault();
    if (formData.user_type === "") {
      setError("Please select Role");
      return;
    }
    editUser
      ? updateUser({
          ...formData,
          organization_id: editData.organization_id,
          organization_name: editData.organization_name,
          organization_type: editData.organization_type,
        })
      : addUser({
          ...formData,
          organization_id: editData.organization_id,
          organization_name: editData.organization_name,
          organization_type: editData.organization_type,
        });
  };

  useEffect(() => {
    setError("");
  }, [formData]);

  const { data: user } = useQuery("user", () => api.get("/admin/get_user"), {
    enabled: true,
    select: (response) => response?.data?.user[0],
  });

  const uploadImageURL = async (s3_uri) => {
    try {
      const data = await api.get(
        `/admin/get_profile_img_link?s3_uri=${s3_uri}`
      );
      return data.data.presigned_url;
    } catch (error) {
      console.log(error);
    }
  };
  const updateProfilePictureURL = async (obj) => {
    try {
      const url = await uploadImageURL(obj.profile_picture);
      return url;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  // Iterate over each object in the array
  const updateProfilePictures = async (allUsers) => {
    const updatedDataArray = await Promise.all(
      allUsers?.map(async (obj) => {
        if (obj.profile_picture) {
          const imageUrl = await updateProfilePictureURL(obj);
          if (imageUrl) {
            return { ...obj, profile_picture: imageUrl };
          } else {
            return obj; // If unable to get URL, return the original object
          }
        } else {
          return obj; // If profile_picture is not defined, return the original object
        }
      })
    );

    setAlluserData(
      allUsers
        .map((item, idx) => {
          return {
            ...item,
            profile_picture: updatedDataArray[idx].profile_picture,
          };
        })
        .filter((item) => item?.user_id !== user?.user_id)
        .slice(0, 10)
    );
    setAllUserWithImage(
      allUsers
        .map((item, idx) => {
          return {
            ...item,
            profile_picture: updatedDataArray[idx].profile_picture,
          };
        })
        .filter((item) => item?.user_id !== user?.user_id)
    );
  };

  const { data: allUsers, refetch } = useQuery(
    "allUsers",
    () =>
      api.get(
        `/admin/superadmin_get_users?organization_id=${editData.organization_id}`
      ),
    {
      enabled: true,
      select: (response) => response.data.users,
      onSuccess: (allUsers) => {
        updateProfilePictures(allUsers);
      },
    }
  );

  return (
    <>
      <div className=" block md:flex w-full max-w-[670px] mt-3">
        <form
          className="w-full md:max-w-[348px] text-start"
          onSubmit={onSubmit}
        >
          {!editUser ? (
            <TextField
              ref={addUserRef}
              name="Email"
              label="Email"
              className={editUser ? "disable-input" : "primary"}
              type="email"
              xClass={
                editUser
                  ? "md:max-w-[348px] w-full mt-5 "
                  : "md:max-w-[348px] w-full mt-5"
              }
              required
              value={formData.user_email}
              onChange={(e) => {
                !editUser &&
                  setFormData({ ...formData, user_email: e.target.value });
              }}
            />
          ) : (
            <p className="mt-7 mb-6 primary-font">
              {" "}
              {formData?.user_email}{" "}
              <span
                className="role-tag"
                style={{ fontSize: "14px" }}
              >{`(${selected})`}</span>
            </p>
          )}

          <div className="relative flex items-center " ref={dropdownRef}>
            <TextField
              ref={inputRef}
              name="name"
              label="Name"
              className="primary"
              xClass="max-w-[348px] w-full mr-4"
              value={formData.name}
              onChange={(e) => {
                setFormData({ ...formData, name: e.target.value });
              }}
            />
            {/* <div className="relative">
              <button
                id="dropdownDefaultButton"
                data-dropdown-toggle="dropdown"
                className="h-[47px]  mt-3 px-5 py-2.5 text-center inline-flex items-center dropdown-btn-user text-nowrap"
                type="button"
                onClick={toggleDropdown}
                style={{
                  color: selected === "Select Role" ? "#858b97" : "#fff",
                }}
              >
                {selected}
                <svg
                  className={`w-2.5 h-2.5 ms-3 ${
                    isOpen ? "transform rotate-180" : ""
                  }`}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>

              {isOpen && (
                <div className="absolute mt-3 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-36 md:w-44 bg-1 -left-6 md:left-0">
                  <ul
                    className="py-2 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownDefaultButton"
                  >
                    <li
                      className="cursor-pointer links-color block px-4 py-2 "
                      onClick={() => handleOptionClick("Admin")}
                    >
                      Admin
                    </li>
                    <li
                      className=" cursor-pointer block px-4 py-2 links-color "
                      onClick={() => handleOptionClick("Viewer")}
                    >
                      Viewer
                    </li>
                  </ul>
                </div>
              )}
            </div> */}

            <DropdownPrimary
              data={["Viewer", "Admin"]}
              selected={selected}
              setSelected={setSelected}
              onSelect={(option) => {
                if (option !== "Select Role") {
                  setFormData({ ...formData, user_type: option.toLowerCase() });
                }
              }}
              btnClass="h-[47px]  mt-3 px-5 py-2.5 text-center inline-flex items-center dropdown-btn-user text-nowrap"
              paperClass="absolute mt-3 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-36 md:w-44 bg-1 -left-6 md:left-0"
            />
          </div>
          <div className="profile text-start md:text-center  mt-5 w-full md:w-5/12 block md:hidden ">
            <p className="profile-title primary-font mb-7 md:mb-5">
              Profile image
            </p>
            <input
              type="file"
              id="imageUpload"
              onChange={handleImageChange}
              accept="image/*"
              style={{ display: "none" }} // Hide the input
            />
            <div className="w-full flex md:justify-center relative rounded-full">
              {imageSrc && (
                <img
                  htmlFor="imageUpload"
                  src={imageSrc}
                  alt=""
                  className="profile-pic rounded-full  md:m-auto absolute"
                  onClick={() => document.getElementById("imageUpload").click()}
                />
              )}
              <label
                htmlFor="imageUpload"
                className="primary-font -mt-2 block justify-center text-center w-[150px] cursor-pointer pro-lable "
              >
                Click or Tap Here to Upload an Image
              </label>
            </div>
          </div>
          {error && <p className="error-msg md:pt-3 pt-10">{error}</p>}
          <div className="md:mt-5 flex align-middle items-center mt-7">
            <Button
              label={editUser ? "Update" : "Add User"}
              className="secondary"
            />
            {editUser && (
              <p
                className="ml-4 border-neutral-800 py-[10px] cancel-btn"
                onClick={() => {
                  setFormData({
                    user_email: "",
                    user_type: "",
                    name: "",
                  });
                  // setSelected("Select Role");
                  setEditUser("");
                  setImageSrc("");
                }}
              >
                Cancel
              </p>
            )}
          </div>
        </form>

        <div className="profile text-start md:text-center  mt-5 w-full md:w-5/12 hidden md:block">
          <p className="profile-title primary-font mb-7 md:mb-5">
            Profile image
          </p>
          <input
            type="file"
            id="imageUpload"
            onChange={handleImageChange}
            accept="image/*"
            style={{ display: "none" }} // Hide the input
          />
          <div className="w-full flex md:justify-center relative rounded-full">
            {imageSrc && (
              <img
                htmlFor="imageUpload"
                src={imageSrc}
                alt=""
                className="profile-pic rounded-full  md:m-auto absolute"
                onClick={() => document.getElementById("imageUpload").click()}
              />
            )}
            <label
              htmlFor="imageUpload"
              className="primary-font -mt-2 block justify-center text-center w-[150px] cursor-pointer pro-lable "
            >
              Click or Tap Here to Upload an Image
            </label>
          </div>
        </div>
      </div>
      <div className="divider w-full max-w-[545px] mt-14"></div>
      <div className=" w-full max-w-[560px]">
        <div className="users-listing mt-10"></div>
        <p className="secondary-font mb-7 ">Users</p>
        {allusersData.length > 0 &&
          allusersData
            ?.filter((item) => item?.user_id !== user?.user_id)
            .map((user, idx) => {
              return (
                <div className="flex items-center mb-5" key={idx}>
                  <div>
                    <img
                      className="w-[60px] h-[37px] md:w-[44px] md:h-[44px] rounded-full"
                      src={
                        user?.profile_picture?.includes(
                          "https://aquacontrol-blob"
                        )
                          ? user?.profile_picture
                          : avatarPlaceHolder
                      }
                      alt="Avatar"
                      // onError={avatarPlaceHolder}
                    />
                  </div>
                  <div className="w-full md:w-[300px] ml-4">
                    <p className="primary-font">
                      {user.user_name + " "}
                      <span className="role-tag">
                        {" "}
                        {`(${user.user_type})`}{" "}
                      </span>
                    </p>
                    <p
                      className="primary-font underline  overflow-ellipsis overflow-hidden max-w-full w-[150px] md:w-full"
                      style={{ fontSize: "11px" }}
                    >
                      {user.email}
                    </p>
                  </div>
                  <Button
                    label={
                      user.user_status === "active" ? "Enabled" : "Disabled"
                    }
                    className="tertiary"
                    xClass={
                      user.user_status !== "active"
                        ? "diabled-btn"
                        : "active-btn"
                    }
                    onClick={() => {
                      user.user_status === "active"
                        ? disableUser(user.email)
                        : enableUser(user.email);
                    }}
                  />
                  <div className="relative">
                    <i
                      className="fa fa-ellipsis-v more-dots"
                      aria-hidden="true"
                      onClick={() => {
                        toggleDropdownUser(user.user_id);
                      }}
                    ></i>

                    {isOpenUser === user.user_id && (
                      <div
                        className="absolute -left-[130px] md:-left-5 mt-2 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-[150px] md:w-44 bg-1"
                        ref={dropdownRefUser}
                      >
                        <ul
                          className="py-2 text-sm text-gray-700 dark:text-gray-200"
                          aria-labelledby="dropdownDefaultButton"
                        >
                          <li
                            className="cursor-pointer links-color block px-4 py-2 "
                            onClick={() => {
                              setFormData({
                                user_email: user.email,
                                user_type: user.user_type,
                                name: user.user_name,
                              });
                              setSelected(user.user_type);
                              handleAddBtnClick();
                              setEditUser(user);
                              setIsOpenUser("");
                              setImageSrc(user.profile_picture);
                            }}
                          >
                            Edit User
                          </li>
                          <li
                            className=" cursor-pointer block px-4 py-2 links-color "
                            onClick={() => deleteUser(user.email)}
                          >
                            Delete User
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        <div className="flex align-middle items-center	pb-3 w-full max-w-[560px]">
          <div className="flex align-middle items-center w-full md:w-[324px]">
            <img
              src={AddIcon}
              alt="icon"
              className="w-[44px] h-[44px] rounded-full cursor-pointer"
              onClick={newUserClick}
            />
            <p className="primary-font pl-[15px] " style={{ color: "#fff" }}>
              New User
            </p>
          </div>

          {allUserWithImage.length > 10 && (
            <Pagination
              items={allUserWithImage}
              itemsPerPage={10}
              setAlluserData={setAlluserData}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default AddUser;
