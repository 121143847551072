import React, { useState, useRef, useEffect } from "react";
import TextField from "../shared/TextField";
import Button from "../shared/Button";
import { useQueryClient } from "react-query";
import { useQuery, useMutation } from "react-query";
import api from "../util/api";
import { useSnackbar } from "notistack";
import ChangePassword from "./ChangePassword";
function AddUser() {
  const user_type = JSON.parse(localStorage.getItem("auth"))?.user_type;
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [imageSrc, setImageSrc] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState("Select Role");
  const [file, setFile] = useState("");
  const dropdownRef = useRef(null);
  const [error, setError] = useState("");
  const [editUser, setEditUser] = useState("");
  const [formData, setFormData] = useState({
    user_email: "",
    user_type: "",
    name: "",
    profile_picture: "",
  });
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // const toggleDropdown = () => {
  //   setIsOpen(!isOpen);
  // };

  const handleOptionClick = (option) => {
    setSelected(option);
    setIsOpen(false);
    if (option !== "Select Role") {
      setFormData({ ...formData, user_type: option.toLowerCase() });
    }
  };

  const uploadImageToPresignedURL = async (presignedURL) => {
    try {
      const response = await fetch(presignedURL, {
        method: "PUT",
        headers: {
          "Content-Type": file.type,
        },
        body: file,
      });

      if (response.ok) {
        console.log("Image uploaded successfully!");
      } else {
        console.error("Failed to upload image:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
    setFile("");
  };

  const { mutate } = useMutation(
    (file) =>
      api.get(
        `/${
          user_type === "admin" ? "admin" : "viewer"
        }/get_profile_pic_upload_link?file_name=${file.name}`
      ),
    {
      onSuccess: (res) => {
        setFormData({ ...formData, profile_picture: res.data.image_s3_path });
        uploadImageToPresignedURL(res.data.presigned_url);
      },
    }
  );

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (loadEvent) => {
        setImageSrc(loadEvent.target.result);
      };
      reader.readAsDataURL(file);
    }
    setFile(file);
  };
  useEffect(() => {
    if (file !== "") {
      mutate(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    setError("");
  }, [formData]);

  const { data: user } = useQuery(
    "user",
    () =>
      api.get(user_type === "admin" ? "/admin/get_user" : "/viewer/get_user"),
    {
      enabled: true,
      select: (response) => response?.data?.user[0],
      onSuccess: (user) => {
        if (user) {
          setFormData({
            user_email: user.email,
            user_type: user.user_type,
            name: user.user_name,
          });
          setSelected(user.user_type);
        }
      },
    }
  );

  const { mutate: updateUser } = useMutation(
    () =>
      api.post(
        user_type === "admin" ? `/admin/update_user` : `/viewer/update_user`,
        formData
      ),
    {
      onSuccess: (res) => {
        enqueueSnackbar("Updated successfully!", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        queryClient.invalidateQueries("user");
      },
      onError: (error) => {
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
    }
  );
  const onSubmit = (e) => {
    e.preventDefault();
    if (formData.user_type === "") {
      setError("Please select Role");
      return;
    }
    updateUser();
  };

  const uploadImageURL = async (s3_uri) => {
    try {
      const data = await api.get(
        `/${
          user_type === "admin" ? "admin" : "viewer"
        }/get_profile_img_link?s3_uri=${s3_uri}`
      );
      return data.data.presigned_url;
    } catch (error) {
      console.log(error);
    }
  };
  const updateProfilePictureURL = async (obj) => {
    try {
      const url = await uploadImageURL(obj.profile_picture);
      return url;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const updateProfilePictures = async () => {
    const imageUrl = await updateProfilePictureURL(user);
    setImageSrc(imageUrl);
  };
  useEffect(() => {
    if (user?.profile_picture) {
      updateProfilePictures();
    }
  }, [user]);
  return (
    <>
      <h1 className="heading" style={{ color: "#ebffff" }}>
        Update Profile
      </h1>
      <div className=" block md:flex w-full max-w-[670px]">
        <form
          className="w-full md:max-w-[348px] text-start"
          onSubmit={onSubmit}
        >
          {/* <TextField
            name="Email"
            label="Email"
            className={"disable-input"}
            type="email"
            xClass={
              editUser
                ? "md:max-w-[348px] w-full mt-5 "
                : "md:max-w-[348px] w-full mt-5"
            }
            required
            value={formData.user_email}
          /> */}
          <p className="mt-7 mb-6 primary-font">
            {" "}
            {formData?.user_email}{" "}
            <span
              className="role-tag"
              style={{ fontSize: "14px" }}
            >{`(${selected})`}</span>
          </p>

          <div className="relative flex items-center " ref={dropdownRef}>
            <TextField
              name="name"
              label="Name"
              className="primary"
              xClass="max-w-[348px] w-full mr-4"
              value={formData.name}
              onChange={(e) => {
                setFormData({ ...formData, name: e.target.value });
              }}
            />
            <div className="relative hidden">
              <button
                id="dropdownDefaultButton"
                data-dropdown-toggle="dropdown"
                className="h-[47px]  mt-3 px-5 py-2.5 text-center inline-flex items-center dropdown-btn-user text-nowrap cursor-not-allowed"
                type="button"
                // onClick={toggleDropdown}
                style={{
                  color: "#858b97",
                }}
              >
                {selected}
                {/* <svg
                  className={`w-2.5 h-2.5 ms-3 ${
                    isOpen ? "transform rotate-180" : ""
                  }`}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg> */}
              </button>

              {isOpen && (
                <div className="absolute mt-3 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 bg-1">
                  <ul
                    className="py-2 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownDefaultButton"
                  >
                    <li
                      className="cursor-pointer links-color block px-4 py-2 "
                      onClick={() => handleOptionClick("Admin")}
                    >
                      Admin
                    </li>
                    <li
                      className=" cursor-pointer block px-4 py-2 links-color "
                      onClick={() => handleOptionClick("Viewer")}
                    >
                      Viewer
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
          {error && <p className="error-msg pt-3">{error}</p>}
          <div className="mt-4 flex align-middle items-center">
            <Button label={"Update"} className="secondary" />
            {editUser && (
              <p
                className="ml-4 border-neutral-800 py-[10px] cancel-btn"
                onClick={() => {
                  setFormData({
                    user_email: "",
                    user_type: "",
                    name: "",
                  });
                  setSelected("Select Role");
                  setEditUser("");
                  setImageSrc("");
                }}
              >
                Cancel
              </p>
            )}
          </div>
        </form>
        {/* {imageSrc && (
          <div className="profile text-start md:text-center mt-5 w-full md:w-5/12">
            <p className="profile-title primary-font mb-5">Profile image</p>
            <img
              src={imageSrc}
              alt=""
              className="profile-pic rounded-full  md:m-auto"
            />
          </div>
        )} */}
        <div className="profile text-start md:text-center mt-5 w-full md:w-5/12">
          <p className="profile-title primary-font mb-5">Profile image</p>
          <input
            type="file"
            id="imageUpload"
            onChange={handleImageChange}
            accept="image/*"
            style={{ display: "none" }} // Hide the input
          />
          <div className="w-full flex md:justify-center relative rounded-full">
            {imageSrc && (
              <img
                htmlFor="imageUpload"
                src={imageSrc}
                alt=""
                className="profile-pic rounded-full  md:m-auto absolute"
                onClick={() => document.getElementById("imageUpload").click()}
              />
            )}
            <label
              htmlFor="imageUpload"
              className="primary-font -mt-2 block justify-center text-center w-[150px] cursor-pointer pro-lable "
            >
              Click or Tap Here to Upload an Image
            </label>
          </div>
        </div>
      </div>
      <div className="divider w-full max-w-[545px] mt-10"></div>
      <div className="w-full md:max-w-[348px] text-start mt-8">
        <ChangePassword />
      </div>
    </>
  );
}

export default AddUser;
