import React from "react";

export default function LeftArrow(props) {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.30465 7.54999L0.889648 4.44499V3.22998L6.30465 0.124985L7.02465 1.24998L1.63965 4.33999V3.33498L7.02465 6.42499L6.30465 7.54999Z" />
    </svg>
  );
}
