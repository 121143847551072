import React from "react";
import Listing from "./Listing";

function Organizations() {
  return (
    <div>
      <Listing />
    </div>
  );
}

export default Organizations;
