import React, { useState } from "react";
import Pagination from "../../shared/Pagination/Pagination";
import Dropdown from "../../shared/Dropdown/Dropdown";
import { generateClient } from "aws-amplify/api";
import {
  getSensorsPressureQuery,
  getPressureDiffQuery,
} from "../../util/query";
import { getFormattedDate } from "../../util/helper";
import ListSkeleton from "../../util/listSkeleton";
import { CSVLink } from "react-csv";

const TABLE_HEAD = [
  "Interval",
  "Sea Intake Pressure",
  "Pressure after Filter 1",
  "Pressure after Filter 2",
  "Filter 1",
  "Filter 2",
];
const TABLE_CELL_WIDTH = 100 / TABLE_HEAD.length;
const PAGE_SIZE = 6;

export default function PressureTable(props) {
  const { className = "", plantId } = props;

  const [selected, setSelected] = useState("Day");
  const [allKeysPresent, setAllKeysPresent] = useState(false);
  const [tableRows, setTableRows] = useState();
  const [totalPage, setTotalPage] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pressuresDiff, setPressuresDiff] = useState({});

  const TABLE_SELECT_FIELD = ["Day", "Week", "Month", "Year"];

  const client = generateClient();

  const [pressures, setPressures] = useState({});
  const sensorNames = ["x01", "x05", "x07"];

  const fetchSensorPressure = async (name) => {
    try {
      const query = getSensorsPressureQuery(
        selected.toLowerCase(),
        name,
        plantId
      );
      // Make the asynchronous GraphQL request
      const response = await client.graphql({ query });
      const pressure = response?.data?.getPressureDataForAllSensor || [];

      setPressures((prevPressures) => ({
        ...prevPressures,
        [name]: pressure,
      }));
    } catch (error) {
      console.error(`Error fetching pressure data for ${name}:`, error);
    }
  };
  const fetchPressureDiff = async (name) => {
    try {
      const query = getPressureDiffQuery(selected.toLowerCase(), name, plantId);
      // Make the asynchronous GraphQL request
      const response = await client.graphql({ query });
      const pressure =
        response?.data?.getPressureDifferenceWithPortx07Andx05Withx01 || 0;
      setPressuresDiff((prevPressures) => ({
        ...prevPressures,
        [name]: pressure,
      }));
    } catch (error) {
      console.error(`Error fetching pressure data for ${name}:`, error);
    }
  };
  React.useEffect(() => {
    const fetchData = () => {
      sensorNames.slice(0, 3).forEach((name) => {
        fetchSensorPressure(name);
      });
      sensorNames.slice(1).forEach((name) => {
        fetchPressureDiff(name);
      });
    };
    fetchData(); // Initial fetch
    const interval = setInterval(fetchData, 600000); // Set interval to 10 minutes
    return () => clearInterval(interval);
  }, [selected]);

  React.useEffect(() => {
    const hasAllKeys = (obj, keys) => {
      return keys.every((key) => obj.hasOwnProperty(key));
    };
    const allKeys = hasAllKeys(pressures, sensorNames);
    const diffKeys = hasAllKeys(pressuresDiff, ["x07", "x05"]);
    setAllKeysPresent(allKeys && diffKeys);
  }, [pressures, pressuresDiff]);
  const processPressureData = (pressureData) => {
    // Function to safely get a value or return a default
    const getValueOrDefault = (array, index, defaultValue = null) => {
      return array && array.length > index && array[index].Pressure_bar !== null
        ? Number(array[index].Pressure_bar).toFixed(2)
        : Number(array[index].Pressure_bar).toFixed(2);
    };

    // Function to filter out values less than or equal to zero
    const filterGreaterThanZero = (value) => {
      return value !== null && parseFloat(value) > 0 ? value : value;
    };

    // Build transformed data array
    const transformedData = pressureData.x01.map((item, index) => ({
      interval: getFormattedDate(item.time_interval, selected),
      x01: filterGreaterThanZero(getValueOrDefault(pressureData.x01, index)),
      x05: filterGreaterThanZero(getValueOrDefault(pressureData.x05, index)),
      x07: filterGreaterThanZero(getValueOrDefault(pressureData.x07, index)),
      x05Diff: isNaN(
        Number(pressuresDiff?.x05[index]?.pressure_bar_difference).toFixed(2)
      )
        ? 0
        : Number(pressuresDiff?.x05[index]?.pressure_bar_difference).toFixed(2),
      x07Diff: isNaN(
        Number(pressuresDiff?.x07[index]?.pressure_bar_difference).toFixed(2)
      )
        ? 0
        : Number(pressuresDiff?.x07[index]?.pressure_bar_difference).toFixed(2),
    }));
    return transformedData;
  };

  React.useEffect(() => {
    if (allKeysPresent) {
      const processedData = processPressureData(pressures);
      setTableRows(processedData);
    }
  }, [allKeysPresent]);

  React.useEffect(() => {
    tableRows && setTotalPage(Math.ceil(tableRows.length / PAGE_SIZE));
    tableRows && setCurrentPage(1);
  }, [tableRows]);
  // startIndex and endIndex for table items
  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex =
    tableRows && Math.min(startIndex + PAGE_SIZE, tableRows.length);
  const displayedItems = tableRows && tableRows.slice(startIndex, endIndex);
  return (
    <div className={`table-container ${className}`}>
      <div className="table-container-row">
        <div className="table-container-select-field">
          <Dropdown
            data={TABLE_SELECT_FIELD}
            setSelected={(singleField) => {
              setSelected(singleField);
              if (singleField !== selected) {
                setPressures({});
                setTableRows();
              }
            }}
            selected={selected}
          />
        </div>
        <Pagination
          currentPage={currentPage}
          totalPage={totalPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
      {/* Table Start */}
      <div className="w-full overflow-scroll">
        {tableRows ? (
          <table className="w-full table-auto">
            <thead>
              <tr>
                {TABLE_HEAD.map((head, index) => (
                  <th key={index} className="border-b p-4">
                    <div className="table-heading">{head}</div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {displayedItems.map(
                ({ interval, x01, x05, x07, x07Diff, x05Diff }, index) => {
                  return (
                    <tr
                      key={index}
                      style={{
                        width: `${TABLE_CELL_WIDTH}%`,
                        borderBottom:
                          index !== displayedItems.length - 1 &&
                          "0.25px solid #545D6E",
                      }}
                    >
                      <td
                        className="dark-bg"
                        style={{ width: `${TABLE_CELL_WIDTH + 8}%` }}
                      >
                        <span> {interval}</span>
                      </td>
                      <td
                        className="light-bg"
                        style={{ width: `${TABLE_CELL_WIDTH}%` }}
                      >
                        <span> {x01}</span>
                      </td>
                      <td
                        className="dark-bg"
                        style={{ width: `${TABLE_CELL_WIDTH}%` }}
                      >
                        <span> {x05}</span>
                      </td>
                      <td
                        className="light-bg"
                        style={{ width: `${TABLE_CELL_WIDTH}%` }}
                      >
                        <span> {x07}</span>
                      </td>
                      <td
                        className="dark-bg"
                        style={{ width: `${TABLE_CELL_WIDTH}%` }}
                      >
                        <span> {x05Diff}</span>
                      </td>
                      <td
                        className="light-bg"
                        style={{ width: `${TABLE_CELL_WIDTH}%` }}
                      >
                        <span> {x07Diff}</span>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        ) : (
          <ListSkeleton breakpoint="lg" />
        )}
      </div>
      {/* Table End */}
      <div className="download-button">
        {tableRows && (
          <CSVLink
            data={tableRows}
            headers={[
              { label: "Interval", key: "interval" },
              { label: "Sea Intake Pressure", key: "x01" },
              { label: "Pressure after Filter 1", key: "x05" },
              { label: "Pressure after Filter 2", key: "x07" },
              { label: "Filter 1", key: "x05Diff" },
              { label: "Filter 2", key: "x07Diff" },
            ]}
            filename={`${"Pressure-table"}.csv`}
          >
            <span className="underline">Download</span>{" "}
          </CSVLink>
        )}
      </div>
    </div>
  );
}
