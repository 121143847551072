import React, { useState } from "react";
import TextField from "../shared/TextField";
import Button from "../shared/Button";
import { useSnackbar } from "notistack";
import "./Styles.css";
import { confirmUserAttribute } from "aws-amplify/auth";
import { fetchAuthSession } from "aws-amplify/auth";
import { sendUserAttributeVerificationCode } from "aws-amplify/auth";

const PasswordReset = ({ onLogin }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [code, setCode] = useState("");
  const [error, setError] = useState("");

  async function currentSession() {
    try {
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      localStorage.setItem(
        "auth",
        JSON.stringify({
          isSignedIn: true,
          token: idToken.toString(),
          user_type: idToken.payload["custom:user_type"],
          organization_type: idToken.payload["custom:organization_type"],
          organization_id: idToken.payload["custom:organization_id"],
        })
      );
    } catch (err) {
      console.log(err);
    }
  }
  async function handleSendUserAttributeVerificationCode() {
    const key = "email";
    try {
      await sendUserAttributeVerificationCode({
        userAttributeKey: key,
      });
      enqueueSnackbar("Code Sent Successfully.", {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
    } catch (error) {
      console.log(error);
    }
  }
  async function handleConfirmUserAttribute(e) {
    e.preventDefault();

    const userAttributeKey = "email";
    const confirmationCode = code;
    try {
      await confirmUserAttribute({
        userAttributeKey,
        confirmationCode,
      });
      await currentSession();

      onLogin();
      enqueueSnackbar("Logged in successfully.", {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
  }

  return (
    <div className="w-full">
      <p className="heading mb-4">Enter Verification Code Sent To Your Email</p>
      <form className="mb-4 w-full" onSubmit={handleConfirmUserAttribute}>
        <TextField
          name="code"
          label="Verification code"
          type="text"
          className="secondary"
          pattern=".{6,}"
          required
          onChange={(e) => {
            setError("");
            setCode(e.target.value.trim());
          }}
        />
        {error && (
          <div className="flex">
            {" "}
            <p className="error-msg pr-4">{error}</p>{" "}
            <button
              onClick={handleSendUserAttributeVerificationCode}
              type="button"
              class="focus:outline-none text-white border-white border-2 	rounded-[7px] px-1 "
            >
              Resend
            </button>
          </div>
        )}
        <Button label="Submit" className="primary" type="submit" />
      </form>
    </div>
  );
};

export default PasswordReset;
