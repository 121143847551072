import React from "react";
import SpeedoMeterFilter from "../shared/SpeedoMeterFilter/SpeedoMeterFilter";
import TankLevel from "../shared/TankLevel/TankLevel";
import Progressbar from "../shared/ProgressBar/ProgressBar";
import Table from "../shared/Table/Table";

export default function index() {
  return (
    <div
      style={{ minHeight: "100vh", maxHeight: "100%", paddingBottom: "30px" }}
    >
      <div className="grid grid-cols-3 gap-5">
        <TankLevel />
        <TankLevel isSmall />
        <div className="grid gap-5">
          <Progressbar />
          <Progressbar title="Prod/day" progressValue={17} />
        </div>
        <SpeedoMeterFilter />
      </div>
      <div className="grid grid-cols-1 mt-9">
        <Table />
      </div>
    </div>
  );
}
