import React from "react";
import "./MonitorIndicator.css";

export default function index(props) {
  const { text, type = "primary", notFullWidth = false } = props;
  return (
    <div
      className={`monitor-indicator-container ${type} ${
        notFullWidth && "not-full-width"
      }`}
    >
      <div className={`monitor-indicator-circle ${type}`} />
      <div className="monitor-indicator-text capitalize">{text}</div>
    </div>
  );
}
