import React from "react";

export default function Pressure(props) {
  return (
    <svg
      width="29"
      height="36"
      viewBox="0 0 29 36"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.69017 13.93C1.69017 7.08997 7.04016 1.47998 13.8702 1.15998C14.1502 1.14998 14.3701 0.909982 14.3601 0.629982C14.3501 0.349982 14.1102 0.129976 13.8302 0.139976C10.2802 0.309976 6.97017 1.80998 4.51017 4.38998C-0.519827 9.63998 -0.579866 17.83 4.22013 23.17C4.32013 23.28 4.46014 23.34 4.60014 23.34C4.72014 23.34 4.84017 23.3 4.94017 23.21C5.15017 23.02 5.17014 22.7 4.98014 22.49C2.87014 20.14 1.70018 17.1 1.70018 13.94L1.69017 13.93Z"
        fill="currentColor"
      />
      <path
        d="M24.7201 4.65999C22.4301 2.13999 19.3201 0.54999 15.9501 0.18999C15.6701 0.15999 15.4201 0.359986 15.3901 0.639986C15.3601 0.919986 15.5601 1.16998 15.8401 1.19998C22.3501 1.88998 27.2701 7.35 27.2701 13.92C27.2701 20.97 21.5301 26.71 14.4801 26.71C11.5401 26.71 8.6701 25.69 6.3901 23.83C6.1701 23.65 5.85007 23.68 5.67007 23.9C5.49007 24.12 5.52008 24.44 5.74008 24.62C7.05008 25.69 8.5501 26.51 10.1401 27.03V29.71C10.1401 30.37 10.6801 30.9 11.3301 30.9H11.8101V34.17C11.8101 34.64 12.1901 35.02 12.6601 35.02H16.2701C16.7401 35.02 17.1201 34.64 17.1201 34.17V30.9H17.6001C18.2601 30.9 18.7901 30.36 18.7901 29.71V27.03C24.2901 25.21 28.2701 20.02 28.2701 13.92C28.2701 10.49 27.0001 7.19998 24.7001 4.64998L24.7201 4.65999ZM16.1201 34.01H12.8501V30.91H16.1201V34.01ZM17.7901 29.72C17.7901 29.81 17.7101 29.89 17.6201 29.89H11.3501C11.2601 29.89 11.1801 29.81 11.1801 29.72V27.34C12.2601 27.61 13.3701 27.74 14.4901 27.74C15.6101 27.74 16.7401 27.6 17.8001 27.34V29.72H17.7901Z"
        fill="currentColor"
      />
      <path
        d="M24.1202 9.72996C24.3702 9.59996 24.4802 9.29996 24.3502 9.03996C23.4502 7.22996 22.0702 5.69996 20.3602 4.61996C13.0802 0.00995755 3.47021 5.24996 3.47021 13.93C3.47021 20 8.41022 24.94 14.4802 24.94C21.6902 24.94 27.0202 18.06 25.1102 11.04C25.0402 10.77 24.7502 10.61 24.4802 10.68C24.2102 10.75 24.0502 11.03 24.1202 11.31C25.8502 17.68 21.0202 23.91 14.4802 23.91C8.97022 23.91 4.49023 19.43 4.49023 13.92C4.49023 8.40998 8.97022 3.92996 14.4802 3.92996C18.3002 3.92996 21.7302 6.05997 23.4302 9.48997C23.5602 9.73997 23.8602 9.84996 24.1202 9.71996V9.72996Z"
        fill="currentColor"
      />
      <path
        d="M20.3204 19.25C20.4704 19.25 20.6104 19.19 20.7104 19.06C25.0204 13.83 21.3103 5.83997 14.4703 5.83997C7.63034 5.83997 3.91035 13.82 8.23035 19.06C8.41035 19.28 8.73038 19.31 8.95038 19.13C9.17038 18.95 9.20039 18.63 9.02039 18.41C8.09039 17.28 7.54036 15.89 7.43036 14.44H9.11036C9.39036 14.44 9.62037 14.21 9.62037 13.93C9.62037 13.65 9.39036 13.42 9.11036 13.42H7.42035C7.53035 11.86 8.16037 10.44 9.12037 9.31998C10.3904 10.59 10.3904 10.66 10.6704 10.66C11.1204 10.66 11.3504 10.11 11.0304 9.78996L9.8404 8.59998C10.9604 7.62998 12.3804 7.00996 13.9404 6.89996V8.58997C13.9404 8.86997 14.1704 9.09998 14.4504 9.09998C14.7304 9.09998 14.9604 8.86997 14.9604 8.58997V6.89996C16.5204 7.00996 17.9404 7.63998 19.0604 8.59998L17.8704 9.78996C17.6704 9.98996 17.6704 10.31 17.8704 10.51C17.9704 10.61 18.1004 10.66 18.2304 10.66C18.3604 10.66 18.4904 10.61 18.5904 10.51L19.7804 9.31998C20.7504 10.44 21.3704 11.86 21.4804 13.42H19.7903C19.5104 13.42 19.2804 13.65 19.2804 13.93C19.2804 14.21 19.5104 14.44 19.7903 14.44H21.4703C21.3603 15.89 20.8104 17.28 19.8804 18.41C19.6004 18.74 19.8504 19.25 20.2704 19.25H20.3204Z"
        fill="currentColor"
      />
      <path
        d="M14.4802 16.1C14.8802 16.1 15.2502 15.99 15.5702 15.81L18.1302 18.37C18.2302 18.47 18.3602 18.52 18.4902 18.52C18.6202 18.52 18.7502 18.47 18.8502 18.37C19.0502 18.17 19.0502 17.85 18.8502 17.65L16.3102 15.11C16.5302 14.77 16.6502 14.37 16.6502 13.94C16.6502 12.74 15.6802 11.77 14.4802 11.77C14.0502 11.77 13.6502 11.9 13.3102 12.11L12.3602 11.16C12.1602 10.96 11.8402 10.96 11.6402 11.16C11.4402 11.36 11.4402 11.68 11.6402 11.88L12.6002 12.84C12.4102 13.16 12.3102 13.53 12.3102 13.93C12.3102 15.13 13.2802 16.1 14.4802 16.1ZM14.4802 12.78C15.1102 12.78 15.6302 13.29 15.6302 13.93C15.6302 14.57 15.1202 15.08 14.4802 15.08C13.8402 15.08 13.3302 14.57 13.3302 13.93C13.3302 13.29 13.8402 12.78 14.4802 12.78Z"
        fill="currentColor"
      />
    </svg>
  );
}
