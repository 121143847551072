import React from "react";
import "./StatusTag.css";

export default function StatusTag(props) {
  const { text = "Timraön", type = "primary" } = props;
  return (
    <div className={`status-tag-container ${type}`}>
      <div className={`status-tag-circle ${type}`} />
      <div className="status-tag-text">{text}</div>
    </div>
  );
}
