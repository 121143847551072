import React from "react";
import "./Pagination.css";
import LeftArrow from "../../../assests/icons/LeftArrow";
import RightArrow from "../../../assests/icons/RightArrow";

export default function Pagination(props) {
  const { currentPage = 1, totalPage = "...", setCurrentPage } = props;
  return (
    <div className="pagination-container">
      <LeftArrow
        className={currentPage === 1 ? "disabled" : ""}
        onClick={() => setCurrentPage(currentPage - 1)}
      />
      <div className="page-number-container">
        <span>{currentPage}</span>
        <span>|</span>
        <span>{totalPage}</span>
      </div>

      <RightArrow
        className={currentPage === totalPage ? "disabled" : ""}
        onClick={() => setCurrentPage(currentPage + 1)}
      />
    </div>
  );
}
