/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import BellIcon from "../../assests/bellIcon.svg";
import Arrow from "../../assests/arrowBack.svg";
import { signOut } from "aws-amplify/auth";
import { useQuery } from "react-query";
import api from "../util/api";
import "./Styles.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  const orgName = location?.state?.orgName;
  const organization_type = JSON.parse(
    localStorage.getItem("auth")
  )?.organization_type;
  const user_type = JSON.parse(localStorage.getItem("auth"))?.user_type;
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleSignOut() {
    try {
      await signOut();
      localStorage.removeItem("auth");
      localStorage.removeItem("plantId");
      localStorage.removeItem("hasNavigated");
      window.history.pushState({}, "", "/");
      window.location.reload();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  const { data: user } = useQuery(
    "user",
    () =>
      api.get(user_type === "admin" ? "/admin/get_user" : "/viewer/get_user"),
    {
      enabled: true,
      select: (response) => response?.data?.user[0],
    }
  );

  const uploadImageURL = async (s3_uri) => {
    try {
      const data = await api.get(
        `/${
          user_type === "admin" ? "admin" : "viewer"
        }/get_profile_img_link?s3_uri=${s3_uri}`
      );
      return data.data.presigned_url;
    } catch (error) {
      console.log(error);
    }
  };
  const updateProfilePictureURL = async (obj) => {
    try {
      const url = await uploadImageURL(obj.profile_picture);
      return url;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const updateProfilePictures = async () => {
    const imageUrl = await updateProfilePictureURL(user);
    setImageSrc(imageUrl);
  };
  useEffect(() => {
    if (user?.profile_picture) {
      updateProfilePictures();
    }
  }, [user]);

  return (
    <div className=" nav-wrapper bg-1 text-white p-[14px] flex items-center justify-between ">
      <img
        onClick={() => {
          window.history.back();
        }}
        src={Arrow}
        alt="img"
        className="arrow-back"
      />
      {location.pathname.includes("more-info") ? (
        <div className=" capitalize flex cursor-pointer relative">
          <p className="heading capitalize pl-5 pr-5 pt-1 ">{orgName || ""}</p>
          <div className=" flex more-info-nav bg-2    left-[112px] py-1  px-4 -top-[9px] rounded-md">
            <Link
              to="/more-info-dashboard"
              state={{ orgName: orgName || "" }}
              onClick={() => {
                localStorage.removeItem("reportActiveTab");
              }}
            >
              {" "}
              <p
                className={
                  location.pathname.includes("dashboard")
                    ? "border-b-[1px] pb-1 info-link"
                    : "dashboard info-link"
                }
              >
                Dashboard
              </p>
            </Link>
            <Link to="/more-info-report" state={{ orgName: orgName || "" }}>
              {" "}
              <p
                className={
                  location.pathname.includes("report")
                    ? "border-b-[1px] pb-1 ml-5 info-link"
                    : "ml-5 info-link"
                }
              >
                Report
              </p>
            </Link>
          </div>
        </div>
      ) : (
        <div className="heading pl-7 capitalize">
          {location.pathname === "/" && organization_type === "contractor"
            ? "Monitor"
            : location.pathname === "/" && organization_type === "super_admin"
            ? "Organizations"
            : location.pathname === "/"
            ? "Dashboard"
            : location.pathname.replace("/", "").replace(/-/g, " ")}
        </div>
      )}

      <div className="flex items-center space-x-4 ml-auto">
        {" "}
        {/* lg:block */}
        <div className="relative text-gray-600 hidden  ">
          <button
            type="submit"
            className="absolute left-5 -top-[1.5px] mt-3 mr-4"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.01965 7.16997C2.01965 4.40997 4.25965 2.16997 7.01965 2.16997C9.77965 2.16997 12.0197 4.40997 12.0197 7.16997C12.0197 9.92997 9.77965 12.17 7.01965 12.17C4.25965 12.17 2.01965 9.92997 2.01965 7.16997ZM15.1096 14.08L12.2797 11.25C13.1597 10.12 13.6896 8.70997 13.6896 7.16997C13.6896 3.48997 10.6997 0.499969 7.01965 0.499969C3.33965 0.499969 0.349609 3.48997 0.349609 7.16997C0.349609 10.85 3.33965 13.84 7.01965 13.84C8.55965 13.84 9.96961 13.31 11.0996 12.43L13.9296 15.26C14.0896 15.42 14.3097 15.5 14.5197 15.5C14.7297 15.5 14.9496 15.42 15.1096 15.26C15.4396 14.93 15.4396 14.41 15.1096 14.08Z"
                fill="#8F8C98"
              />
            </svg>
          </button>
          <input
            type="search"
            name="serch"
            placeholder="Search"
            className="bg-white h-10 px-5 pr-6 rounded-full text-sm focus:outline-none pl-11  search-input "
          />
        </div>
      </div>

      {/* Admin Info and Dropdown */}
      <div className="flex items-center space-x-2 pl-10">
        <div className="notifications-box relative">
          <div className="w-[20px] h-[20px] not-count">
            <p className="p-[2px]">3</p>
          </div>
          <img src={BellIcon} alt="Dropdown Icon" className="w-[15px]" />
        </div>
        <div className="relative group pl-4">
          <img
            className="w-10 h-10 rounded-full"
            src={
              imageSrc ||
              "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAclBMVEX///8AAADx8fEJCQn39/fw8PD8/PzZ2dn09PTi4uKWlpZwcHDQ0NB9fX1jY2Ps7OxJSUm3t7cqKiqlpaVoaGiFhYW/v79DQ0MXFxd5eXktLS1dXV0lJSXIyMigoKA4ODgdHR1VVVWPj4+tra1PT0+Tk5Na8LRmAAAEkElEQVR4nO3d63aqMBAFYCPIpaiI1vut2vb9X/GUti57LJjLkExi9/e/a2UvQJLJkPZ6AAAAAAAAAAAAAAAAAAAAAAAAAH9VEmf9LE64h2FHklaj8XK6ilbT5XhUpY8Wc1HsxP92xYJ7UN0pz6LZueQeWifKdUu+2voBMj7dyVd74h4gUbqRBBRik3IPkmImzVc7cA/TWFIoBRSiCPTVkbwpBhTiLciI8VE5oBDHmHu4+uKJRkAhluFF3GoFFGLLPWBdI82AQsy5h6yn0g4oRMU9aB25QUAhQprB6T6EXwJ6FE3u0Vow92liGFCIUF78suVEu0AWGrFxQCHCeO8fCAmDWGYkU0LCaQhP4oIQUIgQylPPpIQF9/DlMlJAITLuAFK0mzSE21R/UfG/EXcAKWJAEXEHkBlQE4oBdwQJ6mPo/4OoViG9Z8YdQYL2Nqz5/kZckhMuuSNIUCalX6bcESReyAlfuCPcF0fkhCu/14h9esLI75lpFwn73CHuyjpI6Pdd+vi/NL3bphJ9O+4IEjqbhs2O3BEkxuSEY+4IEubV4Avfq8KmWxZXvm9emO2r/ZRzR5AhJ+QOIEVdID5zB5B6JSZ85Q4gVRITBrDVfSIFPHEPXwHtNvX/Ju31BpTlReR7tfQTpa4fRtsQ5aUfwO9MTbWt9Dffa6UX5i+MQC6h+QLD92XFVSLvX29yDKFN4dveKOGQe9g62r6SuefMPWg96l3sF2/cQ9aU6Rbddn6Xuhvovve9X9r/phcxwIB6EYMM+DG3Ud0unQYzl7mVqNWHx57vxdyl0prhe/OFRC5r298G+gj+sL/3Zlz53h+kZtH2kdfyMfLV0vnvXbfjPOjPY39J8tn62k20XM/ygJZK6pI4T4dp/qjHRgCAU3m1MKjOl2Uo89P8syKsWxncf0wLNk8hLPSHl4noRqd0Vn6vQla+Z0wWPyulJ9VJy2D+46/WHk91ssPtkvdZZbT5/OavTnvrQzUyOK8aJteTSjKB2Tetko8ebpT2by/EVdF+SYbztu3UlWfr4oGkxr2dpf2ba9lPZ5LGlJk/L49Yaa9pWYwO1X6YDvfVYVSonAuye/Vkiv5K75pts/GhyW1oL1/tyP3uKM13tFWtWdszqC1eSiK+W7U8uQj4Ycv0gQK9WVYdR1EuoX7wq8d9K9FA76ArujfHd2pK/zZGV+S0/D90nq/msFfDrJmEztmqiucK1hxdRfoHB+acPIvafSRd2rlYUdG/R6dw8LGCy5lME+uTVOrxM3S2y43t5RhXLM/f+C+h7YtI/7yQzu6JZ2bNv93a2AyYcqf7ZLN0Qz99pgs2i8X2604qbH6Vod/bbIPFfumYfvpMF6b2JqdZ0+6Seyt7b8QOTi7pgsXTT5DQESREQiTkZzFhZnc3VJXNT6Rcb1Y0m9gL+Adm3tyFti82y22k8xK6YvfcBf5Sm+1iG+W/AnTFcmGffk4wlfUt/XfmgO+2A3JHdBCQ90Z11HUSt3aF2hXN3fVjDqpisotc2k2KynWDW9Z3yfMWfgAAAAAAAAAAAAAAAAAAAAAAAABF/wCB50Scz71fkQAAAABJRU5ErkJggg=="
            }
            alt="Rounded avatar"
          />{" "}
        </div>
        <div className="text-start pl-2">
          <h2 className="info  capitalize">{user?.user_type}</h2>
          <p className="name">
            {user?.user_name ? user?.user_name : user?.email}
          </p>
        </div>
        <div className="relative inline-block text-left" ref={dropdownRef}>
          <div>
            <button
              type="button"
              onClick={toggleDropdown}
              className="inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-gray-900"
              id="menu-button"
              aria-expanded={isDropdownOpen}
              aria-haspopup="true"
            >
              <svg
                className={`${isDropdownOpen ? "transform rotate-180" : ""}`}
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="7"
                viewBox="0 0 10 7"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.00002 6.50001C4.74002 6.50001 4.49006 6.40001 4.29006 6.21001L0.290059 2.21001C-0.0999414 1.82001 -0.0999414 1.19001 0.290059 0.800007C0.680059 0.410007 1.31998 0.410007 1.70998 0.800007L5.01003 4.11001L8.31008 0.930008C8.70008 0.550008 9.33999 0.560007 9.71999 0.960007C10.1 1.36001 10.09 1.99001 9.69997 2.37001L5.69997 6.23001C5.49997 6.42001 5.25002 6.51001 5.00002 6.51001V6.50001Z"
                  fill="#BCBAC1"
                />
              </svg>
            </button>
          </div>

          {isDropdownOpen && (
            <divs
              className="absolute right-6 z-10 mt-7 pb-1 w-[190px] origin-top-right rounded-md bg-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none "
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabIndex="-1"
              onBlur={closeDropdown}
            >
              <div className="py-1" role="none">
                {/* <Link
                  to="/settings"
                  className="block px-4 py-3  primary-font links-color"
                  role="menuitem"
                  tabIndex="-1"
                  id="menu-item-0"
                  onClick={() => {
                    closeDropdown();
                  }}
                >
                  Profile
                </Link> */}
                <Link
                  to="/profile"
                  className="block px-4 py-3  primary-font links-color"
                  role="menuitem"
                  tabIndex="-1"
                  id="menu-item-0"
                  onClick={() => {
                    closeDropdown();
                  }}
                >
                  Profile
                </Link>
                {user_type?.toLowerCase() === "admin" && (
                  <Link
                    to="/settings"
                    className="block px-4 py-3  primary-font links-color"
                    role="menuitem"
                    tabIndex="-1"
                    id="menu-item-0"
                    onClick={() => {
                      closeDropdown();
                    }}
                  >
                    Settings
                  </Link>
                )}
                <li
                  className=" block px-4 py-3 primary-font links-color cursor-pointer"
                  role="menuitem"
                  tabIndex="-1"
                  id="menu-item-1"
                  onClick={() => {
                    handleSignOut();
                    closeDropdown();
                  }}
                >
                  Log out
                </li>
              </div>
            </divs>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
