import React, { useState } from "react";
import CardWrapper from "../../shared/CardWrapper/CardWrapper";
import MachineTable from "./MachineTable";
import ReportFlowTag from "../../shared/ReportFlowTag/ReportFlowTag";
import MachineCurrentToggle from "./MachineCurrentToggle";
import AreaChart from "./AreaChart";
import { generateClient } from "aws-amplify/api";
import { getMachineOperationDataQuery } from "../../util/query";
import { getPlantId } from "../../util/helper";
export default function Machine() {
  const plantId = getPlantId();
  const client = generateClient();
  const [isChecked, setIsChecked] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("Day");
  const [operatingHours, setOperatingHours] = useState();

  async function getMachineOperationData() {
    try {
      const query = getMachineOperationDataQuery(plantId);
      // Make the asynchronous GraphQL request
      const flowStatus = await client.graphql({ query });
      setIsChecked(flowStatus.data.getMachineOperationData.Flow_Lpmin_status);
    } catch (error) {
      console.error("Error fetching flow in production data:", error);
    }
  }
  const getLabel = () => {
    return selectedFilter === "Day"
      ? "Recent 24 hours"
      : selectedFilter === "Month"
      ? "Current month"
      : selectedFilter === "Year"
      ? "Current year"
      : "Recent 7 days";
  };
  React.useEffect(() => {
    const fetchData = () => {
      getMachineOperationData();
    };
    fetchData(); // Initial fetch
    const interval = setInterval(fetchData, 600000); // Set interval to 10 minutes
    return () => clearInterval(interval);
  }, []);

  const stringToBoolean = (value) => {
    if (typeof value === "boolean") {
      return value;
    }
    return value.toLowerCase() === "true";
  };
  return (
    <div className="machine-page">
      <div className="grid grid-cols-1 xl:grid-cols-3 gap-5">
        <div className="xl:col-span-2 text-white">
          <AreaChart
            setOperatingHours={setOperatingHours}
            setSelectedFilter={(val) => setSelectedFilter(val)}
            plantId={plantId}
          />
        </div>
        <CardWrapper className="flow-page-stats flow-page-stats-flow">
          <MachineCurrentToggle
            className="border-bottom"
            title={"Current machine status"}
            isChecked={stringToBoolean(isChecked)}
            handler={() => setIsChecked(isChecked)}
          />

          <ReportFlowTag
            title={getLabel(selectedFilter)}
            number={operatingHours?.TotalOperatingTime_hours || 0}
            txtSm={true}
          />
        </CardWrapper>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 2xl-grid-cols-3 my-5">
        <div className="col-span-3 xl:col-span-2 2xl:col-span-1">
          <MachineTable plantId={plantId} />
        </div>
      </div>
    </div>
  );
}
