import React, { useState } from "react";

const Pagination = ({ items, itemsPerPage = 2, setAlluserData }) => {
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate total number of pages
  const totalPages = Math.ceil(items?.length / itemsPerPage);

  // Change page
  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, items.length); // Adjust endIndex
    const itemsToShow = items.slice(startIndex, endIndex);
    setAlluserData(itemsToShow); // Update user data after calculating new slice
  };

  return (
    <div>
      {/* Pagination Controls */}
      <div class="flex   items-center">
        <button
          onClick={() => goToPage(currentPage - 1)}
          disabled={currentPage <= 1}
          class="flex items-center justify-center px-4 h-8 text-base font-medium text-white page-btn-bg rounded-s hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        >
          Prev
        </button>
        <button
          onClick={() => goToPage(currentPage + 1)}
          disabled={currentPage >= totalPages}
          style={{ borderLeft: "none" }}
          class="flex items-center justify-center px-4 h-8 text-base font-medium text-white page-btn-bg border-0 border-s border-gray-700 rounded-e hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white "
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Pagination;
