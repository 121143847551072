import React, { useState } from "react";
import TextField from "../shared/TextField";
import Button from "../shared/Button";
import { confirmSignIn } from "aws-amplify/auth";
import "./Styles.css";
import { sendUserAttributeVerificationCode } from "aws-amplify/auth";
import VerifyEmail from "./VerifyEmail";
const VerifyUser = ({ onLogin }) => {
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [showVerify, setShowVerify] = useState(false);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!newPassword || !confirmPassword) return;
    if (newPassword !== confirmPassword) {
      setError("Passwords does not match");
      return;
    }
    try {
      const { isSignedIn } = await confirmSignIn({
        challengeResponse: newPassword,
      });

      if (isSignedIn) {
        async function handleSendUserAttributeVerificationCode(key) {
          try {
            const data = await sendUserAttributeVerificationCode({
              userAttributeKey: key,
            });
            if (data) {
              setShowVerify(true);
            }
          } catch (error) {
            console.log(error);
          }
        }
        handleSendUserAttributeVerificationCode("email");
      }
    } catch (error) {
      console.log(error);
      setError(error.message || "An error occurred.");
    }
  };

  return (
    <div className="w-full">
      {!showVerify && (
        <>
          <h1 className="heading mb-4" style={{ color: "#ebffff" }}>
            Reset your password
          </h1>
          <form className="mb-4 w-full" onSubmit={handleResetPassword}>
            <TextField
              name="code"
              label="New Password"
              type="password"
              className="secondary"
              required
              onChange={(e) => {
                setError("");
                setNewPassword(e.target.value.trim());
              }}
            />
            <TextField
              name="ConfirmPassword"
              label="Confirm Password"
              type="password"
              className="secondary"
              required
              onChange={(e) => {
                setConfirmPassword(e.target.value.trim());
                setError("");
              }}
            />
            {error && <p className="error-msg">{error}</p>}
            <Button label="Submit" className="primary" type="submit" />
          </form>
        </>
      )}

      {showVerify && <VerifyEmail onLogin={onLogin} />}
    </div>
  );
};

export default VerifyUser;
