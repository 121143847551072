import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const WaterLevelChart = ({ barData, selected, refinedLabel }) => {
  const [label] = useState(refinedLabel);

  const [data] = useState(barData);

  const visibleLabel = label;
  const visibleData = data;

  const calculateOpacity = (value) => {
    if (value < 20) {
      return 0.5;
    } else if (value < 50) {
      return 0.5;
    } else {
      return 1;
    }
  };
  const transposeData = (data) => {
    const transposed = [];
    data.forEach((arr) => {
      arr.forEach((value, index) => {
        if (!transposed[index]) {
          transposed[index] = [];
        }
        transposed[index].push(value);
      });
    });
    return transposed;
  };
  const transposedData = transposeData(visibleData);

  const chartData = {
    labels: visibleLabel,
    datasets: transposedData.map((values, i) => {
      return {
        data: values,
        backgroundColor: values.map((value) => {
          const opacity = calculateOpacity(value);
          if (value < 20) {
            return `rgb(252, 41, 101,${opacity})`;
          } else if (value < 50) {
            return `rgb(194, 232, 35,${opacity})`;
          } else {
            return `rgba(35, 171, 185, ${opacity})`; // Your default color
          }
        }),
        borderWidth: 1,
        borderRadius: 3,
        barPercentage: 1,
        categoryPercentage: 1,
        barThickness: "flex",
        categorySpacing: 4,
      };
    }),
  };

  const chartOptions = {
    indexAxis: "x",
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          color: "#B5B9C0",
          align: "inner",
          labelOffset: 8,
        },
        grid: {
          color: "transparent",
        },
        border: {
          width: 2,
          color: "#0B1830",
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: "#B5B9C0",
          align: "inner",
          weight: "bold",
          stepSize: 500,
        },
        grid: {
          color: "#545D6E",
          lineWidth: 0.25,
        },
        border: {
          width: 2,
          color: "#0B1830",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  const alternatingBackgroundPlugin = {
    id: "alternatingBackgroundPlugin",
    beforeDraw: (chart) => {
      const ctx = chart.ctx;
      const xAxis = chart.scales.x; // Assuming x is your horizontal axis
      const chartArea = chart.chartArea;

      // Colors to alternate
      const colors = ["#18243b", "#232f43"];

      // Calculate the width for each section
      const sectionWidth =
        (chartArea.right - chartArea.left) / xAxis.ticks.length;

      ctx.save();
      for (let i = 0; i < xAxis.ticks.length; i++) {
        ctx.fillStyle = colors[i % colors.length];
        // Calculate x position of each section. Add a check to ensure the last section is drawn to the end.
        let x = chartArea.left + i * sectionWidth;
        // Prevent overflow by not drawing beyond the chart area.
        let width =
          i === xAxis.ticks.length - 1 ? chartArea.right - x : sectionWidth;
        ctx.fillRect(x, chartArea.top, width, chartArea.bottom - chartArea.top);
      }
      ctx.restore();
    },
  };

  // Register the plugin
  Chart.register(alternatingBackgroundPlugin);
  return (
    <div className="m-auto text-center">
      <Bar data={chartData} options={chartOptions} />
      <p className="months-bar mt-3">
        <span className="cursor-pointer">{"< "} &nbsp;</span>
        {selected} <span className="cursor-pointer">&nbsp;{">"}</span>
      </p>
    </div>
  );
};

export default WaterLevelChart;
