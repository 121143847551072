import React, { useState } from "react";
import AddUser from "./AddUser";
import Tabs from "../shared/Tabs";
import "./Styles.css";
import System from "./System";
import Sensors from "./Sensors";

const TabComponent = () => {
  const organization_type = JSON.parse(
    localStorage.getItem("auth")
  )?.organization_type;
  const [activeTab, setActiveTab] = useState("tab1");
  const [tabs, setTabs] = useState([
    { id: "tab1", title: "User", content: <AddUser /> },
    { id: "tab2", title: "System", content: <System /> },
    // { id: "tab3", title: "Sensors", content: <Sensors /> },
  ]);

  React.useEffect(() => {
    if (
      organization_type === "super_admin" ||
      organization_type === "contractor"
    ) {
      setTabs([{ id: "tab1", title: "User", content: <AddUser /> }]);
    }
  }, [organization_type]);

  return (
    <div>
      <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />

      <div>{tabs.find((tab) => tab.id === activeTab)?.content}</div>
    </div>
  );
};

export default TabComponent;
