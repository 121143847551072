import React from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";

function ErrorFallback({ error }) {
  return (
    <div
      style={{ color: "white", fontSize: "20px", fontWeight: "600" }}
      className="flex justify-center items-center"
    >
      Oops! Something went wrong. Please refresh the page or try again later.
    </div>
  );
}

const ErrorBoundary = ({ children }) => (
  <ReactErrorBoundary FallbackComponent={ErrorFallback}>
    {children}
  </ReactErrorBoundary>
);

export default ErrorBoundary;
