import React, { useState } from "react";
import Pagination from "../shared/Pagination/Pagination";
import AddOrg from "./AddOrg";
import EditOrg from "./EditOrg";
import api from "../util/api";
import { useQuery } from "react-query";
import Button from "../shared/Button";

const TABLE_HEAD = ["Name", "Type", "Brand", "Created Date"];
const TABLE_CELL_WIDTH = 100 / TABLE_HEAD.length;
const PAGE_SIZE = 6;

export default function OrganizationsListing() {
  const [currentPage, setCurrentPage] = useState(1);
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState();

  const [tableRows, setTableRows] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  // startIndex and endIndex for table items
  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = Math.min(startIndex + PAGE_SIZE, tableRows.length);
  const displayedItems = tableRows.slice(startIndex, endIndex);

  const { data: allOrgs } = useQuery(
    "allOrgs",
    () => api.get("admin/get_organizations"),
    {
      enabled: true,
      select: (response) => response.data.organizations,
    }
  );
  React.useEffect(() => {
    if (allOrgs?.length > 0) {
      setTotalPage(Math.ceil(allOrgs.length / PAGE_SIZE));
      setTableRows(allOrgs);
      if (editData) {
        const filteredOrg = allOrgs.filter(
          (item) => item.organization_id === editData.organization_id
        );
        setEditData(filteredOrg[0]);
      }
    }
  }, [allOrgs]);

  function formatTimestampToDate(timestamp) {
    const date = new Date(timestamp * 1000);
    const formatter = new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });

    return formatter.format(date);
  }
  return (
    <>
      {!isEdit && (
        <div className="table-container">
          <div className="ml-auto	">
            <div className="table-container-select-field"></div>

            <Button
              label={"+ Add Organization"}
              className="secondary"
              onClick={() => setIsAdd(true)}
            />
          </div>
          {/* Table Start */}
          <div className="w-full overflow-scroll">
            <table className="w-full table-auto overflow-scroll">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head, index) => (
                    <th key={index} className="border-b p-4">
                      <div
                        className="secondary-font "
                        style={{ fontWeight: 700 }}
                      >
                        {head}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {displayedItems.map(
                  (
                    {
                      organization_id,
                      organization_name,
                      organization_type,
                      brand_name,
                      creation_timestamp,
                      organization_status,
                    },
                    index
                  ) => {
                    return (
                      <tr
                        key={index}
                        style={{
                          width: `${TABLE_CELL_WIDTH}%`,
                          borderBottom:
                            index !== displayedItems.length - 1 &&
                            "0.25px solid #545D6E",
                        }}
                      >
                        <td className="dark-bg" style={{ textWrap: "nowrap" }}>
                          <span> {organization_name}</span>
                        </td>
                        <td className="light-bg" style={{ textWrap: "nowrap" }}>
                          <span> {organization_type}</span>
                        </td>
                        <td className="dark-bg" style={{ textWrap: "nowrap" }}>
                          <span> {brand_name}</span>
                        </td>
                        <td
                          className="light-bg text-center m-auto "
                          style={{ textWrap: "nowrap" }}
                        >
                          <span>
                            {" "}
                            {formatTimestampToDate(creation_timestamp)}
                          </span>
                        </td>
                        <td className="dark-bg" style={{ width: "160px" }}>
                          <button
                            type="button"
                            className="text-white  ml-3   focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-[7px] text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            style={{ background: "#23CBD8" }}
                            onClick={() => {
                              setIsEdit(true);
                              setEditData({
                                organization_id,
                                organization_name,
                                organization_type,
                                brand_name,
                                creation_timestamp,
                                organization_status,
                              });
                            }}
                          >
                            Edit
                            <svg
                              class="w-4 h-4 ml-2 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 14 10"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M1 5h12m0 0L9 1m4 4L9 9"
                              ></path>
                            </svg>
                          </button>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
          {/* Table End */}
          <div className="download-button">
            <Pagination
              currentPage={currentPage}
              totalPage={totalPage}
              setCurrentPage={setCurrentPage}
            />{" "}
          </div>
        </div>
      )}

      {isAdd && !isEdit && <AddOrg setIsAdd={setIsAdd} />}
      {isEdit && !isAdd && editData && (
        <EditOrg setIsEdit={setIsEdit} editData={editData} />
      )}
    </>
  );
}
