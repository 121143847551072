import React, { useState, useRef, useEffect } from "react";
import "./Dropdown.css";

export default function Dropdown(props) {
  const { data, selected = "Months", setSelected } = props;
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // const handleClickOutside = (event) => {
    //   if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    //     setIsOpen(false);
    //   }
    // };
    // document.addEventListener("mousedown", handleClickOutside);
    // return () => {
    //   document.removeEventListener("mousedown", handleClickOutside);
    // };
  }, []);
  return (
    <div style={{ width: "100%" }}>
      <button
        ref={dropdownRef}
        data-dropdown-toggle="dropdown"
        className="h-[37px] px-5 py-2.5 text-center inline-flex items-center dropdown-btn text-nowrap"
        type="button"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {selected}
        <svg
          className={`w-2.5 h-2.5 ms-3 ${isOpen ? "transform rotate-180" : ""}`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>

      {isOpen && (
        <div className="table-container-select-field-ul absolute mt-3 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 bg-1">
          <ul
            className="text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownDefaultButton"
          >
            {data.map((singleField) => {
              return (
                <li
                  onClick={() => {
                    setIsOpen((prev) => !prev);
                    setSelected(singleField);
                    console.log(singleField);
                  }}
                  className="cursor-pointer links-color block px-4 py-3"
                >
                  {singleField}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
