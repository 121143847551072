import React from "react";
import "./ProgressBar.css";

const red = "fc2965";
const blue = "23cbd8";
const green = "c2e823";

const ProgressBar = (props) => {
  const { title = "Filter", progressValue = 6 } = props;

  // Convert progressValue from the scale of 0 to 6 to the scale of 0 to 100
  const progressPercentage = (progressValue / 6) * 100;

  const Segments = title.includes("Filter")
    ? [
        { id: 1, width: "70", color: blue },
        { id: 2, width: "20", color: green },
        { id: 3, width: "10", color: red },
      ]
    : [
        { id: 1, width: "15", color: red },
        { id: 2, width: "10", color: green },
        { id: 3, width: "60", color: blue },
        { id: 4, width: "15", color: red },
      ];
  return (
    <div className="progress-bar-container">
      <div className="progress-bar-heading">{title}</div>
      <div className="progress-bar-with-pointer">
        <div
          className="progress-bar-pointer"
          style={{
            left: `${progressPercentage <= 0 ? 0 : progressPercentage}%`,
          }}
        />
        <div className="progress-bar">
          {Segments.map((item) => (
            <div
              key={item.id}
              style={{
                width: `${item.width}%`,
                backgroundColor: `#${item.color}`,
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
