import axios from "axios";
import { fetchAuthSession } from "aws-amplify/auth";

const refreshApiSession = async () => {
  const { tokens } = await fetchAuthSession({ forceRefresh: true });
  const organization_type =
    tokens?.idToken?.payload["custom:organization_type"];
  const organization_id = tokens?.idToken?.payload["custom:organization_id"];

  const idToken = tokens?.idToken?.toString();
  const user_type = JSON.parse(localStorage.getItem("auth"))?.user_type;
  if (idToken) {
    localStorage.setItem(
      "auth",
      JSON.stringify({
        isSignedIn: true,
        token: idToken,
        user_type,
        organization_type,
        organization_id,
      })
    );
  } else {
  }
};
// Create an Axios instance with a base URL
const api = axios.create({
  baseURL: "https://p02y0kxfm9.execute-api.eu-west-1.amazonaws.com/prod",
});

// Add an interceptor to set the authorization header
api.interceptors.request.use(
  (config) => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth && auth.token) {
      config.headers.Authorization = `Bearer ${auth.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add an interceptor to handle logout based on status code
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    refreshApiSession();
    console.log(error);
    return Promise.reject(error);
  }
);

export default api;
