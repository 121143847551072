import React, { useState } from "react";
import { resetPassword, confirmResetPassword } from "aws-amplify/auth";
import TextField from "../shared/TextField";
import Button from "../shared/Button";
import { useSnackbar } from "notistack";
import "./Styles.css";

const PasswordReset = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [step, setStep] = useState(1);
  const [username, setUsername] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!username) return;
    try {
      const output = await resetPassword({ username });
      handleResetPasswordNextSteps(output);
      setStep(2);
    } catch (error) {
      console.log(error);
      setError(error.message || "An error occurred.");
    }
  };

  const handleResetPasswordNextSteps = (output) => {
    const { nextStep } = output;
    switch (nextStep.resetPasswordStep) {
      case "CONFIRM_RESET_PASSWORD_WITH_CODE":
        const codeDeliveryDetails = nextStep.codeDeliveryDetails;
        enqueueSnackbar(`Confirmation code was sent to ${username}`, {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        console.log(
          `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`
        );
        break;
      case "DONE":
        console.log("Successfully reset password.");
        break;
      default:
        break;
    }
  };

  const handleConfirmResetPassword = async (e) => {
    e.preventDefault();
    try {
      await confirmResetPassword({ username, confirmationCode, newPassword });
      enqueueSnackbar("Password reset confirmed successfully.", {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => {
        window.location.href = "/";
      }, 400);
    } catch (error) {
      console.log(error);
      setError(error.message || "An error occurred.");
    }
  };

  return (
    <div className="w-full">
      {step === 1 ? (
        <h1 className="primary-font mb-5" style={{ color: "#ebffff" }}>
          Don’t worry! Resetting your password is easy. Just type your
          registered email below.
        </h1>
      ) : (
        <p className="heading mb-4">Reset Password</p>
      )}

      {/* Reset Password Form */}
      {step === 1 && (
        <form className="mb-4 w-full" onSubmit={handleResetPassword}>
          <TextField
            name="email"
            label="Email"
            type="email"
            className="secondary"
            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
            title="Please enter a valid email address."
            required
            onChange={(e) => {
              setUsername(e.target.value.trim());
              setError("");
            }}
          />
          {error && <p className="error-msg">{error}</p>}

          <Button label="Send Code" className="primary" type="submit" />
        </form>
      )}

      {step === 2 && (
        <form className="mb-4 w-full" onSubmit={handleConfirmResetPassword}>
          <TextField
            name="code"
            label="Confirmation code"
            type="text"
            className="secondary"
            pattern=".{6,}"
            required
            onChange={(e) => {
              setError("");
              setConfirmationCode(e.target.value);
            }}
          />
          <TextField
            name="newPassword"
            label="New password"
            type="text"
            className="secondary"
            required
            onChange={(e) => {
              setNewPassword(e.target.value);
              setError("");
            }}
          />
          {error && <p className="error-msg">{error}</p>}
          <Button label="Submit" className="primary" type="submit" />
        </form>
      )}
    </div>
  );
};

export default PasswordReset;
