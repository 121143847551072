import React, { useEffect, useState } from "react";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import Layout from "./components/layout/Layout";
import "./App.css";
import Dashboard from "./components/dashboard/DashboardWrapper";
import Components from "./components/components";
import Monitor from "./components/Monitor/Monitor";
import Settings from "./components/settings/Settings";
import Profile from "./components/settings/Profile";
import Report from "./components/Report/Report";
import Login from "./components/client/Login";
import Organizations from "./components/organizations/Organizations";
import Brands from "./components/brands/Brands";
import { AWS_COGNITO } from "./config";
import ErrorBoundary from "./components/util/errorBoundary";
import { useQuery, useQueryClient } from "react-query";
import api from "./components/util/api";

// amplify init
Amplify.configure({
  Auth: {
    Cognito: AWS_COGNITO,
  },
  // API: {
  //   GraphQL: {
  //     endpoint:
  //       "https://ptmmsww5offudo7vcv2qlrzr5u.appsync-api.eu-west-1.amazonaws.com/graphql ",
  //     region: "eu-west-1",
  //     defaultAuthMode: "apiKey",
  //     apiKey: "da2-577mmkj5uzbejjh6bfdfc5dw4y",
  //   },
  // },
  API: {
    GraphQL: {
      endpoint:
        "https://graph-api-prod.aquacontrol.ai/graphql",
      region: "eu-west-1",
      defaultAuthMode: "apiKey",
      apiKey: "da2-xn5gi2ifubfrrbbe3uillfkxha",
    },
  },
});

function App() {
  const history = useNavigate();
  const queryClient = useQueryClient();

  const auth = JSON.parse(localStorage.getItem("auth"));
  const organization_type = auth?.organization_type;
  const [isLoggedIn, setIsLoggedIn] = useState(auth?.isSignedIn || false);
  const [hasNavigated, setHasNavigated] = useState(false);

  useEffect(() => {
    const authUpdate = JSON.parse(localStorage.getItem("auth"));
    setIsLoggedIn(authUpdate?.isSignedIn || false);
  }, [auth]);

  const getConditionalRoutes = () => {
    let conditionalRoutes = [];

    if (organization_type === "customer") {
      conditionalRoutes.push(
        <Route key="dashboard" path="/" element={<Dashboard />} />,
        <Route key="report" path="/report" element={<Report />} />
      );
    }

    if (organization_type === "contractor") {
      conditionalRoutes.push(
        <Route key="monitor" path="/" element={<Monitor />} />
      );
    }

    if (auth?.user_type === "admin") {
      conditionalRoutes.push(
        <Route key="settings" path="/settings" element={<Settings />} />
      );
    }

    if (organization_type === "super_admin") {
      conditionalRoutes.push(
        <Route key="organizations" path="/" element={<Organizations />} />,
        <Route key="brands" path="/brands" element={<Brands />} />
      );
    }

    // if (auth?.user_type === "viewer") {
    //   conditionalRoutes.push(
    //     <Route key="dashboard" path="/" element={<Dashboard />} />,
    //     <Route key="monitor" path="/monitor" element={<Monitor />} />,
    //     <Route key="report" path="/report" element={<Report />} />
    //   );
    // }
    return conditionalRoutes;
  };

  // useEffect(() => {
  //   const hasNavigatedExist = localStorage.getItem("hasNavigated");

  //   if (isLoggedIn && !hasNavigated) {
  //     localStorage.setItem("hasNavigated", true);
  //     if (organization_type === "super_admin") {
  //       navigate("/");
  //       setHasNavigated(true);
  //     } else if (organization_type === "customer") {
  //       navigate("/");
  //       setHasNavigated(true);
  //     } else if (organization_type === "contractor") {
  //       navigate("/monitor");
  //       setHasNavigated(true);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isLoggedIn, organization_type, navigate]);

  return (
    <div>
      {!isLoggedIn ? (
        <Login
          onLogin={() => {
            setIsLoggedIn(true);
          }}
        />
      ) : (
        <Layout>
          <ErrorBoundary>
            <Routes>
              <Route path="/components" element={<Components />} />
              <Route path="/profile" element={<Profile />} />

              {getConditionalRoutes()}
              <Route path="/more-info-dashboard" element={<Dashboard />} />
              <Route path="/more-info-report" element={<Report />} />
            </Routes>
          </ErrorBoundary>
        </Layout>
      )}
    </div>
  );
}

export default App;

// export default withAuthenticator(App, { hideSignUp: true });
// // CAN BE USED LATER
// import React, { useEffect } from "react";
// import { Amplify, Auth } from "aws-amplify";
// import { withAuthenticator } from "@aws-amplify/ui-react";
// import "@aws-amplify/ui-react/styles.css";
// import awsExports from "./aws-exports.json";
// import NavBar from "./components/layout/NavBar";
// import SideSheet from "./components/layout/SideSheet";
// import Test from "./test";
// import Button from "./components/shared/Button";
// import TextField from "./components/shared/TextField";
// import "./App.css";

// Amplify.configure(awsExports);
// function App({ signOut, user }) {
//   return (
//     <>
//       {/* <h1>Hello {user.username}</h1>
//       <button onClick={signOut}>Sign out</button> */}
//       <div className="flex flex-col h-screen">
//         <NavBar />
//         <div>
//           <SideSheet />
//           {/* Main Content */}
//           <div className="main-content-wrapper">
//             Content goes here
//             <button onClick={signOut}>Sign out</button>
//             <br></br>
//             <Button label={"login"} className="primary" />
//             <Button label={"login"} className="secondary" />
//             <Button label={"login"} className="tertiary" />
//             <TextField label={"Username"} className="primary" />
//             <TextField label={"Username"} className="secondary" />
//             <Test />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default withAuthenticator(App, { hideSignUp: true });
// // export default App;
