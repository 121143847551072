export function getFlowInProductionQuery(interval, zone, plant, port, limit) {
  return `query MyQuery {
    getIotData(interval: "${interval}", zone: "${zone}", plant: "${plant}", port: "${port}", limit: ${limit}) {
      port
      plant
      measure_name
      Flow_Lpmin
      time
    }
  }`;
}

export function getFlowProductionQuery(plant_id) {
  return `query MyQuery {
  getTankAndPressureData(interval: "current", plant_id:"${plant_id}") {
    currentTankLevel
    distributedWaterx04
    filter1Result
    filter2Result
    producedWaterx08     
    plantID
    plantName
    maxTankLevel
 }  
}
`;
}

export function getSensorStatus(interval, plant, limit) {
  return `query MyQuery {
  getIotData(interval: "${interval}", plant: "${plant}",  limit: ${limit}) {
    port
    zone
    plant
    sensor_type
    sensor_name
    time
    DeviceStatus
  }
}
`;
}

export function waterProductionDistributionHistoryQuery(
  port,
  waterProductionHistory,
  Flow_Lpmin
) {
  return `query MyQuery {
  getFlowWaterProductionAndDistribution {
    ${port} {
       ${waterProductionHistory} {
        ${Flow_Lpmin}
        time_interval
      }
    }
  }
}`;
}

export const getFlowWaterProductionQuery = `query MyQuery {
  getFlowWaterProductionAndDistribution {
    x08 {
      waterProductionX08PerDay
      waterProductionX08PerMonth
      waterProductionX08PerYear
      waterProductionX08Recent24Hours
      waterProductionX08PerWeek
      waterProductionX08CurrentFlowLpmin
    }
  }
}`;

export const getFlowWaterDistributionQuery = `query MyQuery {
    getFlowWaterProductionAndDistribution {
      x04 {
        waterDistributionX04Recent24Hours
        waterDistributionX04PerYear
        waterDistributionX04PerWeek
        waterDistributionX04PerMonth
        waterDistributionX04PerDay
        waterDistributionX04CurrentFlowLpmin
      }
    }
  }
  `;

export function getSensorsPressureQuery(interval, name, plantId) {
  return `query MyQuery {
    getPressureDataForAllSensor(interval: "${interval}", port: "${name}",plant_id: "${plantId}") {
      time_interval
      Pressure_bar
    }
  }
    `;
}

export function getPressureDiffQuery(interval, name, plantId) {
  return `query MyQuery {
  getPressureDifferenceWithPortx07Andx05Withx01(interval: "${interval}", port: "${name}",plant_id: "${plantId}") {
    time_interval
    pressure_bar_difference
  }

}
`;
}

export function getQualityhData(interval, plantId) {
  return `query MyQuery {
  getX03Conductivity_mcSpcmAndTemperature_C(interval:  "${interval}", limit: 50,plant_id: "${plantId}") {
    Conductivity_mcSpcm
    Temperature_C
    time_interval
  }
}`;
}

export function getOperationHours(interval, plantId) {
  return `query MyQuery {
  getMachineHistoryOperationData(interval: "${interval}",plant_id: "${plantId}") {
    Flow_Lpmin_status
    time_interval
    TotalOperatingTime_hours
    OperatingTime_hours
  }
}`;
}

export function getFlowData(interval) {
  return `query MyQuery {
    getHistoryWaterFlowx04Andx08ProductionAndDistribution(interval: "week", port: "x08", limit: "100") {
      Flow_Lpmin
      time_interval
    }
  }`;
}

export function waterProductionDistributionHistory(interval, port, plantId) {
  return `query MyQuery {    getHistoryWaterFlowx04Andx08ProductionAndDistribution(interval:  "${interval}", port: "${port}", limit: "300",plant_id: "${plantId}") {
      Flow_Lpmin
      time_interval
    }
}`;
}

export const getTankLevelHistoryQuery = `query MyQuery {
  getTankLevelHistory(interval: "current") {
    currentTankLevel
    time_interval
  }
}`;

export function getWaterFlowDistributionX04Query(interval, plantId) {
  return `query MyQuery{
  getWaterFlowDistributionX04(interval:  "${interval}",plant_id: "${plantId}") {
    current_flowLpmin_x04
    current_totilizer_x04
    flow_totilizer_x04
    time_interval
  }
}`;
}

export function getWaterFlowProductionX08Query(interval, plantId) {
  return `query MyQuery{
  getWaterFlowProductionX08(interval:"${interval}",plant_id: "${plantId}") {
    current_flowLpmin_x08
    flow_totilizer_x08
    time_interval
    current_totilizer_x08
  }
}`;
}

export function getTankLevelHistoryGraphQuery(interval, plantId) {
  return `query MyQuery {
  getTankLevelHistory(date: "${interval}",plant_id: "${plantId}") {
    currentTankLevel
    time_interval
  }
}`;
}
export function getTankLevelHistoryMaxMinAvgQuery(interval) {
  return `query MyQuery {
  getTankLevelHistoryMaxMinAvg(interval:"${interval}") {
    average_currentTankLevel
    highest_currentTankLevel
    lowest_currentTankLevel
    time_interval
  }
}`;
}

export function getMachineOperationDataQuery(plant_id) {
  return `query MyQuery {
  getMachineOperationData(interval: "current",plant_id: "${plant_id}") {
    time_interval
    Machine1
    Flow_Lpmin_status
  }
}`;
}

export function getIotDataQuery(plant_id) {
  return `query MyQuery {
  getIotData(interval: "current", plant_id: "${plant_id}") {
    DeviceStatus
    port
    zone
  }
}`;
}
export function getAlertQuery(plant_id) {
  return `query MyQuery {
  getStatusBarWaterProduction (plant_id: "${plant_id}"){
    x04_distribution_water
    status_alert
    x08_production_water
  }
  getStatusBarX07AndX05(port: "x05",plant_id: "${plant_id}") {
    status_alert
    pressure_bar_difference
  }
  getStatusOperation(plant_id: "${plant_id}") {
    Flow_Lpmin_x08
    current_Tank_Level
    status_alert
  }
  getStatusPressureIntakex01(plant_id: "${plant_id}") {
    pressure_bar
    status_alert
  }
  getStatusTankLevelBelow60Percentage(plant_id: "${plant_id}") {
    status_alert
    current_Tank_Level
  }
}
  `;
}

export function getStatusBarX05AndX07Query(plant_id) {
  return `query MyQuery {
    getStatusBarX07AndX05(port: "x07",plant_id: "${plant_id}") {
      status_alert
      pressure_bar_difference
    }
  }`;
}
export function getPLantPortStatusAlertQuery(plant_id) {
  return `query MyQuery {
    getPLantPortStatusAlert(plant_id: "${plant_id}") {
      port
      connection_status
    }
  }`;
}
