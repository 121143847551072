import React, { useEffect, useState } from "react";
import CardWrapper from "../../shared/CardWrapper/CardWrapper";
import TankLevel from "../../shared/TankLevel/TankLevel";
// import TankLevelTable from "./TankLevelTable";
import ColoredChart from "./ColoredChart";
import { generateClient } from "aws-amplify/api";
import { getFlowProductionQuery } from "../../util/query";
import { getPlantId } from "../../util/helper";
export default function TankLevelPage() {
  const plantId = getPlantId();
  const [tankLevel, setTankLevel] = useState();
  const client = generateClient();
  async function getTankLevelHistory() {
    try {
      const query = getFlowProductionQuery(plantId);
      // Make the asynchronous GraphQL request
      const flowIn = await client.graphql({ query });
      if (flowIn && flowIn.data.getTankAndPressureData) {
        setTankLevel(flowIn.data.getTankAndPressureData);
      }
    } catch (error) {
      console.error("Error fetching flow in tank data:", error);
    }
  }

  useEffect(() => {
    const fetchData = () => {
      getTankLevelHistory();
    };
    fetchData(); // Initial fetch
    const interval = setInterval(fetchData, 600000); // Set interval to 10 minutes
    return () => clearInterval(interval); // Clear interval on component unmount or when activeTab changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="tank-level-page">
      <div className="grid gap-0 md:gap-5 grid-cols-1 xl:grid-cols-3">
        <CardWrapper className="flex justify-center align-middle items-center">
          <TankLevel
            recenter
            progressValue={Number(tankLevel?.currentTankLevel).toFixed(2) || 0}
            maxTankLevel={Number(tankLevel?.maxTankLevel)?.toFixed(2)}
          />
        </CardWrapper>
        <div className="col-span-2 text-white">
          <ColoredChart plantId={plantId} />
        </div>
      </div>
      {/* <div className="grid mt-5 gap-5 grid-cols-1 xl:grid-cols-3">
        <div className="col-span-2">
          <TankLevelTable />
        </div>
      </div> */}
    </div>
  );
}
