import React, { useEffect, useState } from "react";
import Pagination from "../../shared/Pagination/Pagination";
import Dropdown from "../../shared/Dropdown/Dropdown";
import { generateClient } from "aws-amplify/api";
import { waterProductionDistributionHistory } from "../../util/query";
import ListSkeleton from "../../util/listSkeleton";
import { getFormattedDate } from "../../util/helper";
import { CSVLink } from "react-csv";

const TABLE_HEAD = ["Interval", "Flow in (kbm)", "Flow out (kbm)"];
const TABLE_SELECT_FIELD = ["Day", "Week", "Month", "Year"];
const TABLE_CELL_WIDTH = 100 / TABLE_HEAD.length;
const PAGE_SIZE = 6;

export default function FlowTable({ plantId }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [selected, setSelected] = useState("Day");
  const [flowIn, setFlowIn] = useState();
  const [flowOut, setFlowOut] = useState();
  const [tableRows, setTableRows] = useState();
  const [totalPage, setTotalPage] = useState();
  const client = generateClient();
  ////production

  async function fetchFlowInProduction() {
    try {
      const port = "x08";
      const query = waterProductionDistributionHistory(
        selected.toLowerCase(),
        port,
        plantId
      );
      const flowIn = await client.graphql({
        query,
      });
      if (flowIn) {
        const filteredData = await flowIn.data
          .getHistoryWaterFlowx04Andx08ProductionAndDistribution;
        setFlowIn(filteredData);
      }
    } catch (error) {
      console.error("Error fetching flow in production data:", error);
    }
  }
  ////distribution

  async function fetchFlowOutDistribution() {
    try {
      const port = "x04";
      const query = waterProductionDistributionHistory(
        selected.toLowerCase(),
        port,
        plantId
      );
      // Make the asynchronous GraphQL request
      const flowOut = await client.graphql({
        query,
      });
      if (flowOut) {
        const filteredData = await flowOut.data
          .getHistoryWaterFlowx04Andx08ProductionAndDistribution;
        setFlowOut(filteredData);
      }
    } catch (error) {
      console.error("Error fetching flow in production data:", error);
    }
  }
  React.useEffect(() => {
    const fetchData = () => {
      fetchFlowInProduction();
      fetchFlowOutDistribution();
    };

    fetchData(); // Initial fetch
    const interval = setInterval(fetchData, 600000); // Set interval to 10 minutes
    return () => clearInterval(interval); // Clear interval on component unmount
  }, [selected]);

  React.useEffect(() => {
    if (flowIn && flowOut) {
      const TABLE_ROWS = flowIn.map((inItem, index) => {
        const outItem = flowOut[index];
        return {
          monthName: getFormattedDate(flowIn[index].time_interval, selected),
          flowIn:
            Number(inItem.Flow_Lpmin).toFixed(2) > 0
              ? Number(inItem.Flow_Lpmin).toFixed(2)
              : Number(inItem.Flow_Lpmin).toFixed(2),
          flowOut:
            Number(outItem.Flow_Lpmin)?.toFixed(2) > 0
              ? Number(outItem.Flow_Lpmin)?.toFixed(2)
              : Number(outItem.Flow_Lpmin)?.toFixed(2),
        };
      });
      setTableRows(TABLE_ROWS);
    }
  }, [flowIn, flowOut]);
  useEffect(() => {
    tableRows && setTotalPage(Math.ceil(tableRows.length / PAGE_SIZE));
    tableRows && setCurrentPage(1);
  }, [tableRows]);
  // startIndex and endIndex for table items
  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex =
    tableRows && Math.min(startIndex + PAGE_SIZE, tableRows.length);
  const displayedItems = tableRows && tableRows.slice(startIndex, endIndex);
  return (
    <div className="table-container">
      <div className="table-container-row">
        <div className="table-container-select-field">
          <Dropdown
            data={TABLE_SELECT_FIELD}
            setSelected={(singleField) => {
              setSelected(singleField);
              if (singleField !== selected) {
                setTableRows();
                setFlowIn();
                setFlowOut();
              }
            }}
            selected={selected}
          />
        </div>
        <Pagination
          currentPage={currentPage}
          totalPage={totalPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
      {/* Table Start */}
      {tableRows ? (
        <div className="w-full overflow-scroll">
          <table className="w-full table-auto">
            <thead>
              <tr>
                {TABLE_HEAD.map((head, index) => (
                  <th key={index} className="border-b p-4">
                    <div className="table-heading">{head}</div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {displayedItems?.map(({ monthName, flowIn, flowOut }, index) => {
                return (
                  <tr
                    key={index}
                    style={{
                      width: `${TABLE_CELL_WIDTH}%`,
                      borderBottom:
                        index !== displayedItems.length - 1 &&
                        "0.25px solid #545D6E",
                    }}
                  >
                    <td className="dark-bg">
                      <span> {monthName}</span>
                    </td>
                    <td
                      className="light-bg"
                      style={{ width: `${TABLE_CELL_WIDTH}%` }}
                    >
                      <span> {flowIn}</span>
                    </td>
                    <td
                      className="dark-bg"
                      style={{ width: `${TABLE_CELL_WIDTH}%` }}
                    >
                      <span> {flowOut}</span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <ListSkeleton breakpoint="lg" />
      )}
      {/* Table End */}
      <div className="download-button">
        {tableRows && (
          <CSVLink
            data={tableRows}
            headers={[
              { label: "Interval", key: "monthName" },
              { label: "Flow in (kbm)", key: "flowIn" },
              { label: "Flow out (kbm)", key: "flowOut" },
            ]}
            filename={`${"Flow-table"}.csv`}
          >
            <span className="underline">Download</span>{" "}
          </CSVLink>
        )}
      </div>
    </div>
  );
}
