import React, { useState } from "react";
import TextField from "../shared/TextField";
import Button from "../shared/Button";
import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import api from "../util/api";
import { useSnackbar } from "notistack";
import DropdownPrimary from "../shared/Dropdown/DropdownPrimary";
import { useQuery } from "react-query";

function AddOrg({ setIsAdd }) {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setIsAdd(!isModalOpen);
  };

  return (
    <div>
      {/* Main modal */}
      {isModalOpen && <Modal onClose={toggleModal} setIsAdd={setIsAdd} />}
    </div>
  );
}

function Modal({ onClose, setIsAdd }) {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [orgName, setOrgName] = React.useState("");
  const [error, setError] = useState("");
  const [selectedBrand, setSelectedBrand] =
    React.useState("Organization Brand");
  const [selectedType, setSelectedType] = React.useState("Organization Type");
  const { mutate: addOrg } = useMutation(
    ["addOrg"],
    (data) => api.post("/admin/add_organization", data),
    {
      onSuccess: () => {
        onClose();
        enqueueSnackbar("Organization added successfully.", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        queryClient.invalidateQueries("allOrgs");
      },
      onError: (error) => {
        enqueueSnackbar(error.response.data, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
    }
  );
  const { data: allBrands } = useQuery(
    "allBrands",
    () => api.get("/admin/get_brands"),
    {
      enabled: true,
      select: (response) => response.data.brands,
    }
  );

  const onAdd = (e) => {
    e.preventDefault();
    if (selectedType === "Organization Type") {
      setError("Select organization type.");
      return;
    }
    if (
      selectedBrand === "Organization Brand" &&
      selectedType === "contractor"
    ) {
      setError("Select organization brand.");
      return;
    }
    const organization_id = "id" + Math.random().toString(16).slice(2);
    const brand_id =
      !selectedBrand === "Organization Brand" &&
      allBrands.filter((item) => item.brand_name === selectedBrand)[0].brand_id;
    const data = {
      organization_id,
      organization_name: orgName,
      organization_type: selectedType,
      brand_name:
        selectedBrand === "Organization Brand" ? undefined : selectedBrand,
      brand_id: selectedBrand === "Organization Brand" ? undefined : brand_id,
    };
    addOrg(data);
  };
  const brandsName = allBrands?.map((item) => item.brand_name);

  return (
    <div className="fixed top-0 right-0 left-0 z-50 flex items-center justify-center w-full h-[calc(100%-1rem)] overflow-y-auto overflow-x-hidden md:inset-0 max-h-full backdrop-blur-sdm ">
      <div className="relative m-4  w-full  rounded-lg max-h-full max-w-[530px] border-[.1px]  border-gray-500 ">
        <div className="relative bg-white rounded-lg shadow bg-1">
          <div className="flex items-center justify-between p-4 md:p-4 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              Add Organization{" "}
            </h3>
            <button
              onClick={onClose}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-2 dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <form className=" w-full" onSubmit={onAdd}>
            <div className="p-4 md:p-5 space-y-4">
              <TextField
                className="primary"
                name="name"
                label="Organization Name"
                type="text"
                required
                value={orgName}
                pattern=".{3,}"
                title="Minimum 3 characters name"
                onChange={(e) => {
                  setOrgName(e.target.value);
                  setError("");
                }}
              />

              <DropdownPrimary
                data={["customer", "contractor"]}
                selected={selectedType}
                setSelected={setSelectedType}
                onSelect={() => {
                  setError("");
                }}
                btnClass="h-[47.5px]  mt-3 px-3 py-2.5 text-center inline-flex items-center  text-nowrap w-full flex justify-between rounded-[6px] border-[1px] border-white "
                paperClass="absolute mt-3 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-full md:max-w-md bg-1  md:max-w-[510px]  md:left-0 bg-2"
              />
              {selectedType === "contractor" && (
                <DropdownPrimary
                  data={brandsName || []}
                  selected={selectedBrand}
                  setSelected={setSelectedBrand}
                  onSelect={() => {
                    setError("");
                  }}
                  btnClass="h-[47.5px]  mt-3 px-3 py-2.5 text-center inline-flex items-center  text-nowrap w-full flex justify-between rounded-[6px] border-[1px] border-white "
                  paperClass="absolute mt-3 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-full md:max-w-[510px] bg-1   md:left-0   bg-2"
                />
              )}
              {error && <p className="error-msg md:pt-3 pt-0">{error}</p>}

              <div className="col-span-3 xl:col-span-2 mt-12 py-4">
                <Button label={"Save"} className="secondary" type="submit" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddOrg;
