import React from "react";
import "./SensorTag.css";

export default function SensorTag(props) {
  const { text = "Timraön", type = "primary", icon: Icon, lg = false } = props;
  return (
    <div
      className={`${
        lg ? "sensor-tag-container-lg" : "sensor-tag-container"
      } ${type} `}
    >
      {Icon}
      <div
        className="sensor-tag-text"
        style={{ fontSize: lg ? "15px" : "11px" }}
      >
        {text}
      </div>
    </div>
  );
}
