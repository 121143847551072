import React, { useState, useEffect } from "react";
import "./TankLevel.css";

export default function TankLevel(props) {
  const {
    isSmall = false,
    progressValue = 8000,
    recenter = false,
    className = "",
    maxTankLevel = 9000,
  } = props;
  const [selectedLine, setSelectedLine] = useState(1);
  const [pointerPosition, setPointerPosition] = useState(0);
  const selectLine = (index) => {
    setSelectedLine(index);
  };
  const calculatedPosition = (progressValue / maxTankLevel) * 100;

  useEffect(() => {
    setPointerPosition(100 - calculatedPosition); // Invert to move the pointer upwards
  }, [progressValue]);

  const colors = isSmall
    ? [
        "#23CBD8",
        "#3BE0E0",
        "#3BE0E0",
        "#3BE0E0",
        "#3BE0E0",
        "#8EF9FA",
        "#8EF9FA",
        "#8EF9FA",
        "#8EF9FA",
        "#8EF9FA",
        "#CEED4F",
        "#DAF17B",
        "#FEA9C1",
        "#FD5484",
        "#FC2965",
      ]
    : [
        "#23CBD8",
        "#23CBD8",
        "#23CBD8",
        "#23CBD8",
        "#3BE0E0",
        "#3BE0E0",
        "#3BE0E0",
        "#3BE0E0",
        "#69F0F3",
        "#69F0F3",
        "#8EF9FA",
        "#8EF9FA",
        "#8EF9FA",
        "#8EF9FA",
        "#8EF9FA",
        "#8EF9FA",
        "#C2E823",
        "#DAF17B",
        "#E7F6A7",
        "#FEA9C1",
        "#FD7FA3",
        "#FD5484",
        "#FC2965",
        "#FC2965",
      ];

  const numLines = isSmall ? 15 : 24;
  const center = recenter ? "recenter" : "";
  const small = isSmall ? "small" : "";
  return (
    <div className={`lines-main-container ${className} ${center} ${small}`}>
      <div className="lines-container-heading">Tank level</div>
      <div className="group-container">
        <div
          className="pointer-container"
          style={{ top: `${pointerPosition + 5}%` }}
        >
          {!isSmall ? (
            <span
              style={{
                fontSize: "12px",
                marginTop: "1.4px",
                color: isNaN(Number(progressValue).toFixed())
                  ? "transparent"
                  : "white",
              }}
            >
              {Number(progressValue).toFixed() + " L"}{" "}
            </span>
          ) : null}
          <div className="lines-container-pointer" />
        </div>
        <div className="lines-container">
          {Array.from({ length: numLines }, (_, index) => (
            <div
              key={index}
              className={`lines-container-single ${
                selectedLine === index ? "selected" : ""
              }`}
              style={{ background: colors[index % colors.length] }}
              onClick={() => selectLine(index)}
            ></div>
          ))}
        </div>
      </div>
      <div className="percentage-value">
        {isNaN(calculatedPosition.toFixed(2))
          ? ""
          : calculatedPosition.toFixed(2)}
        %
      </div>
    </div>
  );
}
