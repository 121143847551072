import React, { useState } from "react";
import TextField from "../shared/TextField";
import Button from "../shared/Button";
import DropdownPrimary from "../shared/Dropdown/DropdownPrimary";
import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import api from "../util/api";
import { useSnackbar } from "notistack";
import { useQuery } from "react-query";

export default function System() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState("");
  const [edit, setEdit] = useState(false);

  const [formData, setFormData] = React.useState({
    name_of_facility: "",
    mail_contact: "",
    brand_id: "",
    brand_name: "Select Brand",
    model: "",
    gps_coordinates: "",
    expected_capacity: "",
    depth_intake: "",
    depth_from_surface_to_bottom: "",
    intake__distance_from_land: "",
    tank_level_manual_offset: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setError("");
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDropdownChange = (selectedBrand) => {
    const brand_id = allBrands.filter(
      (item) => item.brand_name === selectedBrand
    )[0]?.brand_id;
    setFormData((prev) => ({
      ...prev,
      brand_name: selectedBrand,
      brand_id,
    }));
    setError("");
  };
  const { data: allBrands } = useQuery(
    "allBrands",
    () => api.get("/admin/get_brands"),
    {
      enabled: true,
      select: (response) => response.data.brands,
    }
  );

  const { mutate: addPlant } = useMutation(
    ["addPlant"],
    (data) => api.post("/admin/update_plant", data),
    {
      onSuccess: () => {
        enqueueSnackbar(
          edit ? "Plant updated successfully." : "Plant added successfully.",
          {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          }
        );
        queryClient.invalidateQueries("getPlant");
      },
      onError: (error) => {
        enqueueSnackbar(error.response.data, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
    }
  );
  const validateEmails = (str) => {
    // Remove commas from the start and end of the string
    const cleanedStr = str.replace(/^,|,$/g, "");

    // Split the string by commas to get individual emails
    const emails = cleanedStr.split(",");

    // Regular expression for validating email addresses
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Validate each email and return the result
    return emails.every((email) => emailRegex.test(email.trim()));
  };
  const cleanString = (str) => {
    return str.replace(/^,|,$/g, "");
  };
  const onAdd = (e) => {
    e.preventDefault();
    if (formData.brand_name === "Select Brand") {
      setError("Please select brand.");
      return;
    }

    if (!validateEmails(formData.mail_contact)) {
      setError("Enter valid comma separated email(s) in mail contact.");
      return;
    }
    if (!edit) {
      const plant_id = "id" + Math.random().toString(16).slice(2);
      const data = {
        ...formData,
        plant_id,
        expected_capacity: +formData.expected_capacity,
        depth_intake: +formData.depth_intake,
        depth_from_surface_to_bottom: +formData.depth_from_surface_to_bottom,
        intake__distance_from_land: +formData.intake__distance_from_land,
        tank_level_manual_offset: +formData.tank_level_manual_offset,
        mail_contact: cleanString(formData.mail_contact),
      };
      addPlant(data);
    } else {
      const data = {
        ...formData,
        expected_capacity: +formData.expected_capacity,
        depth_intake: +formData.depth_intake,
        depth_from_surface_to_bottom: +formData.depth_from_surface_to_bottom,
        intake__distance_from_land: +formData.intake__distance_from_land,
        tank_level_manual_offset: +formData.tank_level_manual_offset,
        mail_contact: cleanString(formData.mail_contact),
      };
      addPlant(data);
    }
  };

  const { data: getPlant } = useQuery(
    "getPlant",
    () => api.get("/admin/get_plant"),
    {
      enabled: true,
      select: (response) => response?.data,
      onSuccess: (data) => {
        if (data.length > 0) {
          const plant = data[0];
          delete plant.modification_timestamp;
          delete plant.organization_name;
          delete plant.organization_id;
          setFormData(plant);
          setEdit(true);
        }
      },
    }
  );
  const brandsName = allBrands?.map((item) => item.brand_name);
  return (
    <div className="mt-8">
      <form className="grid grid-cols-3" onSubmit={onAdd}>
        <div className="col-span-3 xl:col-span-2 grid grid-cols-1 md:grid-cols-2 gap-3">
          <TextField
            className="primary"
            name="name_of_facility"
            label="Name of facility"
            type="text"
            required
            value={formData.name_of_facility}
            onChange={handleChange}
            pattern=".*"
          />

          <TextField
            className="primary"
            name="mail_contact"
            label="Mail contacts / Warnings sent to"
            type="text"
            required
            value={formData.mail_contact}
            onChange={handleChange}
            placeholder="Enter comma separated emails"
          />

          <span>
            <p className="primary-input-label">Brand</p>
            <DropdownPrimary
              data={brandsName || []}
              selected={formData.brand_name}
              onSelect={() => {}}
              setSelected={(option) => !edit && handleDropdownChange(option)}
              btnClass={`h-[47px] mt-0 px-3 py-2.5 text-center inline-flex items-center text-nowrap w-full flex justify-between rounded-[6px] border-[1px] border-white `}
              paperClass="absolute mt-3 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-full md:w-full bg-1 md:left-0"
              click={!edit}
            />
          </span>

          <TextField
            className="primary"
            name="model"
            label="Model"
            type="text"
            xClass="mt-3 md:mt-0"
            required
            value={formData.model}
            onChange={handleChange}
          />

          <TextField
            className="primary"
            name="gps_coordinates"
            label="GPS-coordinates"
            type="text"
            required
            value={formData.gps_coordinates}
            onChange={handleChange}
          />
          <TextField
            className="primary"
            name="expected_capacity"
            label="Expected capacity"
            type="number"
            required
            value={formData.expected_capacity}
            onChange={handleChange}
          />
        </div>

        <div className="col-span-3 xl:col-span-2 grid grid-cols-1 md:grid-cols-3 items-end gap-5 relative mt-3">
          <TextField
            className="primary"
            name="depth_intake"
            label="Depth (intake)"
            type="number"
            required
            xClass="mt-3 md:mt-0"
            unit
            value={formData.depth_intake}
            onChange={handleChange}
          />
          <TextField
            className="primary"
            name="depth_from_surface_to_bottom"
            label="Depth (From surface to bottom)"
            type="number"
            required
            unit
            value={formData.depth_from_surface_to_bottom}
            onChange={handleChange}
          />
          <TextField
            className="primary"
            name="intake__distance_from_land"
            label="Intake- Distance from land"
            type="number"
            required
            unit
            value={formData.intake__distance_from_land}
            onChange={handleChange}
          />
          <TextField
            className="primary"
            name="tank_level_manual_offset"
            label="Tank Level Manual offset"
            type="number"
            required
            value={formData.tank_level_manual_offset}
            onChange={handleChange}
          />
        </div>

        <div className="col-span-3 xl:col-span-2 mt-6">
          {error && <p className="error-msg  pb-2">{error}</p>}

          <Button label={"Save"} className="secondary" />
        </div>
      </form>
    </div>
  );
}
