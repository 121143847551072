// src/components/Sidebar.js
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AQ from "../../assests/AQ IKON.svg";
import Dash from "../../assests/IKON Dash.svg";
import Monitor from "../../assests/IKON Monitor.svg";
import Sensor from "../../assests/IKON Sensor.svg";
import Rapport from "../../assests/IKON Rapport.svg";
import ActiveBg from "../../assests/ActiveBg.svg";
import ActiveLink1 from "../../assests/ActiveLink1.svg";
import ActiveLink2 from "../../assests/ActiveLink2.svg";
import ActiveLink3 from "../../assests/ActiveLink3.svg";
import ActiveLink4 from "../../assests//ActiveLink4.svg";
import "./Styles.css";
const Sidebar = () => {
  const location = useLocation();
  const organization_type = JSON.parse(
    localStorage.getItem("auth")
  )?.organization_type;
  const user_type = JSON.parse(localStorage.getItem("auth"))?.user_type;

  const [links, setLinks] = useState([
    // {
    //   to: "/",
    //   label: "Dashboard",
    //   icon: Dash,
    //   activeIcon: ActiveLink3,
    // },
    // { to: "/report", label: "Report", icon: Rapport, activeIcon: ActiveLink1 },
    // {
    //   to: "/monitor",
    //   label: "Monitor",
    //   icon: Monitor,
    //   activeIcon: ActiveLink4,
    // },
    // {
    //   to: "/",
    //   label: "Organizations",
    //   icon: Dash,
    //   activeIcon: ActiveLink3,
    // },
    // {
    //   to: "/brands",
    //   label: "Brands",
    //   icon: Sensor,
    //   activeIcon: ActiveLink2,
    // },
  ]);

  useEffect(() => {
    if (organization_type === "super_admin") {
      setLinks([
        {
          to: "/",
          label: "Organizations",
          icon: Dash,
          activeIcon: ActiveLink3,
        },
        {
          to: "/brands",
          label: "Brands",
          icon: Sensor,
          activeIcon: ActiveLink2,
        },
      ]);
    }
    if (organization_type === "contractor") {
      setLinks([
        {
          to: "/",
          label: "Monitor",
          icon: Monitor,
          activeIcon: ActiveLink4,
        },
      ]);
    }

    if (organization_type === "customer") {
      setLinks([
        {
          to: "/",
          label: "Dashboard",
          icon: Dash,
          activeIcon: ActiveLink3,
        },
        {
          to: "/report",
          label: "Report",
          icon: Rapport,
          activeIcon: ActiveLink1,
        },
      ]);
    }
    // if (user_type === "viewer") {
    //   setLinks([
    //     {
    //       to: "/",
    //       label: "Dashboard",
    //       icon: Dash,
    //       activeIcon: ActiveLink3,
    //     },
    //     {
    //       to: "/report",
    //       label: "Report",
    //       icon: Rapport,
    //       activeIcon: ActiveLink1,
    //     },
    //     {
    //       to: "/monitor",
    //       label: "Monitor",
    //       icon: Monitor,
    //       activeIcon: ActiveLink4,
    //     },
    //   ]);
    // }
  }, [organization_type]);
  return (
    <div
      className="sidebar-wrapper top-0 left-0 h-full bg-1 text-white"
      style={{ width: "250px" }}
    >
      {/* Logo */}
      <div className="flex  justify-center" style={{ padding: "45px 0" }}>
        <img src={AQ} alt={`Icon`} className="pr-2" />
      </div>

      {/* Links */}
      <div className="space-y-2">
        {links.map(({ to, label, icon, activeIcon }) => (
          <Link
            key={to}
            to={to}
            className={`block p-2 relative`}
            onClick={() => {
              label != "Report" && localStorage.removeItem("reportActiveTab");
            }}
          >
            <div className="flex items-center p-4">
              {/* Icon Image Wrapper */}
              <div className="mr-2">
                <img
                  style={{ fontSize: "20px" }}
                  src={location.pathname === to ? activeIcon : icon}
                  alt={`${label} Icon`}
                />
              </div>
              <p
                className={`${
                  location.pathname === to
                    ? "primary-font active-link-nav"
                    : "primary-font"
                }`}
              >
                {label}
              </p>
            </div>
            {location.pathname === to && (
              <div className="absolute top-0 left-0 right-0 bottom-0">
                <img src={ActiveBg} alt={`${label} Icon`} />
              </div>
            )}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
