import React from "react";

const Button = ({ label, onClick, className, xClass = "" }) => {
  let baseClasses = "px-4 py-2 rounded focus:outline-none";

  switch (className) {
    case "primary":
      baseClasses = " primary-btn";
      break;
    case "secondary":
      baseClasses = " secondary-btn";
      break;

    case "tertiary":
      baseClasses = " tertiary-btn";
      break;

    default:
      baseClasses = " primary-btn";
  }

  return (
    <button className={`${baseClasses} ${xClass}`} onClick={onClick}>
      {label}
    </button>
  );
};

export default Button;
