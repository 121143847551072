import React, { useState } from "react";
import Pagination from "../../shared/Pagination/Pagination";
import Dropdown from "../../shared/Dropdown/Dropdown";
import { generateClient } from "aws-amplify/api";
import { getQualityhData } from "../../util/query";
import { getFormattedDate, getTimeFormattedArray } from "../../util/helper";
import ListSkeleton from "../../util/listSkeleton";
import { CSVLink } from "react-csv";
import { getPlantId } from "../../util/helper";
import BarChart from "../../shared/BarChartTwoValues/BarChart";
import GraphSkeleton from "../../util/graphSkeleton";

const TABLE_HEAD = ["Interval", "Conductivity", "Temperature"];
const TABLE_CELL_WIDTH = 100 / TABLE_HEAD.length;
const PAGE_SIZE = 6;

export default function QualityTable() {
  const plantId = getPlantId();

  const [selected, setSelected] = useState("Day");
  const [tableRows, setTableRows] = useState();
  const [refinedLabel, setRefinedLabel] = useState();

  const TABLE_SELECT_FIELD = ["Day", "Week", "Month", "Year"];
  const client = generateClient();

  const fetchQualityhData = async (name) => {
    try {
      const query = getQualityhData(selected.toLowerCase(), plantId);
      // Make the asynchronous GraphQL request
      const response = await client.graphql({ query });
      const pressure =
        response?.data?.getX03Conductivity_mcSpcmAndTemperature_C.reverse() ||
        [];
      setTableRows(pressure);
      setRefinedLabel(
        getTimeFormattedArray(
          pressure,
          1,
          selected === "Year" ? "year" : undefined
        )
      );
    } catch (error) {
      console.error(`Error fetching pressure data for ${name}:`, error);
    }
  };
  React.useEffect(() => {
    const fetchData = () => {
      fetchQualityhData();
    };
    fetchData(); // Initial fetch
    const interval = setInterval(fetchData, 600000); // Set interval to 10 minutes
    return () => clearInterval(interval);
  }, [selected]);

  const barDataCond =
    tableRows &&
    tableRows?.map((item) =>
      item.Conductivity_mcSpcm ? Number(item.Conductivity_mcSpcm) : 0
    );

  const barDataTemp =
    tableRows &&
    tableRows?.map((item) =>
      item.Conductivity_mcSpcm ? Number(item.Temperature_C) : 0
    );
  return (
    <>
      <div className="table-container-ro flex justify-between max-md:flex-col gap-6">
        <div className="quality-page-title">Value incoming/outgoing water</div>
        <div className="table-container-select-field ml-auto">
          <Dropdown
            data={TABLE_SELECT_FIELD}
            setSelected={(singleField) => {
              setSelected(singleField);
              singleField !== selected && setTableRows();
            }}
            selected={selected}
          />{" "}
        </div>
      </div>
      {/* Table Start */}
      <div className=" grid lg:grid-cols-2 gap-8  ">
        {barDataCond ? (
          <BarChart
            barData={[...barDataCond]}
            selected={selected}
            refinedLabel={refinedLabel}
            label="Conductivity"
          />
        ) : (
          <GraphSkeleton breakpoint="lg" />
        )}
        {barDataTemp ? (
          <BarChart
            barData={[...barDataTemp]}
            selected={selected}
            refinedLabel={refinedLabel}
            label="Temprature"
          />
        ) : (
          <GraphSkeleton breakpoint="lg" />
        )}
      </div>
    </>
  );
}
