import React, { useState, useRef, useEffect } from "react";
import "./Dropdown.css";

export default function Dropdown(props) {
  const {
    data,
    selected,
    setSelected,
    onSelect,
    btnClass,
    paperClass,
    icon = true,
    click = true,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelected(option);
    setIsOpen(false);
    onSelect(option);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="relative" ref={dropdownRef}>
      <button
        id="dropdownDefaultButton"
        data-dropdown-toggle="dropdown"
        className={btnClass}
        type="button"
        onClick={() => {
          click && toggleDropdown();
        }}
        style={{
          color: !data.includes(selected) ? "#858b97" : "#fff",
          borderColor: click ? "white" : "grey",
          cursor: click ? "pointer" : "not-allowed",
        }}
      >
        {selected}
        {icon && (
          <svg
            className={`w-2.5 h-2.5 ms-3 ${
              isOpen ? "transform rotate-180" : ""
            }`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
            style={{ color: click ? "white" : "grey" }}
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        )}
      </button>

      {isOpen && (
        <div className={paperClass}>
          <ul
            className="py-2 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownDefaultButton"
          >
            {data.map((option) => {
              return (
                <li
                  className="cursor-pointer links-color block px-4 py-2 "
                  onClick={() => {
                    handleOptionClick(option);
                  }}
                >
                  {option}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
