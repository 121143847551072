import React from "react";
import "./ReportFlowTag.css";

export default function ReportFlowTag(props) {
  const { title, number, unit, className = "", txtSm = false } = props;
  console.log(number);
  return (
    <div className={`report-flow-tag ${className}`}>
      <div className="report-flow-tag-title">{title}</div>
      <div
        className={
          txtSm
            ? "report-flow-tag-container mt-2 txtsm"
            : "report-flow-tag-container mt-2 "
        }
      >
        {number}
      </div>
      {unit && (
        <div
          className="report-flow-tag-unit"
          dangerouslySetInnerHTML={{ __html: unit }}
        ></div>
      )}
    </div>
  );
}
