import React from "react";

export default function Waves(props) {
  return (
    <svg
      width="27"
      height="20"
      viewBox="0 0 27 20"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5498 1.95999C5.5998 1.95999 5.59981 5.04997 9.64981 5.04997C13.6998 5.04997 13.6998 1.95999 17.7498 1.95999C21.7998 1.95999 21.7998 5.04997 25.8498 5.04997"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5498 14.93C5.5998 14.93 5.59981 18.02 9.64981 18.02C13.6998 18.02 13.6998 14.93 17.7498 14.93C21.7998 14.93 21.7998 18.02 25.8498 18.02"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5498 8.43999C5.5998 8.43999 5.59981 11.53 9.64981 11.53C13.6998 11.53 13.6998 8.43999 17.7498 8.43999C21.7998 8.43999 21.7998 11.53 25.8498 11.53"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
