export function divideArray(inputArray, subArrayLength) {
  // Validate the inputs
  if (!Array.isArray(inputArray)) {
    throw new Error("Input must be an array");
  }
  if (typeof subArrayLength !== "number" || subArrayLength <= 0) {
    throw new Error("Sub-array length must be a positive number");
  }

  const result = [];
  let subArray = [];

  inputArray.forEach((item, index) => {
    subArray.push(item);

    if (subArray.length === subArrayLength || index === inputArray.length - 1) {
      result.push(subArray);
      subArray = [];
    }
  });

  return result;
}

function formatTime(timeString, format) {
  const date = new Date(timeString);

  if (format === "year") {
    const month = date.toLocaleString("default", { month: "short" }); // Get the month in short format
    return `${month} ${date.getFullYear()}`; // Return the month and year
    // return date.getFullYear(); // Return the year
  }

  const options = {
    month: "short", // Full month name
    day: "numeric", // Numeric day
    hour: "numeric", // Hour
    minute: "numeric", // Minute
    hour12: true,
  };

  return date.toLocaleString(undefined, options); // Format the date
}

export function getTimeFormattedArray(originalArray, chunkSize, format) {
  const formattedTimes = [];
  for (let i = chunkSize - 1; i < originalArray.length; i += chunkSize) {
    const formattedTime = formatTime(originalArray[i].time_interval, format);
    formattedTimes.push(formattedTime);
  }

  return formattedTimes;
}

export const getFormattedDate = (dateString, selected) => {
  const date = new Date(dateString);
  let formattedDate;

  switch (selected) {
    case "Day":
      formattedDate = date
        .toLocaleString("default", {
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })
        .toUpperCase();
      break;

    case "Week":
      formattedDate = `${date
        .toLocaleString("default", {
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })
        .toUpperCase()}`;
      break;

    case "Month":
      formattedDate = date
        .toLocaleString("default", {
          month: "short",
          day: "numeric",
        })
        .toUpperCase();
      break;

    case "Year":
      const year = date.getFullYear();
      const month = date
        .toLocaleString("default", { month: "short" })
        .toUpperCase();
      formattedDate = `${month} ${year} `;
      break;

    default:
      throw new Error("Invalid selection");
  }

  return formattedDate;
};

export const getPlantId = () => {
  const plantId = localStorage.getItem("plantId");
  return plantId;
};
