import React, { useState } from "react";
import SpeedoMeterFilter from "../../shared/SpeedoMeterFilter/SpeedoMeterFilter";
import PressureTable from "./PressureTable";
import {
  getSensorsPressureQuery,
  getPressureDiffQuery,
} from "../../util/query";
import ListSkeleton from "../../util/listSkeleton";
import { generateClient } from "aws-amplify/api";
import { useSnackbar } from "notistack";
import { getPlantId } from "../../util/helper";

export default function Pressure() {
  const plantId = getPlantId();
  const client = generateClient();
  const { enqueueSnackbar } = useSnackbar();
  const [allKeysPresent, setAllKeysPresent] = useState(false);
  const [pressures, setPressures] = useState({});
  const [pressuresDiff, setPressuresDiff] = useState({});
  const [attempt, setAttempt] = useState(false);
  const [attemptDiff, setAttemptDiff] = useState(false);
  const sensorNames = ["x01", "x05", "x07"];

  const fetchSensorPressure = async (name) => {
    try {
      const query = getSensorsPressureQuery("current", name, plantId);
      // Make the asynchronous GraphQL request
      const response = await client.graphql({ query });
      const pressure =
        response?.data?.getPressureDataForAllSensor[0]?.Pressure_bar || 0;
      setPressures((prevPressures) => ({
        ...prevPressures,
        [name]: pressure,
      }));
    } catch (error) {
      if (error?.errors[0]?.message?.includes("Rate Exceeded") && !attempt) {
        enqueueSnackbar(
          "An error occurred while fetching data. Attempting to retry.",
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            preventDuplicate: true,
          }
        );
        setTimeout(() => {
          setAttempt(true);
        }, 3000);
      }
      console.error(`Error fetching pressure data for ${name}:`, error);
    }
  };

  React.useEffect(() => {
    const fetchData = () => {
      sensorNames.slice(0, 3).forEach((name) => {
        fetchSensorPressure(name);
        setAttempt(false);
      });
    };
    fetchData(); // Initial fetch
    const interval = setInterval(fetchData, 600000); // Set interval to 10 minutes
    return () => clearInterval(interval);
  }, [attempt]);

  React.useEffect(() => {
    const hasAllKeys = (obj, keys) => {
      return keys.every((key) => obj.hasOwnProperty(key));
    };
    setAllKeysPresent(hasAllKeys(pressures, sensorNames));
  }, [pressures]);
  const headings = [
    "Sea Intake Pressure",
    "Pressure after Filter 1",
    "Pressure after Filter 2",
  ];

  const fetchPressureDiff = async (name) => {
    try {
      const query = getPressureDiffQuery("current", name, plantId);
      // Make the asynchronous GraphQL request
      const response = await client.graphql({ query });
      const pressure =
        response?.data?.getPressureDifferenceWithPortx07Andx05Withx01[0]
          ?.pressure_bar_difference || 0;
      setPressuresDiff((prevPressures) => ({
        ...prevPressures,
        [name]: pressure,
      }));
    } catch (error) {
      if (error?.errors[0]?.message?.includes("Rate Exceeded") && !attempt) {
        enqueueSnackbar(
          "An error occurred while fetching data. Attempting to retry.",
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            preventDuplicate: true,
          }
        );
        setTimeout(() => {
          setAttemptDiff(true);
        }, 3000);
      }

      console.error(`Error fetching pressure data for ${name}:`, error);
    }
  };

  React.useEffect(() => {
    const fetchData = () => {
      sensorNames.slice(1).forEach((name) => {
        fetchPressureDiff(name);
        setAttemptDiff(false);
      });
    };
    fetchData(); // Initial fetch
    const interval = setInterval(fetchData, 600000); // Set interval to 10 minutes
    return () => clearInterval(interval);
  }, [attemptDiff]);
  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-5">
        {allKeysPresent
          ? sensorNames.map((item, idx) => {
              return (
                <SpeedoMeterFilter
                  maxVal={8}
                  title={headings[idx]}
                  labelText=""
                  labelValue=""
                  labelText2=""
                  labelValue2=""
                  labelTextStart={true}
                  value={
                    // pressures[item] > 0 ? Number(pressures[item]).toFixed(2) : 0
                    Number(pressures[item]).toFixed(2)
                  }
                />
              );
            })
          : sensorNames.map((_item) => <ListSkeleton breakpoint="lg" />)}

        {pressuresDiff?.x05 !== undefined ? (
          <>
            <SpeedoMeterFilter
              title={"Filter 1"}
              labelText="FILTER INSPECTION"
              labelValue="5,0"
              labelText2="PROBABLE FILTER CHANGE"
              labelValue2="> 1.0"
              labelTextStart={true}
              value={
                // pressures[item] > 0 ? Number(pressures[item]).toFixed(2) : 0
                Number(pressuresDiff.x05)?.toFixed(2)
              }
            />
          </>
        ) : (
          <ListSkeleton breakpoint="lg" />
        )}

        {pressuresDiff?.x07 !== undefined ? (
          <>
            <SpeedoMeterFilter
              title={"Filter 2"}
              labelText="FILTER INSPECTION"
              labelValue="5,0"
              labelText2="PROBABLE FILTER CHANGE"
              labelValue2="> 1.0"
              labelTextStart={true}
              value={
                // pressures[item] > 0 ? Number(pressures[item]).toFixed(2) : 0
                Number(pressuresDiff.x07)?.toFixed(2)
              }
            />
          </>
        ) : (
          <ListSkeleton breakpoint="lg" />
        )}
      </div>
      <div className="mt-5 grid grid-cols-1 xl:grid-cols-3">
        {allKeysPresent && (
          <PressureTable className="col-span-2" plantId={plantId} />
        )}
      </div>
    </>
  );
}
