import React from "react";
import "./CardWrapper.css";

export default function CardWrapper(props) {
  return (
    <div
      className={`card-wrapper ${props.className ?? ""}`}
      style={{ ...props.style }}
    >
      {props.children}
    </div>
  );
}
