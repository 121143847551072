import React from "react";
import "./Style.css";
function Tabs({ tabs, activeTab, setActiveTab, type = "primary" }) {
  return (
    <div className="flex justify-start text-sm font-medium text-start flex-nowrap w-full overflow-x-scroll overflow-y-hidden">
      {tabs.map((tab) => (
        <div
          key={tab.id}
          className={`cursor-pointer w-full ${
            type === "primary"
              ? "max-w-[160px] p-4 -mb-px"
              : " w-full md:w-[105px] p-[10px] pr-0 pb-0 mb-[0px]"
          }   text-start  mr-2  md:mr-6  pl-0 ${
            activeTab === tab.id
              ? `  ${
                  type === "primary"
                    ? "active-tab border-white border-b-[3px] text-white"
                    : "active-tab-secondary"
                }`
              : `text-gray-600  ${
                  type === "primary"
                    ? "tabs-heading hover:text-white"
                    : "tabs-heading-secondary"
                }  border-b-[2px]`
          }`}
          onClick={() => setActiveTab(tab.id)}
        >
          {tab.title}
        </div>
      ))}
    </div>
  );
}

export default Tabs;
