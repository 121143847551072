import React, { useState } from "react";
import TextField from "../shared/TextField";
import Button from "../shared/Button";
import { useSnackbar } from "notistack";
import "./Styles.css";
import { updatePassword } from "aws-amplify/auth";

const VerifyUser = ({ onLogin }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");

  async function handleUpdatePassword(e) {
    e.preventDefault();
    try {
      await updatePassword({ oldPassword, newPassword });
      enqueueSnackbar("Password updated successfully.", {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setNewPassword("");
      setOldPassword("");
    } catch (err) {
      if (err.message.includes("username")) {
        setError("Incorrect old password");
      } else {
        setError(err.message);
      }
    }
  }
  return (
    <div className="w-full pb-6">
      <h1 className="heading mb-4" style={{ color: "#ebffff" }}>
        Update Password
      </h1>
      <form className="mb-4 w-full" onSubmit={handleUpdatePassword}>
        <TextField
          name="old"
          label="Old Password"
          type="text"
          className="primary"
          required
          onChange={(e) => {
            setError("");
            setOldPassword(e.target.value);
          }}
        />
        <TextField
          name="new"
          label="New Password"
          type="text"
          className="primary"
          required
          onChange={(e) => {
            setNewPassword(e.target.value);
            setError("");
          }}
        />
        {error && <p className="error-msg">{error}</p>}
        <div className="pt-4">
          <Button label={"Update"} className="secondary" type="submit" />
        </div>
      </form>
    </div>
  );
};

export default VerifyUser;
