import React, { useEffect, useState } from "react";
import TextField from "../shared/TextField";
import Button from "../shared/Button";
import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import api from "../util/api";
import AddUser from "./AddUser";
import DropdownPrimary from "../shared/Dropdown/DropdownPrimary";

export default function EditOrg({ setIsEdit, editData }) {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [isChecked, setIsChecked] = useState(false);
  const [selectedBrand, setSelectedBrand] = React.useState(
    editData?.organization_brand
  );
  const [selectedType, setSelectedType] = React.useState(
    editData.organization_type
  );
  const [orgName, setOrgName] = useState(editData.organization_name);

  const { mutate: updateOrg } = useMutation(
    (data) => api.post(`/admin/update_organization`, data),
    {
      onSuccess: (res) => {
        enqueueSnackbar("Organization Updated!", {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        queryClient.invalidateQueries("allOrgs");
      },
      onError: (error) => {
        enqueueSnackbar(error.response.data || "Something went wrong!", {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
    }
  );

  const onEdit = (e) => {
    e.preventDefault();
    if (orgName) {
      const data = {
        organization_id: editData.organization_id,
        organization_name: orgName,
      };
      updateOrg(data);
    }
  };

  const { mutate: enableOrg } = useMutation(
    () =>
      api.get(
        `/admin/activate_organization?organization_id=${editData.organization_id}`
      ),
    {
      onSuccess: (res) => {
        console.log(res);
        enqueueSnackbar(res.data, {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        queryClient.invalidateQueries("allOrgs");
      },
      onError: (error) => {
        enqueueSnackbar(error.response.data, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
    }
  );
  const { mutate: disableOrg } = useMutation(
    () =>
      api.get(
        `/admin/deactivate_organization?organization_id=${editData.organization_id}`
      ),
    {
      onSuccess: (res) => {
        enqueueSnackbar(res.data, {
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        queryClient.invalidateQueries("allOrgs");
      },
      onError: (error) => {
        enqueueSnackbar(error.response.data, {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      },
    }
  );

  const handleToggle = () => {
    // eslint-disable-next-line no-unused-expressions
    editData.organization_status === "active" ? disableOrg() : enableOrg();
    setIsChecked(!isChecked);
  };
  useEffect(() => {
    editData.organization_status === "active"
      ? setIsChecked(true)
      : setIsChecked(false);
  }, [editData]);
  return (
    <div className="mt-0">
      <div className="grid grid-cols-1 lg:grid-cols-2 max-w-[745px] gap-3">
        <div className="lg:col-span-1">
          <div
            className="cursor-pointer text-md mb-4   align-middle text-center text-white flex items-center"
            onClick={() => {
              setIsEdit();
            }}
          >
            <svg
              class="w-3 h-3 text-gray-800 dark:text-white mr-2  "
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 8 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M7 1 1.3 6.326a.91.91 0 0 0 0 1.348L7 13"
              ></path>
            </svg>
            <span> Back</span>
          </div>
          <form className="max-w-[345px] w-full" onSubmit={onEdit}>
            <TextField
              className="primary"
              name="name"
              label="Organization Name"
              type="text"
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
              required
              pattern=".{3,}"
              title="Minimum 3 characters required"
            />

            <DropdownPrimary
              data={[]}
              selected={selectedType}
              setSelected={setSelectedType}
              onSelect={() => {}}
              btnClass="h-[47px]  mt-2 px-3 py-2.5 text-center inline-flex items-center  text-nowrap w-full flex justify-between rounded-[6px] border-[1px] border-white max-w-md pointer-events-none"
              paperClass="absolute mt-3 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-full md:max-w-md bg-1   md:left-0"
              icon={false}
            />

            {selectedBrand && (
              <DropdownPrimary
                data={[]}
                selected={selectedBrand}
                setSelected={setSelectedBrand}
                onSelect={() => {}}
                btnClass="h-[47px]  mt-6 px-3 py-2.5 text-center inline-flex items-center  text-nowrap w-full flex justify-between rounded-[6px] border-[1px] 	 max-w-md pointer-events-none	"
                paperClass="absolute mt-3 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-full md:max-w-md bg-1   md:left-0"
                icon={false}
              />
            )}
            <div className="col-span-3 xl:col-span-2 mt-6">
              <Button label={"Update"} className="secondary" />
            </div>
          </form>
        </div>
        <div className="lg:col-span-1 pt-8">
          <p className="text-md mb-[13px]   align-middle text-center text-white flex items-center">
            Status
          </p>
          <div className="flex items-center align-middle">
            <button
              label={
                editData.organization_status === "active"
                  ? "Enabled"
                  : "Disabled"
              }
              className={`h-[45px]  mt-0 px-3 py-2.5 text-center items-center  text-nowrap w-full flex justify-between rounded-[6px] border-[1px] border-white max-w-[200px]   text-white ${
                editData.organization_status !== "active"
                  ? "diabled-btn"
                  : "active-btn"
              }`}
            >
              {editData.organization_status === "active"
                ? "Enabled"
                : "Disabled"}

              <label className="inline-flex items-center pl-4   cursor-pointer">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleToggle}
                  className="sr-only peer"
                />
                <div
                  className={`relative w-11 h-6 ${
                    isChecked
                      ? "acccent-switch"
                      : "bg-gray-200 dark:bg-gray-700"
                  } rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600`}
                ></div>
              </label>
            </button>
          </div>
        </div>
      </div>

      <div className="divider w-full max-w-[545px] mt-14"></div>

      <AddUser editData={editData} />
    </div>
  );
}
