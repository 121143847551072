import React, { useState, useEffect } from "react";
import CardWrapper from "../shared/CardWrapper/CardWrapper";
import Tabs from "../shared/Tabs";
import BarChart from "../shared/BarChart/BarChart";
import Dropdown from "../shared/Dropdown/Dropdown";
import { generateClient } from "aws-amplify/api";
import { waterProductionDistributionHistory } from "../util/query";
import GraphSkeleton from "../util/graphSkeleton";
import { divideArray, getTimeFormattedArray } from "../util/helper";
function Bar({ setSelectedFilter, setTab, plantId }) {
  const [activeTab, setActiveTab] = useState("tab1");
  const [selected, setSelected] = useState("Day");
  const [flowIn, setFlowIn] = useState();
  const [flowOut, setFlowOut] = useState();

  const [refinedLabel, setRefinedLabel] = useState();

  const tabs = [
    { id: "tab1", title: "Producted Water", content: "This is Tab 3 content" },
    {
      id: "tab2",
      title: "Distributed Water",
      content: "This is Tab 3 content",
    },
  ];
  const TABLE_SELECT_FIELD = ["Day", "Week", "Month", "Year"];

  const client = generateClient();
  ////production

  async function fetchFlowInProduction() {
    try {
      const port = "x08";
      const query = waterProductionDistributionHistory(
        selected.toLowerCase(),
        port,
        plantId
      );
      const flowIn = await client.graphql({
        query,
      });
      if (flowIn) {
        const filteredData =
          await flowIn.data.getHistoryWaterFlowx04Andx08ProductionAndDistribution.map(
            (item) => {
              if (Number(item.Flow_Lpmin) > 0) {
                return Number(item.Flow_Lpmin).toFixed(2);
              } else {
                return Number(item.Flow_Lpmin).toFixed(2);
              }
            }
          );

        const refinedData = divideArray(filteredData.reverse(), 1);
        setFlowIn({ data: refinedData, interval: "minute" });
        setRefinedLabel(
          getTimeFormattedArray(
            flowIn.data.getHistoryWaterFlowx04Andx08ProductionAndDistribution.reverse(),
            1
          )
        );
      }
    } catch (error) {
      console.error("Error fetching flow in production data:", error);
    }
  }

  ////distribution
  async function fetchFlowOutDistribution() {
    try {
      const port = "x04";
      const query = waterProductionDistributionHistory(
        selected.toLowerCase(),
        port,
        plantId
      );
      // Make the asynchronous GraphQL request
      const flowOut = await client.graphql({
        query,
      });

      if (flowOut) {
        const filteredData =
          await flowOut.data.getHistoryWaterFlowx04Andx08ProductionAndDistribution.map(
            (item) => {
              if (Number(item.Flow_Lpmin) > 0) {
                return Number(item.Flow_Lpmin).toFixed(2);
              } else {
                return Number(item.Flow_Lpmin).toFixed(2);
              }
            }
          );

        const refinedData = divideArray(filteredData.reverse(), 1);
        setFlowOut({ data: refinedData, interval: "minute" });
        setRefinedLabel(
          getTimeFormattedArray(
            flowOut.data.getHistoryWaterFlowx04Andx08ProductionAndDistribution.reverse(),
            1
          )
        );
      }
    } catch (error) {
      console.error("Error fetching flow in production data:", error);
    }
  }
  useEffect(() => {
    const fetchData = () => {
      if (activeTab === "tab1") {
        fetchFlowInProduction();
      } else {
        fetchFlowOutDistribution();
      }
    };
    fetchData(); // Initial fetch
    const interval = setInterval(fetchData, 600000); // Set interval to 10 minutes
    return () => clearInterval(interval); // Clear interval on component unmount or when activeTab changes
  }, [activeTab, selected]);

  return (
    <>
      <CardWrapper>
        <header className="p-2  pb-2 py-3 md:p-6 md:pb-0 md:pt-2 ">
          <div className="flex justify-between">
            <div className="dashboard-page-title mb-2">Water flow</div>
            <div className="table-container-select-field dropdown-bar-mob-view mb-6">
              <Dropdown
                data={TABLE_SELECT_FIELD}
                setSelected={(singleField) => {
                  setSelected(singleField);
                  setSelectedFilter && setSelectedFilter(singleField);
                  if (singleField !== selected) {
                    setFlowIn();
                    setFlowOut();
                  }
                }}
                selected={selected}
              />{" "}
            </div>
          </div>
          <div className="flex justify-between">
            <Tabs
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={(tab) => {
                setActiveTab(tab);
                setSelected("Day");
                setTab && setTab(tab);
                setSelectedFilter && setSelectedFilter("Day");
                setFlowIn();
                setFlowOut();
              }}
              type="secondary"
            />
            <div className="table-container-select-field dropdown-bar-mob ">
              <Dropdown
                data={TABLE_SELECT_FIELD}
                setSelected={(singleField) => {
                  setSelected(singleField);
                  setSelectedFilter && setSelectedFilter(singleField);
                  if (singleField !== selected) {
                    setFlowIn();
                    setFlowOut();
                  }
                }}
                selected={selected}
              />
            </div>
          </div>
        </header>

        <div className="w-full p-2 pl-1 md:p-6 pb-0 md:pb-0">
          {activeTab === "tab1" ? (
            flowIn ? (
              <BarChart
                barData={flowIn}
                selected={selected}
                refinedLabel={refinedLabel}
              />
            ) : (
              <GraphSkeleton />
            )
          ) : flowOut ? (
            <BarChart
              barData={flowOut}
              selected={selected}
              refinedLabel={refinedLabel}
            />
          ) : (
            <GraphSkeleton />
          )}
        </div>
      </CardWrapper>
    </>
  );
}

export default Bar;
