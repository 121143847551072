import React, { useState } from "react";
import Tabs from "../shared/Tabs";
import Flow from "./Flow/Flow";
import Quality from "./Quality/Quality";
import Pressure from "./Pressure/Pressure";
import TankLevelPage from "./TankLevelPage/TankLevelPage";
import Machine from "./Machine/Machine";

export default function Report() {
  const reportActiveTab = localStorage.getItem("reportActiveTab");
  const [activeTab, setActiveTab] = useState(reportActiveTab || "tab1");

  const tabs = [
    { id: "tab1", title: "Flow", content: <Flow /> },
    { id: "tab2", title: "Pressure", content: <Pressure /> },
    { id: "tab3", title: "Quality", content: <Quality /> },
    { id: "tab4", title: "Machine", content: <Machine /> },
    { id: "tab5", title: "Tank level", content: <TankLevelPage /> },
  ];
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="report-page">
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={(tab) => {
          setActiveTab(tab);
          localStorage.setItem("reportActiveTab", tab);
        }}
      />
      <div className="mt-14 mb-6">
        {tabs.find((tab) => tab.id === activeTab)?.content}
      </div>
    </div>
  );
}
