import React, { useState } from "react";
import "./Table.css";
import Pagination from "../Pagination/Pagination";
import Dropdown from "../Dropdown/Dropdown";

const TABLE_HEAD = ["", "Flow in (kbm)", "Flow out (kbm)", "Flow return"];
const TABLE_SELECT_FIELD = ["Month", "Year"];
const TABLE_CELL_WIDTH = 100 / TABLE_HEAD.length;
const PAGE_SIZE = 6;

const TABLE_ROWS = [
  {
    monthName: "JAN",
    flowIn: 20,
    flowOut: 20,
    flowReturn: 10,
  },
  {
    monthName: "FEB",
    flowIn: 10,
    flowOut: 20,
    flowReturn: 10,
  },
  {
    monthName: "MAR",
    flowIn: 10,
    flowOut: 20,
    flowReturn: 10,
  },
  {
    monthName: "APR",
    flowIn: 10,
    flowOut: 20,
    flowReturn: 10,
  },
  {
    monthName: "MAY",
    flowIn: 10,
    flowOut: 20,
    flowReturn: 10,
  },
  {
    monthName: "JUN",
    flowIn: 10,
    flowOut: 20,
    flowReturn: 10,
  },
  {
    monthName: "JUL",
    flowIn: 10,
    flowOut: 20,
    flowReturn: 10,
  },
  {
    monthName: "AUG",
    flowIn: 20,
    flowOut: 20,
    flowReturn: 10,
  },
  {
    monthName: "SEP",
    flowIn: 10,
    flowOut: 20,
    flowReturn: 10,
  },
  {
    monthName: "OCT",
    flowIn: 10,
    flowOut: 20,
    flowReturn: 10,
  },
  {
    monthName: "NOV",
    flowIn: 10,
    flowOut: 20,
    flowReturn: 10,
  },
  {
    monthName: "DEC",
    flowIn: 10,
    flowOut: 20,
    flowReturn: 10,
  },
];

export default function Table() {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPage = Math.ceil(TABLE_ROWS.length / PAGE_SIZE);
  // startIndex and endIndex for table items
  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = Math.min(startIndex + PAGE_SIZE, TABLE_ROWS.length);
  const displayedItems = TABLE_ROWS.slice(startIndex, endIndex);

  return (
    <div className="table-container">
      <div className="table-container-row">
        <div className="table-container-select-field mt-3">
          <Dropdown data={TABLE_SELECT_FIELD} />
        </div>
        <Pagination
          currentPage={currentPage}
          totalPage={totalPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
      {/* Table Start */}
      <div className="w-full overflow-scroll">
        <table className="w-full table-auto">
          <thead>
            <tr>
              {TABLE_HEAD.map((head, index) => (
                <th key={index} className="border-b p-4">
                  <div className="table-heading">{head}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {displayedItems.map(
              ({ monthName, flowIn, flowOut, flowReturn }, index) => {
                return (
                  <tr
                    key={index}
                    style={{
                      width: `${TABLE_CELL_WIDTH}%`,
                      borderBottom:
                        index !== displayedItems.length - 1 &&
                        "0.25px solid #545D6E",
                    }}
                  >
                    <td className="dark-bg">
                      <span> {monthName}</span>
                    </td>
                    <td
                      className="light-bg"
                      style={{ width: `${TABLE_CELL_WIDTH}%` }}
                    >
                      <span> {flowIn}</span>
                    </td>
                    <td
                      className="dark-bg"
                      style={{ width: `${TABLE_CELL_WIDTH}%` }}
                    >
                      <span> {flowOut}</span>
                    </td>
                    <td
                      className="light-bg"
                      style={{ width: `${TABLE_CELL_WIDTH}%` }}
                    >
                      <span> {flowReturn}</span>
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
      {/* Table End */}
      <div className="download-button">
        <span className="underline">Download</span>
      </div>
    </div>
  );
}
