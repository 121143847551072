import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import api from "../util/api";
import Dashboard from "./Dashboard";
import "./Dashboard.css";
function DashboardWrapper() {
  const [plantId, setPlantId] = useState(null);
  // wrapper for customer only
  const user_type = JSON.parse(localStorage.getItem("auth"))?.user_type;
  const organization_type = JSON.parse(
    localStorage.getItem("auth")
  )?.organization_type;

  const { data: getPlant, isFetching } = useQuery(
    "getPlant",
    () =>
      api.get(
        user_type === "viewer" ? "/viewer/get_plant" : "/admin/get_plant"
      ),
    {
      enabled: organization_type === "customer",
      select: (response) => response?.data,
    }
  );
  useEffect(() => {
    if (organization_type === "customer" && getPlant) {
      if (getPlant[0]?.plant_id) {
        localStorage.setItem("plantId", getPlant[0]?.plant_id);
        setPlantId(getPlant[0]?.plant_id);
      }
    }
  }, [getPlant, isFetching]);

  return (
    <div>
      {organization_type === "customer" ? (
        plantId && <Dashboard plant_id={plantId} />
      ) : (
        <Dashboard />
      )}
    </div>
  );
}
export default DashboardWrapper;
