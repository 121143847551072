import React from "react";
import "./Quality.css";
import QualityTable from "./QualityTable";
export default function Quality() {
  return (
    <div className="quality-page">
      <QualityTable />
    </div>
  );
}
